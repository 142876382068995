import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import Booking from "../screens/BookingTypes/Booking";

const MovingPage = () => {
  // useEffect(() => {
  //   try {
  //     localStorage.setItem("hideBookingDropdown", "1");
  //   } catch (error) {
  //     Sentry.captureException(error);
  //   }
  // }, []);

  return (
    <Layout>
      <Booking type={"Moving"} />
    </Layout>
  );
};

export default MovingPage;
