import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import ResetPassword from "../screens/Account/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <Layout>
      <ResetPassword />
    </Layout>
  );
};

export default ResetPasswordPage;
