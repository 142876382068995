import css from "../../style/howMovEzWorks.module.css";
import Section1InfoSlider from "../../components/HowItWorks/Section1InfoSlider";
import Section2FAQs from "../../components/HowItWorks/Section2FAQs";

function MUVHowItWorks() {
  return (
    <div className={css?.HIWMainDiv}>
      <div className={css?.mainHeading}>How moovez works</div>
      <Section1InfoSlider />
      <Section2FAQs />
    </div>
  );
}

export default MUVHowItWorks;
