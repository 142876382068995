import React from "react";
import Section1TopBanner from "../../components/OurStory/Section1TopBanner";
import Section2FurtherInfo from "../../components/OurStory/Section2FurtherInfo";
import Section3CEOMessage from "../../components/OurStory/Section3CEOMessage";
import Section4BecomeAMoover from "../../components/OurStory/Section4BecomeAMoover";

const OurStory = () => {
  return (
    <div>
      <Section1TopBanner />
      <Section2FurtherInfo />
      <Section3CEOMessage />
      <Section4BecomeAMoover />
    </div>
  );
};

export default OurStory;
