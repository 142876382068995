import React from "react";
import css from "../../style/deliveryInfo.module.css";

const S3Brands = () => {
  const images = [
    "retailer1.png",
    "retailer2.png",
    "retailer3.png",
    "retailer4.png",
    "retailer5.png",
    "retailer6.png",
    "retailer7.png",
    "retailer8.png",
  ];
  return (
    <div className={css?.s3Md}>
      <h2 className={css?.s2H}>Delivering from all your favourite retailers</h2>
      <div className={css?.s3Images}>
        {images.map((img) => (
          <img src={`/images/${img}`} alt={img} className={css?.s3Img} />
        ))}
      </div>
    </div>
  );
};

export default S3Brands;
