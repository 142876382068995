import React, { useState, useRef, useEffect } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import { FaChevronDown } from "react-icons/fa";

const MoveSize = ({ values, moveSizeId, handleUpdateBookingObject }) => {
  const [toggleDD, setToggleDD] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const menuRef = useRef();
  const changeFunc = (val) => {
    try {
      handleUpdateBookingObject("moveSizeId", val ? parseInt(val) : 1000, "17");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  useEffect(() => {
    if (!moveSizeId || !Array.isArray(values))
      setSelectedItem("Select move size");
    else {
      const item = values.find((val) => moveSizeId === val?.id);
      setSelectedItem(item.noOfBedrooms);
    }
  }, [moveSizeId]);
  return (
    <div className={css?.dropdownMainDiv}>
      <div
        className={css?.dropdownTextDiv}
        onClick={() => setToggleDD(!toggleDD)}
        ref={menuRef}
      >
        {selectedItem}
        <FaChevronDown />
      </div>
      <div
        className={css?.DDOptions}
        style={{ display: toggleDD ? "block" : "none" }}
      >
        {values?.map((moves) => (
          <div
            onClick={() => changeFunc(moves?.id)}
            className={css?.DDOption}
            style={{
              backgroundColor:
                moves?.id === moveSizeId ? "var(--secondary)" : "white",
            }}
          >
            {moves?.noOfBedrooms}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoveSize;
