import {
  GET_BOOKING_FAIL,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_REQUEST,
  DELETE_BOOKING,
} from "../constants/bookingConstants";

// used for booking types
export const bookingReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BOOKING_REQUEST:
      return {
        ...state,
        bookingInfo: null,
        bookingLoading: true,
      };
    case GET_BOOKING_SUCCESS:
      return {
        ...state,
        bookingLoading: false,
        bookingInfo: payload,
        bookingError: "",
      };
    case DELETE_BOOKING:
      return {
        ...state,
        bookingLoading: false,
        bookingInfo: { ...state.bookingInfo, bookingFiles: payload },
        bookingError: "",
      };
    case GET_BOOKING_FAIL:
      return {
        ...state,
        bookingLoading: false,
        bookingError: payload,
      };
    default:
      return state;
  }
};
