import React from "react";
import * as Sentry from "@sentry/react";
import Calendar from "react-calendar";
// importing the css
import css from "../../style/booking.module.css";

const Calander = ({ handleUpdateBookingObject, deliveryDate, type }) => {
  return (
    <div className={css?.calanderMainDiv}>
      <div className={css?.Level1Heading} style={{ width: "100%" }}>
        {["Delivery"].includes(type)
          ? "Schedule your pickup time"
          : "Select your date & time"}
      </div>
      <div style={{ width: "100%" }}>
        {/* calander component */}
        <Calendar
          value={deliveryDate ? new Date(deliveryDate) : new Date()}
          // activeStartDate={new Date()}
          onChange={(date) => {
            try {
              handleUpdateBookingObject(
                "deliveryDate",
                date.toString()?.split(" GMT")[0],
                "10"
              );
            } catch (error) {
              Sentry.captureException(error);
              console.log(error);
            }
          }}
          className={css?.calanderConponent}
          minDate={new Date()}
        />
      </div>
    </div>
  );
};

export default Calander;
