import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import axios from "axios";
import baseUrl from "../../store/constants/baseUrl";
import ModalComponent from "../Modals/Modal";
import { userPaymentMethods } from "../../store/actions/paymentActions";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "black" },
      ":-placeholder": { color: "black" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({
  userId,
  setAddStripe,
  setstripeLoader,
  submitWrapper,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  const addStripeToUserAccount = async (userId, id, elements) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}BookingsAPI/AddingStripeToUserProfile?userId=${userId}&token=${id}`
      );
      console.log("successful Payment with data", data.statusCode);
      if (data.statusCode === 200) {
        dispatch(userPaymentMethods(userId));
        setAddStripe(true);
        elements.getElement(CardElement).clear();
        setModalFields({
          ...modalFields,
          open: true,
          text: data?.message ?? "Card added successfully.",
        });
      }
    } catch (error) {
      setstripeLoader(false);
      Sentry.captureException(error);
      console.log(error);
      setModalFields({
        ...modalFields,
        open: true,
        text: error?.response?.data?.message ?? "Couldn't add card.",
      });
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { error, token } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (!error) {
        const { id } = token;
        setstripeLoader(true);
        await addStripeToUserAccount(userId, id, elements);
      } else {
        console.log("payment error", error.message);
        setAddStripe(false);
        setstripeLoader(false);
      }
    } catch (error) {
      setstripeLoader(false);
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <form
        onSubmit={(e) =>
          submitWrapper ? submitWrapper(handleSubmit, e) : handleSubmit(e)
        }
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <fieldset style={{ width: "100%" }}>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>

        <input
          type="submit"
          value="[Save Card]"
          style={{
            color: "var(--primary)",
            fontWeight: "700",
            fontSize: "15px",
            padding: "10px",
            borderRadius: "5px",
            margin: "20px 0 0 auto",
          }}
        ></input>
      </form>
    </div>
  );
};

export default PaymentForm;
