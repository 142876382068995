import {
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
} from "../constants/userProfileConstants";

// used for userProfile
export const userProfile = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileInfo: null,
        userProfileLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLoading: false,
        userProfileInfo: payload,
        userProfileError: "",
      };
    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        userProfileLoading: false,
        userProfileError: payload,
      };
    default:
      return state;
  }
};
