import React from "react";
import css from "../../style/moverInfo.module.css";

const S3Testimonials = () => {
  const LIST = [
    {
      title: "1. Enter your details",
      description:
        "Be sure to enter the correct details & add pictures. Choosing a vehicle that’s too small may mean multiple trips and end up costing you more. We also have default options for each move type and size.",
    },
    {
      title: "2. Pair with a moover",
      description:
        "Our moovers will arrive with blankets, dollies, straps and other basic tools. We are not a packing service. The more prepared you are, the smoother the job will go. Chat in-app and know exactly where your moovers are with real-time tracking.",
    },
    {
      title: "3. Review and tip",
      description:
        "Once your move is complete, leave a review and say thanks with a tip for a job well done. See you next time!",
    },
  ];
  return (
    <div className={css?.S4_Md}>
      <h1 className={css?.S3_Heading}>How moovez works</h1>
      <div className={css?.S4_Cards}>
        {LIST.map((item, index) => (
          <div className={css?.S4_Card_MD}>
            <img
              className={css?.S4_Img}
              src={`/images/howWork_step${index + 1}.png`}
              alt={item.title}
            />
            <h3 className={css?.S2_Title}>{item.title}</h3>
            <p className={css?.S2_Description} style={{ marginBottom: 0 }}>
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default S3Testimonials;
