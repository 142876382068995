import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/booking.module.css";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className={css?.loading}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
