import {
  GET_DELIVERY_SLOTS_FAIL,
  GET_DELIVERY_SLOTS_SUCCESS,
  GET_DELIVERY_SLOTS_REQUEST,
} from "../constants/deliverySlotsConstants";

// used for delivery types
export const deliverySlotsReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DELIVERY_SLOTS_REQUEST:
      return {
        ...state,
        deliverySlotsInfo: null,
        deliverySlotsLoading: true,
      };
    case GET_DELIVERY_SLOTS_SUCCESS:
      return {
        ...state,
        deliverySlotsLoading: false,
        deliverySlotsInfo: payload,
        deliverySlotsError: "",
      };
    case GET_DELIVERY_SLOTS_FAIL:
      return {
        ...state,
        deliverySlotsLoading: false,
        deliverySlotsError: payload,
      };
    default:
      return state;
  }
};
