import React from "react";
import css from "../../style/moverInfo.module.css";
import { useNavigate } from "react-router";

const S1Top = () => {
  const history = useNavigate();
  return (
    <div className={css?.S1_Md}>
      <div className={css?.S1_L}>
        <h1 className={css?.S1_H}>Booking a move has never been easier.</h1>
        <h5 className={css?.S2_T}>
          moovez is the perfect solution to get you moved into your new
          apartment, condo, house or office.
        </h5>
        <button className={css?.S1_Btn} onClick={() => history("/Movers")}>
          Book a move
        </button>
      </div>
      <img
        src="/images/book_a_mover_banner.png"
        alt="truck"
        className={css?.S1_R}
      />
    </div>
  );
};

export default S1Top;
