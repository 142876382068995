import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
//reducers
import { loginReducer } from "./reducers/loginReducer";
import { vehicleTypesReducer } from "./reducers/vehicleTypeReducer";
import { deliverySlotsReducer } from "./reducers/deliverySlotsReducer";
import { bookingReducer } from "./reducers/bookingReducer";
import { userProfile } from "./reducers/userProfileReducer";
import { myTrips } from "./reducers/myTripReducer";
import { paymentMethodReducer } from "./reducers/paymentMethodsReducer";

//combining the reducers
const reducer = combineReducers({
  loginReducer,
  vehicleTypesReducer,
  deliverySlotsReducer,
  bookingReducer,
  userProfile,
  myTrips,
  paymentMethodReducer,
});

const initialState = {};
// added the thunk middleware
const middleware = [thunk];

// created the redux store
const Store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
