import React from "react";
import css from "../../style/myTrips.module.css";
import { useNavigate } from "react-router";

const TripCard = ({ data }) => {
  // history for routing
  const history = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("wemoovezUserProfile"));

  // useEffect(() => {
  //   dispatch(getSingleBooking(data.bookingFiles.map((i) => i.bookingId)));
  // }, []);
  return (
    <div className={css?.cardMainDiv}>
      <div className={css?.cardLeftDiv}>
        <div className={css?.cardStatus}>{data?.status}</div>
        <div className={css?.cardDate}>
          {data?.deliveryDate?.split(" ")[0] + " " + data?.exactTime}
        </div>
        <div className={css?.cardPickup}>
          <span className={css?.statusDot} style={{ backgroundColor: "green" }}>
            .
          </span>
          {data?.pickupLocation}
        </div>
        {data?.stops?.map((stop) => (
          <div className={css?.cardPickup}>
            <span
              className={css?.statusDot}
              style={{ backgroundColor: "green" }}
            >
              .
            </span>
            {stop?.stop}
          </div>
        ))}
        <div className={css?.cardPickup}>
          <span className={css?.statusDot} style={{ backgroundColor: "green" }}>
            .
          </span>
          {data?.dropoffLocation}
        </div>
        <div className={css?.vehicleName}>{data?.vehicle?.title}</div>
      </div>
      <div className={css?.cardRightDiv}>
        <div className={css?.cardPickup2}>{data?.pickupLocation}</div>
        <div className={css?.totalBill}>
          CAD {data?.bookingTotalModel?.totalCharge}
        </div>
        <div className={css?.buttonsDiv}>
          {/* <div
            className={css?.changePaymentBtn}
            onClick={() => history(`/userProfile/${userInfo?.email}`)}
          >
            change payment method
          </div> */}
          <div
            className={css?.viewDetailsBtn}
            onClick={() => history(`/singleTrip/${userInfo?.id}/${data?.id}`)}
          >
            view details
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripCard;
