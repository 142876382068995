import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import FeedBackScreen from "../screens/Info/FeedBackScreen";

const FeedBackPage = () => {
  return (
    <Layout>
      <FeedBackScreen />
    </Layout>
  );
};

export default FeedBackPage;
