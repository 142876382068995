import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/thankyou.module.css";

const ThankYou = () => {
  return (
    <div className={css?.thankyouMainDiv}>
      <div className={css?.thankyouCheck}>
        <i class="fa fa-check"></i>
      </div>
      <div className={css?.thankyouHeading}>Thank you!</div>
      <div className={css?.thankyouText}>
        Your booking has been received. One of our moovers will get paired to
        your job shortly.
      </div>
    </div>
  );
};

export default ThankYou;
