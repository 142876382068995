import React from "react";
// importing the css
import css from "../../style/booking.module.css";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RoundButton from "../Booking/RoundButton";
import Calander from "../Booking/Calander";
import TimeSlot from "./TimeDropdown";

const ModalComponent = ({
  type,
  deliveryDate,
  exactTime,
  handleUpdateBookingObject,
  openState,
  closeFunction,
  btnClickFunc1,
  timeSlots,
}) => {
  return (
    <Modal
      open={openState}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{ p: 4, bgcolor: "background.paper" }}
        className={css.calanderConponentModal}
      >
        <Typography id="modal-modal-description" className={css?.Level3Heading}>
          Schedule
          {["Movers"].includes(type) ? " movers" : " pickup"}
        </Typography>
        <Calander
          deliveryDate={deliveryDate}
          handleUpdateBookingObject={handleUpdateBookingObject}
        />
        <TimeSlot
          exactTime={exactTime}
          type={type}
          handleUpdateBookingObject={handleUpdateBookingObject}
          values={timeSlots}
        />
        <div className={css?.CalanderModalBottomBtns}>
          <div className={css?.roundBtn2} onClick={closeFunction}>
            Close
          </div>
          <RoundButton
            text={
              ["Movers"].includes(type) ? "Set date & time" : "Confirm pickup"
            }
            clickFunction={btnClickFunc1}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
