import {
  GET_TRIP_FAIL,
  GET_TRIP_SUCCESS,
  GET_TRIP_REQUEST,
} from "../constants/myTripConstants";

// used for my trips
export const myTrips = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TRIP_REQUEST:
      return {
        ...state,
        myTripsInfo: null,
        myTripsLoading: true,
      };
    case GET_TRIP_SUCCESS:
      return {
        ...state,
        myTripsLoading: false,
        myTripsInfo: payload,
        myTripsError: "",
      };
    case GET_TRIP_FAIL:
      return {
        ...state,
        myTripsLoading: false,
        myTripsError: payload,
      };
    default:
      return state;
  }
};
