import React, { useState, useEffect } from "react";
import css from "../../style/deliveryInfo.module.css";
import { getFAQs } from "../../store/actions/CommonActions";
import * as Sentry from "@sentry/react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";

const FAQAccordion = ({ question, answer, expanded, setExpanded, index }) => {
  return (
    <div className={css?.s7FAQM}>
      <Accordion
        className={css?.s7FAQAcc}
        expanded={expanded === index}
        onClick={() => setExpanded(expanded === index ? -1 : index)}
      >
        <AccordionSummary
          expandIcon={<BsChevronDown />}
          collapse
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css?.s7FAQSummary}
        >
          <div className={css.s7AccordionQuestion}>{question}</div>
        </AccordionSummary>
        <AccordionDetails className={css.s7AccordionAnswer}>
          {answer}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Section6_FAQs = () => {
  const [FAQs, setFAQs] = useState([]);
  const [FAQsLoading, setFAQsLoading] = useState(true);
  const [expanded, setExpanded] = useState(-1);
  useEffect(() => {
    try {
      getFAQs(setFAQs, setFAQsLoading);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <div className={css?.s7M}>
      <div className={css?.s7Heading}>Frequently asked questions</div>
      {FAQsLoading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={css?.s7FAQs}>
          {FAQs?.map((FAQ, index) => (
            <FAQAccordion
              {...FAQ}
              setExpanded={setExpanded}
              expanded={expanded}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Section6_FAQs;
