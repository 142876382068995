import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { getFAQs } from "../store/actions/CommonActions";
import css from "../style/FAQ.module.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import Layout from "../layouts/index";
import { GoDotFill } from "react-icons/go";

const FAQAnswer = ({ answer }) => {
  if (!answer) return <></>;
  return (
    <div style={{}}>
      {answer?.h ? <div style={{ margin: "15px 0" }}>{answer.h}</div> : <></>}
      {answer?.p ? <div style={{ margin: "15px 0" }}>{answer.p}</div> : <></>}
      {Array.isArray(answer?.b) ? (
        <div>
          {answer.b.map((bullet) => (
            <div>
              <GoDotFill
                style={{
                  color: "var(--primary)",
                  margin: "0 10px 4px 20",
                  fontSize: 14,
                }}
              />
              {bullet}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {Array.isArray(answer?.b2) ? (
        <div>
          {answer.b2.map((bullet) => (
            <div style={{ padding: "0 0 0 40px" }}>{bullet}</div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {answer?.i ? (
        <div style={{ margin: "15px 0" }}>
          <i>{answer.i}</i>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const FAQAccordion = ({ question, answer, expanded, setExpanded, index }) => {
  return (
    <div className={css?.FAQM}>
      <Accordion
        className={css?.FAQAcc}
        expanded={expanded === index}
        onClick={() => setExpanded(expanded === index ? -1 : index)}
      >
        <AccordionSummary
          expandIcon={<BsChevronDown />}
          collapse
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css?.FAQSummary}
        >
          <div className={css.AccordionQuestion}>{question}</div>
        </AccordionSummary>
        <AccordionDetails className={css.AccordionAnswer}>
          {Array.isArray(answer) ? (
            answer.map((A) => <FAQAnswer answer={A} />)
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Section8 = () => {
  const [FAQs, setFAQs] = useState([
    {
      question: "What is moovez?",
      answer: [
        {
          p: "We are an on-demand moving and delivery service that connects customers and businesses with vetted and verified movers, with a range of vehicle sizes available. Our experienced moovers are trained to handle heavy and bulky items with care, ensuring that they reach their final destination safely and securely.",
        },
      ],
    },
    {
      question: "What happens if the drop-off location is closed?",
      answer: [
        {
          p: "If the drop-off location is closed or unable to accept the items, your moovers will notify you and ask for an alternative delivery location. If there is no other location available, the items will need to be returned to the pickup location. To ensure a smooth and successful delivery, we recommend calling ahead to confirm that the drop-off location is open and able to accept the items being delivered before your moovers arrive.",
        },
        {
          p: "Please note additional charges will apply if additional travel is required. Also, our moovers have the right to charge you for their time if they are required to wait for an unreasonable amount of time. We will quote you for the job accordingly, and all payments will be handled seamlessly through the app upon successful completion of the job.",
        },
      ],
    },
    {
      question:
        "Can moovez help me move items within my home or from the street to my home?",
      answer: [
        {
          p: 'Yes, we offer labour-only jobs. Our "Movers" job type is designed for this purpose. Our moovers will show up and provide the muscle for any of your heavy lifting needs. Typical examples include moving furniture around your house, helping to load or unload a rented moving truck, unloading storage pods or C-Cans, and more.',
        },
        {
          p: 'To book a labour-only job, start by finding the "Movers" job type on our website or mobile app. When booking, enter the address where you require our help, and if there’s a second address, please "add a stop". Our system will provide a quote breakdown based on the information you provid. This includes travel time (our moover(s) need to travel to the address provided) and an hourly rate for their labour.',
        },
      ],
    },
    {
      question: "Can moovez dispose of or recycle items for me?",
      answer: [
        {
          p: 'Yes, we are happy to assist you with disposing of your unwanted items. To request this service, we recommend submitting a "Moving" job through our website or mobile app. Keep in mind that dump runs typically require more time than “Delivery” jobs, which are designed for 1-5 items and take no longer than 15 minutes to load or unload. That’s why we recommend submitting a “Moving” job for dump runs.',
        },
        {
          p: "Please note that landfills and recycling centers have limited operating hours, so it's important to book our moover(s) early in the day to ensure timely disposal. Our moover(s) need time to load up and transport your items to the dump, they will let you know if they don't have enough time to complete the job before the landfill or recycling center closes.",
        },
        {
          p: "It's important to be aware that landfills and recycling centers typically charge a fee based on the weight of the items being disposed of. These fees can range from $25 for 250 kg or less and go up from there. You can find more information about the fees on the websites of the landfill or recycling centers in your area. The landfill fees will be added to your total and charged to your card in addition to our regular fees.",
        },
      ],
    },
    {
      question: "Where do I enter my promo code?",
      answer: [
        {
          p: "You enter your promo code during the booking process. You will see a box to enter your promo code before you submit your booking.",
        },
      ],
    },
    {
      question: "How do I know my scheduled job is on the way?",
      answer: [
        {
          p: "As soon as you book your job, you will receive a confirmation via text message or email with all the details of your booking. Once your job is assigned to one of our moover(s), we will notify you. We will also send you a reminder via text message or email when your move or delivery is about to begin. You can track our moover(s) in real-time from the moment they start navigating to the pickup location until the job is completed.",
        },
      ],
    },
    {
      question: "May I schedule multiple jobs in advance?",
      answer: [
        {
          p: "Certainly! Once you have scheduled your first job, you can go ahead and schedule as many additional jobs as you need. They can be scheduled for the same day or weeks apart to suit your needs.",
        },
      ],
    },
    {
      question: "Can I tip my moovers?",
      answer: [
        {
          p: "Tipping our moovers is always optional. However, if you are satisfied with the job done and would like to show your appreciation, you can do so through our website or app. You will have the opportunity to tip your moover(s) immediately after your job is completed. Our moovers keep 100% of the tips!",
        },
        {
          p: "If you already tipped our moovers in cash, please select the 'No Tip' option in our app or website to avoid double tipping.",
        },
      ],
    },
    {
      question: "How do I speak with my moover(s)?",
      answer: [
        {
          p: "Once our moover(s) are assigned to your job, you will have the ability to communicate with them directly through our mobile app. If you need to work out any details ahead of time, please make sure to update your move in the app or website.",
        },
        {
          p: "In case of any questions or issues, you can always call Customer Support or email us at support@wemoovez.com and we will be happy to assist you.",
        },
      ],
    },
    {
      question:
        "Does moovez move refrigerators, washer & dryers, and other large items?",
      answer: [
        {
          p: "Yes, we do move appliances! However, due to their weight and size, appliances may require special handling and transportation. Please select an appropriate vehicle and the appropriate number of movers to handle the weight. If the appliance(s) need to be moved to a specific location in the house, such as the main floor, basement, or upstairs, please remember to measure the doorways and stairways to ensure that the appliance(s) will fit through. If you have any questions or concerns about moving your appliances, please feel free to contact Customer Support at support@wemoovez.com for more information.",
        },
      ],
    },
    {
      question: "Does moovez move pianos?",
      answer: [
        {
          p: "We're sorry, but we do not offer piano moving services. Piano moving requires special skills, equipment, and experience that are beyond the scope of our moving and delivery services. We suggest contacting a specialized piano moving company for this specific service. For any other specialized items, we recommend contacting a moving company that specializes in that particular item.",
        },
      ],
    },
    {
      question: "Can I ride in the same vehicle as my stuff?",
      answer: [
        {
          p: "Unfortunately we do not authorize ridesharing on our system. There are other ridesharing services available for that.",
        },
      ],
    },
    {
      question:
        "My building requires moving companies to provide a certificate of insurance for a specified amount. Can moovez work with it?",
      answer: [
        {
          p: "Yes! Please send us an email at support@wemoovez.com for a copy of our insurance certificate that you can provide for your building.",
        },
      ],
    },
    {
      question: "Can I have the moovers pay for my marketplace item?",
      answer: [
        {
          p: "We do not offer payment services for any services beyond those provided on our platform. Therefore, we recommend that you arrange for payment of the items being moved or delivered in advance through methods such as cash, PayPal, or e-transfer before our moover(s) arrives for pickup. While our moovers may occasionally agree to pay for items on your behalf, this is on a case-by-case basis. Please note that moovez is not responsible for any transactions that occur outside our scope of service or outside of our platform.",
        },
      ],
    },
  ]);
  const [FAQsLoading, setFAQsLoading] = useState(true);
  const [expanded, setExpanded] = useState(-1);
  useEffect(() => {
    try {
      // getFAQs(setFAQs, setFAQsLoading);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <Layout>
      <div className={css?.M}>
        <div className={css?.Heading}>Frequently asked questions</div>
        {/* {FAQsLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : ( */}
        <div className={css?.FAQs}>
          {FAQs?.map((FAQ, index) => (
            <FAQAccordion
              {...FAQ}
              setExpanded={setExpanded}
              expanded={expanded}
              index={index}
            />
          ))}
        </div>
        {/* )} */}
      </div>
    </Layout>
  );
};

export default Section8;
