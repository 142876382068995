import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import Booking from "../screens/BookingTypes/Booking";

const BookingPage = (props) => {
  return (
    <Layout>
      <Booking bookingType={props?.location?.state?.bookingType} />
    </Layout>
  );
};

export default BookingPage;
