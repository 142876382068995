import React from "react";
import css from "../../style/BecomeAMoover.module.css";

const Card = ({ icon, title, text }) => {
  return (
    <div className={css?.S5CardM}>
      <img className={css?.S5CardImg} src={`/images/${icon}.svg`} alt={icon} />
      <div className={css?.S5CardHeading}>{title}</div>
      <div className={css?.S5CardTxt}>{text}</div>
    </div>
  );
};
const Section5 = () => {
  const cardData = [
    {
      icon: "get_paid",
      title: "Get paid",
      text: "The harder you work, the more you can earn. Our movers typically earn between $1,000-$1,700 per week, and our satisfied customers often provide generous tips. The best part is, you get to keep 100% of your tips!",
    },
    {
      icon: "control",
      title: "Be a boss",
      text: "With moovez, you have complete control over your work schedule. You can choose to work every day, only a couple of hours a day, or even just on weekends. Take on jobs that fit your availability and preferences, and work on your own terms.",
    },
    {
      icon: "register",
      title: "Simple registration process",
      text: "While the specific requirements may vary, signing up takes just a few minutes. After completing the onboarding process and passing verification by our team, you'll be ready to start accepting jobs right away.",
    },
    {
      icon: "training",
      title: "Work with friends",
      text: "Most of our jobs require a team of two moovers. You can either be matched with a helper through our platform or invite your friends to join and complete jobs together.",
    },
  ];
  return (
    <div className={css?.s5M}>
      <div className={css?.s5Heading}>Why moovez?</div>
      <div className={css?.s5Txt}>
        Whether you’re a moving company or just someone looking for the perfect
        side hustle, we provide quality jobs so you get to build a moving &
        delivery business on your own terms without the stress of finding
        customers.
      </div>
      <div className={css?.s5Cards}>
        {cardData?.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </div>
  );
};

export default Section5;
