export const tabPanels = [
  {
    tooltip: [
      { heading1: "Delivery guidelines" },
      {
        text: "For a successful delivery, make sure you follow these guidelines:",
        bullets: [
          "1-5 items",
          "These jobs are quick. It should take approximately 15-20 minutes to load & unload the vehicle.",
          "If your job will take longer than the above mentioned loading/unloading time, please submit a “moving” job.",
        ],
      },
      {
        text: "Common use cases:",
        bullets: [
          "marketplace deliveries, curb-side/In-store pick-ups, construction material delivery, etc.",
        ],
      },
      {
        heading2: "Prohibited items",
        text: "Alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
      },
    ],
    title: "Schedule a delivery.",
    text: "Getting large items delivered has never been easier.",
    text2: "",
    places: "Delivery",
    btn1Txt: "Deliver now",
    btn2Txt: "Schedule for later",
  },
  {
    tooltip: [
      {
        heading1: "Moving guidelines",
      },
      {
        text: "For a successful move, make sure you follow these guidelines:",
        bullets: [
          "If your load exceeds 5 items and will take over 15 minutes to load, it’s a “moving” job (you’re in the right place).",
          "These jobs typically take multiple trips to load & unload the vehicle. Work time can take anywhere from 1-12 hours depending on the size of the job.",
        ],
      },
      {
        text: "Common use cases:",
        bullets: [
          "Apartment, condo & house moves.",
          "Office downsizing, relocation services, storage moves, dump runs, etc.",
          "Moving multiple pieces of furniture that take time to load/unload, blanket and strap down.",
        ],
      },
      {
        heading2: "Prohibited items",
        text: "Alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
      },
    ],
    title: "Moving, simplified.",
    text: "Get moved into your new home or office at the tap of a button.",
    text2: "",
    places: "Moving",
    btn1Txt: "Book now",
    btn2Txt: "Learn more",
    btn2Link: "moverInfo",
  },
  {
    tooltip: [
      {
        heading1: "Movers guidelines",
      },
      {
        text: "For a successful movers job, make sure you follow these guidelines:",
        bullets: [
          "Movers are for when you need the muscle but you don’t need the truck.",
          "Work time can take anywhere from 1-12 hours depending on the size of the job.",
        ],
      },
      {
        text: "Common use cases:",
        bullets: [
          "Moving furniture around an apartment, condo, house or office.",
          "Helping to load or unload a rented moving truck, Storage pods, C-Can, etc.",
        ],
      },
      {
        heading2: "",
        text2:
          "Note: Our moovers can go to multiple locations but if you need a vehicle to move things between locations please submit a moving job.",
      },
      {
        heading2: "Prohibited items",
        text: "Alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
      },
    ],
    title: "Need the muscle without the truck?",
    text: "Schedule our on-demand movers to help",
    text2: "with all your heavy lifting needs.",
    places: "Movers",
    btn1Txt: "Book now",
    btn2Txt: "Learn more",
    btn2Link: "moverInfo",
  },
  {
    tooltip: [
      {
        heading1: "Business guidelines",
      },
      {
        text: "To get started, make sure you follow these guidelines:",
        bullets: [
          "Fill out the online form so we can learn more about your business delivery needs.",
          "Once completed, one of our Business Development Representatives will reach out to you and explain the next steps.",
        ],
      },
      {
        text: "Common use cases:",
        bullets: [
          "Last-mile delivery for your business.",
          "Fast and reliable on-demand, same-day or advance-order deliveries for businesses of any size.",
          "Our business clients include: real estate agencies, home stagers/interior designers, furniture stores, retail stores, construction suppliers, construction companies, and the list continues to grow.",
        ],
      },
      {
        heading2: "Prohibited items",
        text: "Alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
      },
    ],
    title: "Deliver faster.",
    text: "The new last-mile delivery solution for your business.",
    text2: "",
    places: "Business",
    btn1Txt: "Get started",
    btn2Txt: "Learn more",
    btn2Link: "businessInfo",
  },
  {
    tooltip: [
      {
        heading1: "Become a moover guidelines",
      },
      {
        text: "To become a mover, make sure you follow these guidelines:",
        bullets: [
          "Click on “Sign up now” and follow the onboarding process.",
          "Sign up with a vehicle or sign up as a moover without a vehicle.",
          "Earn money helping people move their things.",
          "Work as much or as little as you want.",
        ],
      },
      {
        text: "Common use cases:",
        bullets: [
          "Help businesses deliver products to their clients.",
          "Help deliver larger items purchased from stores or on Facebook marketplace, Kijiji, etc.",
          "Help people move from apartments, condos, houses or offices.",
        ],
      },
      {
        heading2: "Prohibited items",
        text: "Alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
      },
    ],
    title: "Be part of the movement and get paid",
    text: "Join the fastest growing network of movers.",
    text2: "",
    places: "driver",
    btn1Txt: "Sign up now",
    btn2Txt: "Learn more",
    btn2Link: "BecomeAMoover",
  },
];
