import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/moveCompleted.module.css";
import { useNavigate, useParams } from "react-router-dom";

const MoveCompleted = () => {
  const [TNC, setTNC] = useState(false);
  const history = useNavigate();
  const { userId, bookingId } = useParams();
  const handleCompleteMove = () => {
    try {
      if (TNC) {
        history(`/rating/${userId}/${bookingId}`);
      } else alert("Please accept the terms and conditions and privacy policy");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.mainDiv}>
      <div className={css?.cardMainDiv}>
        <div className={css?.headingMainDiv}>Another job well done!</div>
        <div className={css?.BtnStyle} onClick={() => handleCompleteMove()}>
          Move completed
        </div>
        <div className={css?.bottomText}>
          <input
            type="checkbox"
            className={css?.checkBox}
            onChange={(e) => setTNC(e.target.value)}
          />
          By clicking you accept our{" "}
          <span
            className={css?.highlightedText}
            onClick={() => history("/terms")}
          >
            terms & condition
          </span>{" "}
          and{" "}
          <span
            className={css?.highlightedText}
            onClick={() => history("/Privacy")}
          >
            privacy policy
          </span>
        </div>
      </div>
    </div>
  );
};

export default MoveCompleted;
