import React, { useState, useEffect } from "react";
import BlogLayout from "../layouts/BlogLayout";
import BlogDetailsScreen from "../screens/Blogs/BlogDetailsScreen";
import { useParams } from "react-router";
import { blogsContent } from "../blogs/blogsJson";

const BlogDetails = () => {
  const { title } = useParams();
  const [currentBlog, setCurrentBlog] = useState(null);

  useEffect(() => {
    if ((blogsContent, title)) {
      setCurrentBlog(blogsContent[title]);
    }
  }, [blogsContent, title]);

  return (
    <BlogLayout title={currentBlog ? currentBlog.title : "Loading..."}>
      {currentBlog ? <BlogDetailsScreen blog={currentBlog} /> : <></>}
    </BlogLayout>
  );
};

export default BlogDetails;
