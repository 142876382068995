import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/orderSummary.module.css";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PaymentForm from "../stripe/PaymentForm3";
import { stripeTestPromise } from "../../store/constants/baseUrl";
import { Elements } from "@stripe/react-stripe-js";

//Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalComponent = ({
  text,
  openState,
  closeFunction,
  userId,
  setCardSaved,
}) => {
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  return (
    <Modal
      open={openState}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" className={css?.CMTitleText}>
          {text}
        </Typography>
        <Elements stripe={stripeTestPromise}>
          <PaymentForm
            userId={userId}
            setCardSaved={setCardSaved}
            submitWrapper={(cb, e) => {
              e.preventDefault();
              if (!buttonClicked) {
                setButtonClicked(true);
                cb(e);
              }
            }}
          />
        </Elements>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
