import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/BecomeAMoover.module.css";
import { getFAQs } from "../../store/actions/CommonActions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

const FAQAnswer = ({ answer }) => {
  if (!answer) return <></>;
  return (
    <div style={{}}>
      {answer?.h ? <div style={{ margin: "15px 0" }}>{answer.h}</div> : <></>}
      {answer?.p ? <div style={{ margin: "15px 0" }}>{answer.p}</div> : <></>}
      {answer?.p2 ? <div style={{ margin: "15px 0" }}>{answer.p}</div> : <></>}
      {Array.isArray(answer?.b) ? (
        <div>
          {answer.b.map((bullet) => (
            <div>
              <GoDotFill
                style={{
                  color: "var(--primary)",
                  margin: "0 10px 4px 20",
                  fontSize: 14,
                }}
              />
              {bullet}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const FAQAccordion = ({ question, answer, expanded, setExpanded, index }) => {
  return (
    <div className={css?.s8FAQM}>
      <Accordion
        className={css?.s8FAQAcc}
        expanded={expanded === index}
        onClick={() => setExpanded(expanded === index ? -1 : index)}
      >
        <AccordionSummary
          expandIcon={<BsChevronDown />}
          collapse
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css?.s8FAQSummary}
        >
          <div className={css.s8AccordionQuestion}>{question}</div>
        </AccordionSummary>
        <AccordionDetails className={css.s8AccordionAnswer}>
          {Array.isArray(answer) ? (
            answer.map((A) => <FAQAnswer answer={A} />)
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Section8 = () => {
  const [FAQs, setFAQs] = useState([
    {
      question: "What is moovez?",
      answer: [
        {
          p: "We are an on-demand moving and delivery service that connects customers and businesses with vetted and verified movers, with a range of vehicle sizes available. Our experienced moovers are trained to handle heavy and bulky items with care, ensuring that they reach their final destination safely and securely.",
        },
      ],
    },
    {
      question: "What will I be doing?",
      answer: [
        {
          p: "As an independent contractor with moovez, your role will be to assist customers and businesses with the transportation of heavy and bulky items from A to B. You have complete flexibility to choose the jobs that fit your schedule. Our platform offers a wide range of services to customers, including store deliveries, donation centre drop-offs, dump runs, Kijiji/Facebook Marketplace deliveries, and more. We also handle full apartment, condo, house, and office moves, as well as labour-only jobs where customers simply need help with moving items within their home or office. There are no contractual obligations with customers beyond a service agreement, and no minimum hour requirements.",
        },
        {
          p: "As a moover with moovez, your success will depend on your ability to choose the right jobs, arrive at the pickup location on time, safely lift and transport items, work hard, and treat our customers with respect. Before arriving at a job, you'll have the opportunity to communicate directly with customers to clarify any details. Our satisfied customers often provide generous tips, so if you do a good job, you'll be rewarded.",
        },
        { p: "The best part is, you get to keep 100% of your tips!" },
      ],
    },
    {
      question: "What are the requirements for becoming a moovez moover?",
      answer: [
        {
          p: "General requirements",
          b: [
            "18 years of age or older",
            "Android or iOS smartphone",
            "Ability to pay for and pass a background check",
          ],
        },
        {
          p: "Moover/Moving Company (truck + muscle)",
          b: [
            "An active and valid driver’s license that authorizes you to drive (not a learner’s permit).",
            "Auto insurance that matches the name and vehicle you sign up with.",
            "Proof of being an owner or an insured driver if the vehicle and/or insurance policies are registered under a legal registered business name.",
            "Willing and able to lift and carry large bulky items, such as couches and desks (at least 75 lbs).",
          ],
        },
        {
          p: "Muscle (no truck)",
          b: [
            "A valid, unexpired Canadian government issued identification. Examples include but are not limited to a driver's license, identification card, passport, or permanent resident card.",
          ],
        },
        {
          p: "How do I get paid?",
          b: [
            "We use a commission-based model: moovers take 80% and the platform takes 20% commission.",
            "Moovers (with a vehicle) can make an average of $25-50/hr.",
            "Helpers (no vehicle) can make an average of $20-35/hr",
            "Moving Companies make an average of $550 per job.",
            "Moovers, Helpers & Moving Companies all keep 100% of the customer tips.",
          ],
        },
        {
          p: "Payment schedule",
        },
        {
          p: "After you are approved on the platform, you will be asked to connect a bank account to receive payouts. We pay out once a week to your connected account.",
        },
      ],
    },
    {
      question: "How do I get paid?",
      answer: [
        {
          p: "Average earnings",
          b: [
            "We use a commission-based model: moovers take 80% and the platform takes 20% commission.",
            "Moovers (with a vehicle) can make an average of $25-50/hr.",
            "Helpers (no vehicle) can make an average of $20-35/hr.",
            "Moving Companies make an average of $550 per job.",
            "Moovers, Helpers & Moving Companies all keep 100% of the customer tips.",
          ],
        },
        {
          p: "Payment schedule",
        },
        {
          p: "After you are approved on the platform, you will be asked to connect a bank account to receive payouts. We pay out once a week to your connected account.",
        },
      ],
    },
    {
      question: "What are the different types of moovers?",
      answer: [
        {
          p: "Moover",
        },
        {
          p: "A moover is an individual who is 18 years of age or older; drives a pickup truck, cargo van or moving truck; can lift over 75 pounds; and understands the importance of excellent customer service.",
        },
        {
          p: "Helper",
        },
        {
          p: "A Helper is an individual who is 18 years of age or older, assists a moover with moves or takes Labour-Only jobs, can lift over 75 pounds, and understands the importance of excellent customer service.",
        },
        {
          p: "Moving Company",
        },
        {
          p: "A Moving Company is looking for additional jobs to fill the gaps in their schedule. Typically they operate 3-5 ton moving trucks and will do small to medium-sized condo/apartment, house and office moves. They will also do bigger business deliveries. They are required to maintain excellent customer service.",
        },
      ],
    },
    {
      question: "How do I sign up to be a moover?",
      answer: [
        { p: "Get started by signing up under the Become a moover tab." },
        {
          p: "Once you have completed the sign up process, you will be sent an email and directed to our onboarding process. Follow the required steps and upload all necessary documents. You won’t be able to accept jobs until all required documents have been uploaded and approved by our verification team.",
        },
        {
          p: "Required documents may vary but be prepared to provide the following:",
          b: [
            "Vehicle registration & insurance verification (if you are signing up as a moover with a vehicle or a moving company)",
            "ID Verification",
            "Agreeing to our Moover Agreement",
            "Paying for and passing a background check",
            "Completing online onboarding training",
          ],
        },
        {
          p: "Once completed, you will be able to download the Moover app and accept your first job!",
        },
      ],
    },
    {
      question: "How long does the application process take?",
      answer: [
        {
          p: "Typically, filling out the basic information and uploading the required documents will take less than 30 minutes.",
        },
        {
          p: "After that, a few more things need to happen:",
          b: [
            "Vehicle inspection/approval (if signing up with a vehicle)",
            "Passing a background check (this can take 7-10 days to be completed)",
            "Completing the onboarding training",
          ],
        },
      ],
    },
    {
      question: "When and where do I get jobs?",
      answer: [
        {
          p: "You're the boss! You can choose to accept jobs that fit your availability and work as much or as little as you'd like. We receive job requests throughout the day, as well as outside of normal business hours when people are home from work. Business hours are typically the best time for business deliveries, while after-work hours and weekends are popular among regular customers for moving and delivery services.",
        },
        {
          p: "You'll soon discover that different types of jobs are more common at certain times of day, and as you complete more jobs, you'll become more familiar with the patterns and trends.",
        },
      ],
    },
    {
      question: "Is moovez safe?",
      answer: [
        {
          p: "We’re committed to moover and customer safety. We've designed a review process for moovers to review the customers. Any customers that behave in a way deemed inappropriate by our internal code of conduct will be blocked from our platform indefinitely.",
        },
        {
          p: "We have 24/7 support. Easily contact our support team for reliable, around-the-clock help by phone, email, or text. For emergency assistance: you can always contact 911 directly.",
        },
        {
          p: "Moving furniture and other heavy items comes with some inherent risks. That’s why we try to provide as much information as possible before you hit the road. Having the right equipment and knowledge can make your life a lot easier. We do our best to create a safe work environment for every moover on our platform.",
        },
      ],
    },
  ]);
  // const [FAQsLoading, setFAQsLoading] = useState(true);
  const [expanded, setExpanded] = useState(-1);
  useEffect(() => {
    try {
      // getFAQs(setFAQs, setFAQsLoading);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <div className={css?.s8M}>
      <div className={css?.s8Heading}>Frequently asked questions</div>
      <div className={css?.s8FAQs}>
        {FAQs?.map((FAQ, index) => (
          <FAQAccordion
            {...FAQ}
            setExpanded={setExpanded}
            expanded={expanded}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Section8;
