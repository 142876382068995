import React from "react";
import BlogLayout from "../layouts/BlogLayout";
import BlogListScreen from "../screens/Blogs";

const BlogList = () => {
  return (
    <BlogLayout title="Blog">
      <BlogListScreen />
    </BlogLayout>
  );
};

export default BlogList;
