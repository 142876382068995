import React, { useState } from "react";
import css from "../../style/forgotPassword.module.css";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmEmailAction } from "../../store/actions/loginActions";
import { FaCode } from "react-icons/fa";
const ConfirmEmail = () => {
  // verification code
  const [verificationcode, setverificationcode] = useState();
  //history for routing
  const history = useNavigate();
  // dispatch actions
  const dispatch = useDispatch();
  // getting error
  const { loginError } = useSelector((state) => state.loginReducer);

  // email and user id from params
  const { email, phone } = useParams();
  // verification cide
  const [verificationcodeActive, setverificationcodeActive] = useState(false);
  const [count, setCount] = useState(0);
  // forgot password
  const handleConfirmEMail = () => {
    if (verificationcode) {
      setCount(1);
      dispatch(ConfirmEmailAction(email, verificationcode, history));
    } else {
      alert("Please fill all the fields");
    }
  };

  return (
    <div className={css?.signup_page}>
      <div className={css?.inner_content}>
        <div class={css?.RegisterMainHeading}>Verify your email</div>
        <div className={css?.dash_with_title}>
          A verification code has been sent to your email {email} and cellphone{" "}
          {phone}. Please enter that code below:
        </div>
        <div class={css?.sign_up_form}>
          <div class={css?.fullInputleft}>
            <span className={css?.textIconsFull}>
              <FaCode />
            </span>
            <input
              placeholder="Code"
              className={css?.inputFieldFull}
              value={verificationcode}
              onFocus={() => setverificationcodeActive(true)}
              onChange={(e) => setverificationcode(e?.target?.value)}
            />
            {verificationcodeActive && !verificationcode && (
              <span className={css?.crossIconFull}>
                <i class="far fa-times-circle"></i>
              </span>
            )}
          </div>
        </div>
        {count !== 0 ? (
          <div style={{ color: "red" }}>{loginError ? loginError : ""}</div>
        ) : (
          ""
        )}
        {/* <div style={{ color: "red" }}>{loginError ? loginError : ""}</div> */}
        <div className={css?.BottomButtons2}>
          <div className={css?.dash_with_title} style={{ width: "40%" }}>
            Back to{" "}
            <span
              className={css?.signupButton}
              style={{ textDecoration: "underline" }}
              onClick={() => history("/Signup")}
            >
              Sign up
            </span>
          </div>
          <button onClick={handleConfirmEMail} className={css?.submitButton}>
            Confirm Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
