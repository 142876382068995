import React from "react";
import Layout from "../layouts/index";
import MoverInfoScreen from "../screens/Info/MoverInfo";

const MoverInfo = () => {
  return (
    <Layout>
      <MoverInfoScreen />
    </Layout>
  );
};

export default MoverInfo;
