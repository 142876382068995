import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const getAllFeedBacks = async (feedBackObj) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}FeedBackFromUser`,
      feedBackObj
    );
    console.log("feedback data from api", data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
