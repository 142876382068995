import {
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
} from "../constants/userProfileConstants";
import { LOGOUT } from "../constants/LoginConstants";
import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

// post user profile action
// this action is called in user profile page
export const postUserProfile =
  (userProfile, setChangesSavedMessage, setShowModal) => async (dispatch) => {
    try {
      console.log("profile is updating", userProfile);
      const { data } = await axios.post(
        `${baseUrl}ProfileDetailAPI/save`,
        userProfile
      );
      console.log("profile data from api", data);

      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data,
      });
      setChangesSavedMessage("Profile updated successfully.");
      setShowModal(true);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      dispatch({
        type: GET_USER_PROFILE_FAIL,
        payload: "post user profile API Failed,Please try again!",
      });
      setChangesSavedMessage(
        "Unable to update profile at the moment, please try again later!"
      );
      setShowModal(true);
    }
  };

export const deleteProfile =
  (userId, history, setError) => async (dispatch) => {
    try {
      console.log("Base URL and id", baseUrl, deleteProfile);
      const { data } = await axios.post(
        `${baseUrl}Auth/DeleteUser?Id=` + parseInt(userId),
        {}
      );
      console.log("user's profile deleted successfully", data);
      dispatch({
        type: LOGOUT,
        payload: data,
      });
      history("/login");
    } catch (error) {
      console.log(error);
      setError("Failed to delete profile, please try again later");
    }
  };

// get user profile action
// this action is called in user profile page
export const getUserProfile = (userEmail) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}ProfileDetailAPI/${userEmail}`);
    console.log("user profile data from api", data);
    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_USER_PROFILE_FAIL,
      payload: "user profile API Failed,Please try again!",
    });
  }
};
// post user image action
// this action is called in user profile pages
export const postProfileImage =
  (userId, userEmail, ProfileImage, setMessage, setShowModal) =>
  async (dispatch) => {
    try {
      console.log("file from frontend", ProfileImage);
      await axios.post(
        `${baseUrl}ProfileDetailAPI/profileImage?id=${userId}`,
        ProfileImage
      );
      const { data } = await axios.get(
        `${baseUrl}ProfileDetailAPI/${userEmail}`
      );
      console.log("profile image data from api", data);
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data,
      });
      setMessage("Image updated successfully.");
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setMessage(
        "Unable to update profile image at the moment, please try again later!"
      );
      Sentry.captureException(error);
      setShowModal(true);
    }
  };
