import { loadStripe } from "@stripe/stripe-js";

const baseUrl = `${process.env.REACT_APP_BASE_URL}api/`;
const imageURL = process.env.REACT_APP_BASE_URL;
const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PC;
export const stripeTestPromise = loadStripe(PUBLIC_KEY);
export const vehicleSpecsImageURL = `${imageURL}vehicleImages/`;
export const bookingFilesImageURL = `${imageURL}BookingFiles/`;
export const profileImageURL = `${imageURL}profileImages/`;
export default baseUrl;

export const BOOKING_TYPES = [
  { value: "All", option: "All" },
  { value: "Pending", option: "Pending" },
  { value: "InCompleted", option: "Incompleted" },
  { value: "Active", option: "Active" },
  { value: "InProgress", option: "In Progress" },
  { value: "Cancelled", option: "Cancelled" },
  { value: "Completed", option: "Completed" },
];
