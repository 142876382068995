import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import Press from "../screens/Info/Press";

const PressPage = () => {
  return (
    <Layout>
      <Press />
    </Layout>
  );
};

export default PressPage;
