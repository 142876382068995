import React from "react";
import css from "../../style/businessInfo.module.css";

const S2Features = () => {
  return (
    <div className={css?.s2Md}>
      <div className={css?.s2L1}>
        <div className={css?.s2CMd}>
          <div className={css?.s2CImgD}>
            <img
              src="/images/solution_ic1.svg"
              alt=""
              className={css?.s2CImgTag}
            />
          </div>
          <h2 className={css?.s2CardH}>A solution that delivers</h2>
          <h5 className={css?.s2CardT}>Convenient, fast and affordable.</h5>
        </div>
      </div>
      <div className={css?.s2L2}>
        <div className={css?.s2CMd}>
          <div className={css?.s2CImgD}>
            <img
              src="/images/solution_ic2.svg"
              alt=""
              className={css?.s2CImgTag}
            />
          </div>
          <h2 className={css?.s2CardH}>Real-time tracking</h2>
          <h5 className={css?.s2CardT}>
            In-app tracking allows you and your customers to track deliveries in
            real time.
          </h5>
        </div>
        <div className={css?.s2CMd}>
          <div className={css?.s2CImgD}>
            <img
              src="/images/solution_ic3.svg"
              alt=""
              className={css?.s2CImgTag}
            />
          </div>
          <h2 className={css?.s2CardH}>Reduce operational costs</h2>
          <h5 className={css?.s2CardT}>
            No need to manage your own delivery fleet. Let us handle it and only
            pay when deliveries are needed.
          </h5>
        </div>
        <div className={css?.s2CMd}>
          <div className={css?.s2CImgD}>
            <img
              src="/images/solution_ic4.svg"
              alt=""
              className={css?.s2CImgTag}
            />
          </div>
          <h2 className={css?.s2CardH}>Protected deliveries</h2>
          <h5 className={css?.s2CardT}>
            Each delivery is back by an insurance policy from the moment your
            customer’s items are in our possession.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default S2Features;
