import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import MyTrips from "../screens/Booking/MyTrips";

const MyTripsPage = () => {
  return (
    <Layout>
      <MyTrips />
    </Layout>
  );
};

export default MyTripsPage;
