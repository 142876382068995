import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const getBusinessAverageDeliveries =
  (setBusinessAverageDeliveries) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessAverageDeliveries`,
        {}
      );
      setBusinessAverageDeliveries(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const getBusinessCurrentIndustry =
  (setBusinessCurrentIndustry) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessCurrentIndustry`,
        {}
      );
      setBusinessCurrentIndustry(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const getBusinessGeneralLeadTime =
  (setBusinessGeneralLeadTime) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessGeneralLeadTime`,
        {}
      );
      setBusinessGeneralLeadTime(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const getBusinessOrderPreference =
  (setBusinessOrderPreference) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessOrderPreference`,
        {}
      );
      setBusinessOrderPreference(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const getBusinessPreferedContactMethod =
  (setBusinessPreferedContactMethod) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessPreferedContactMethod`,
        {}
      );
      setBusinessPreferedContactMethod(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const getBusinessVehicleOftenRequired =
  (setBusinessVehicleOftenRequired) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/GetBusinessVehicleOftenRequired`,
        {}
      );
      setBusinessVehicleOftenRequired(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const postBookingSignup =
  (businessObject, history) => async (dispatch) => {
    try {
      await axios.post(`${baseUrl}Auth/BusinessSignup`, businessObject);
      localStorage.setItem("currentBookingType", "Business");
      history("/businessBooking");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };
