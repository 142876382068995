import React from "react";
import css from "../../style/moverInfo.module.css";

const S3Testimonials = () => {
  const LIST = [
    {
      title: "Sherisse Blenman",
      description:
        "Professional and personable service from beginning to end. I was relieved to find a service like this one. It's like Uber for moving! Made my move day seamless. Highly recommend!",
    },
    {
      title: "Jaime Siguiendo",
      description:
        "They were very helpful, professional, and efficient! Moved all of my stuff in a very quick manner. Would use again next time I need something moved!",
    },
    {
      title: "Bob Bernard",
      description:
        "Exceptional service! I've used them twice and they were highly professional in both cases. My items were delivered on time and in perfect condition. They made my moving process so easy. I recommend this service, I'll be returning!",
    },
  ];
  return (
    <div className={css?.S3_Md}>
      <h1 className={css?.S3_Heading}>What our customers are saying…</h1>
      <div className={css?.S3_Cards}>
        {LIST.map((item, index) => (
          <div className={css?.S3_Card_Md}>
            <img
              className={css?.S3_quotation}
              src={`/images/quote.svg`}
              alt={item.title}
            />
            <div className={css?.S3_description}>{item.description}</div>

            <div className={css?.S3Circle}>
              <img
                className={css?.S3_Card_img}
                src={`/images/t${index + 1}.png`}
                alt={item.title}
              />
              <h4 className={css?.S3_Card_Name}>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default S3Testimonials;
