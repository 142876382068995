import React from "react";
import css from "../../style/ourStory.module.css";

const Section2 = () => {
  return (
    <div className={css?.S2M}>
      <div className={css?.s2Heading}>On-demand moving & delivery</div>
      <div className={css?.s2P}>
        moovez was founded with the goal of revolutionizing the way people move
        large and bulky items from one location to another. We were frustrated
        by the lack of innovation in the moving and delivery industry, so we
        decided to develop a convenient and on-demand solution. Our innovative
        approach leverages the existing network of moving and delivery vehicles
        on the road and matches them with the needs of our customers. By doing
        so, we can pair the closest available moover(s) to your job and get your
        items moved efficiently in just a few clicks.
      </div>
      <div className={css?.s2P}>
        Our platform allows customers and businesses to quickly and easily
        select the vehicle and number of moovers that best fits their needs and
        budget, providing them with the help they need, when they need it,
        without the hassle and stress of traditional moving and delivery
        services. Additionally, customers can track the progress of our moovers
        in real-time, ensuring they always know the location of their belongings
        and when they will arrive. This level of transparency and accountability
        sets us apart from other moving and delivery services and is why so many
        people trust us to handle their items.
      </div>
      <div className={css?.s2P}>
        At moovez, our aim is to make the moving and delivery process more
        convenient, reliable, and affordable. Not sure if moovez is the right
        solution for you? Give us a try and discover for yourself how we can
        simplify your move and take the stress out of your next big item move or
        delivery.
      </div>
    </div>
  );
};

export default Section2;
