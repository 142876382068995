import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/ourStory.module.css";

const Section4 = () => {
  return (
    <div className={css?.S4M}>
      <div className={css?.s4Heading}>Before you go, let’s talk business!</div>
      <div className={css?.s4Text}>
        moovez has become the preferred partner for numerous small and
        medium-sized businesses facing last-mile delivery challenges. We cater
        to a diverse range of industries, including real estate, home staging,
        interior design, furniture stores, flooring stores, and other retail
        outlets. Our expertise helps businesses scale their delivery
        capabilities, freeing them up to focus on growing their business.
        Whatever your moving and delivery requirements may be, moovez is ready
        to become a perfect extension to your business and work together towards
        your success.
      </div>
      <div className={css?.s4Card}>
        <div className={css?.s4CardLeft}>
          <div className={css?.s4CardLeftHeading}>
            Become a moover and start earning.
          </div>
          <div className={css?.s4CardLeftTxt}>
            Earn money helping people move their stuff.
          </div>
          <div
            className={css?.s4CardLeftBtn}
            onClick={() =>
              (window.location.href =
                "https://moversignup.moovez.ca/Driver/MooverSignUp")
            }
          >
            Sign up now
          </div>
        </div>
        <img
          className={css?.s4Image}
          src="/images/driver.png"
          alt="Driver on the driving seat"
        />
      </div>
    </div>
  );
};

export default Section4;
