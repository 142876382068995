import React from "react";
import Layout from "../layouts/index";
import BecomeAMoover from "../screens/Info/BecomeAMoover";

const BecomeAMooverPage = () => {
  return (
    <Layout>
      <BecomeAMoover />
    </Layout>
  );
};

export default BecomeAMooverPage;
