import {
  GET_BOOKING_FAIL,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_REQUEST,
} from "../constants/bookingConstants";
import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

// post booking action
// this action is called in delivery pages
export const postBooking = async (
  bookingData,
  setBookingObj,
  setShowLottie
) => {
  try {
    console.log("posting booking for the first time======", bookingData);
    const { data } = await axios.post(`${baseUrl}BookingsAPI`, bookingData);
    if (data) setBookingObj(null, data?.data, "9");
    setShowLottie(false);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    setShowLottie(false);
  }
};

// post booking action
// this action is called in trip pages
export const cancelBooking = async (bookingId, userId, setLoader, history) => {
  try {
    console.log("booking cancel hay", bookingId);
    const res = await axios.get(
      `${baseUrl}BookingsAPI/cancelBooking?bookingId=${bookingId}`
    );

    if (res.data) {
      history(`/myTrips/${userId}`);
    } else {
      const { data } = await axios.get(
        `${baseUrl}ProfileDetailAPI/myTrips?id=${userId}`
      );
      console.log("trip data from api", data);
    }
    setLoader(false);
  } catch (error) {
    setLoader(false);
    console.log(error);
    Sentry.captureException(error);
  }
};

// update booking action
// this action is called in multiple pages
export const updateBooking = async (
  bookingId,
  bookingObj,
  setBookingObj,
  setShowLottie
) => {
  try {
    const { data } = await axios.put(
      `${baseUrl}BookingsAPI/${bookingId}`,
      bookingObj
    );
    if (data) setBookingObj(null, data, "8");
    setShowLottie(false);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    setShowLottie(false);
  }
};

// get booking total action
export const getBookingTotal = async (bookingObj, setTotal) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}BookingsAPI/GetTotalBookingModel`,
      bookingObj
    );
    setTotal(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// get booking action
// this action is called in multiple pages
export const getSingleBooking = (bookingId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BOOKING_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}BookingsAPI/${bookingId}`);
    console.log("booking data from api", data);
    dispatch({
      type: GET_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_BOOKING_FAIL,
      payload: "booking API Failed,Please try again!",
    });
  }
};

// post bookingFile action
// this action is called in delivery pages
export const postBookingFiles = async (
  bookingId,
  bookingFiles,
  setLoader,
  handleUpdateBookingObject
) => {
  try {
    await axios.post(
      `${baseUrl}BookingsAPI/bookingFiles?id=${bookingId}`,
      bookingFiles
    );
    const { data } = await axios.get(`${baseUrl}BookingsAPI/${bookingId}`);
    if (
      data?.bookingFiles &&
      Array.isArray(data?.bookingFiles) &&
      data?.bookingFiles?.length > 0
    ) {
      handleUpdateBookingObject("bookingFiles", data?.bookingFiles, "19");
    }
    setLoader(false);
  } catch (error) {
    setLoader(false);
    console.log(error);
    Sentry.captureException(error);
  }
};

// Delete booking File action
export const deleteBookingFile = (bookingId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}SaveImageAPI/DeleteBookingFile?id=${bookingId}`
    );
    console.log("booking files data from api", data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

// delete stop action
// this action is called in delivery pages
export const deleteStop = (stopId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${baseUrl}DestinationsAPI/${stopId}`);
    console.log("deleted stop data", data);
    // dispatch({
    //   type: GET_BOOKING_SUCCESS,
    //   payload: data,
    // });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
// get move places action
// this action is called in delivery pages
export const getMovePlaces = (setMovePlaces) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}MoveTypesAPI/GetMovePlaces`);
    console.log("Move places API result", data);
    setMovePlaces(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
// get move size action
// this action is called in delivery pages
export const getMoveSize = (movePlaceId, setMoveSize) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}MoveTypesAPI/GetMoveTypes?movePlaceId=${movePlaceId}`
    );
    console.log("Move size API result", data);
    setMoveSize(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
