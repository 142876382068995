import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from "pure-react-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router";
import { vehicleSpecsImageURL } from "../../store/constants/baseUrl";
import { Modal } from "@material-ui/core";
import { FaCircle } from "react-icons/fa";

const BulletPoints = (text, title) => {
  return (
    <div className={css?.BulletMainDiv}>
      <FaCircle className={css?.bulletCircle} />
      <span className={css?.bulletText}>{title + text}</span>
    </div>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

export default function SectionC() {
  // dispatch redux actions
  const dispatch = useDispatch();
  const history = useNavigate();
  // height width
  const [hight, width] = useWindowSize();
  // calling vehicle types and delivery slots API
  useEffect(() => {
    dispatch(getAllVehicles());
  }, []);

  // carousel counter
  const [counter, setcounter] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [showVehicleModal, setShowVehicleModal] = useState(false);

  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );

  const handleCloseVehicleModal = () => {
    setShowVehicleModal(false);
    setSelectedVehicle({});
  };

  const handleReturnBG = (active, inactive) => {
    try {
      if (width > 600) {
        if (counter < vehicleTypesInfo?.length - 3) {
          return active;
        } else {
          return inactive;
        }
      } else {
        if (counter === vehicleTypesInfo?.length) {
          return inactive;
        } else {
          return active;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleReturnColor = (active, inactive) => {
    try {
      if (counter === 1) {
        return inactive;
      } else {
        return active;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.S3MainDiv}>
      <Modal open={showVehicleModal} onClose={handleCloseVehicleModal}>
        <div className={css?.vehicleSpecsMainDiv}>
          <div className={css?.rImgMd}>
            <img
              src={`${vehicleSpecsImageURL}${selectedVehicle.pictureUrl}`}
              alt={selectedVehicle?.title + " image"}
              className={css?.rImg}
            />
          </div>
          <div className={css?.rContent}>
            <div className={css?.rRContent}>
              <div className={css?.contentHeading}>
                {selectedVehicle.title} Info:
              </div>
              {BulletPoints(
                ` ${selectedVehicle.length}ft x ${selectedVehicle.width}ft x ${selectedVehicle.height}ft`,
                "Cargo space (LxWxH):"
              )}
              {selectedVehicle?.cargoSpace?.maxLoad
                ? BulletPoints(
                    selectedVehicle?.cargoSpace?.maxLoad,
                    "Max load (lbs)"
                  )
                : ""}
            </div>
            <div className={css?.rRContent}>
              {selectedVehicle.recommendedUse?.length ? (
                <>
                  <div className={css?.contentHeading}>Recommended use</div>
                  {selectedVehicle.recommendedUse?.map((use) =>
                    BulletPoints(use?.useLine, "")
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={css?.vehicleSpecsDisclaimer}>
            <i>
              All measurements are approximate. Cargo space varies based on make
              and model of vehicle. We categorize our vehicles based on industry
              standard dimensions but these may vary slightly according to the
              vehicle that gets matched to your order.
            </i>
          </div>
        </div>
      </Modal>
      {vehicleTypesInfo &&
        Array.isArray(vehicleTypesInfo) &&
        vehicleTypesInfo?.length > 0 && (
          <CarouselProvider
            naturalSlideWidth={100}
            totalSlides={vehicleTypesInfo?.length}
            visibleSlides={width > 600 ? 4 : 1}
          >
            <div className={css?.carouselTxtNBtnDiv}>
              <div className={css?.carouselTxtDiv}>
                <div className={css?.carouselMainTxt}>
                  We have the right vehicle for all your moving &#38; delivery
                  needs.
                </div>
                <div className={css?.carouselTxt}>
                  In just a few simple steps we can have a vehicle and moover(s)
                  on their way to help move your things.
                </div>
                <div className={css?.carouselTxt}>
                  {" "}
                  Step one, decide if your job is considered Delivery, Moving,
                  Movers or Business. Second, choose the vehicle that fits your
                  needs. Third, input your pickup and drop-off locations. Final
                  step is to sit back, relax and wait for your items to arrive
                  safely.
                </div>
              </div>
              {/* HIDING THESE BUTTONS FOR DESKTOP FOR NOW */}
              <div className={css?.carouselBtnDiv}>
                <ButtonBack
                  className={css?.carouselIcons}
                  style={{
                    backgroundColor: handleReturnColor(
                      "var(--secondary)",
                      "#c0faa5"
                    ),
                  }}
                  onClick={() => {
                    try {
                      if (counter > 1) {
                        setcounter(counter - 1);
                      }
                    } catch (error) {
                      Sentry.captureException(error);
                      console.log(error);
                    }
                  }}
                >
                  <WestIcon />
                </ButtonBack>
                <ButtonNext
                  className={css?.carouselIcons}
                  onClick={() => {
                    try {
                      if (counter < vehicleTypesInfo?.length) {
                        setcounter(counter + 1);
                      }
                    } catch (error) {
                      Sentry.captureException(error);
                      console.log(error);
                    }
                  }}
                  style={{
                    backgroundColor: handleReturnBG(
                      "var(--secondary)",
                      "#c0faa5"
                    ),
                  }}
                >
                  <EastIcon />
                </ButtonNext>
              </div>
            </div>
            <Slider className={css?.vehicleSlider}>
              {vehicleTypesInfo?.map((vehicle, index) => (
                <Slide
                  index={index}
                  className={css?.vehicleCard}
                  onClick={() => {
                    setSelectedVehicle(vehicle);
                    setShowVehicleModal(true);
                  }}
                >
                  <div className={css?.vehicleName}>{vehicle?.title}</div>
                  <img
                    src={vehicleSpecsImageURL + vehicle?.pictureUrl}
                    className={css?.vehicleImageImg}
                    alt={vehicle.title}
                  />
                  <div className={css?.vehicleDimentions}>
                    {vehicle?.length +
                      "ft X " +
                      vehicle?.width +
                      "ft X " +
                      vehicle?.height +
                      "ft"}
                  </div>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        )}
    </div>
  );
}
