import React from "react";
import Layout from "./index";
import css from "../style/blogs.module.css";

const BlogLayout = ({ children, title }) => {
  return (
    <Layout>
      <div className={css.blogTitleBox}>
        <p className={css.blogTitleText}>{title}</p>
        <img
          src="/images/MoovezBlogImage.png"
          alt="moovez blog"
          className={css.blogTitleImg}
        />
      </div>
      {children}
    </Layout>
  );
};

export default BlogLayout;
