import React from "react";
import css from "../../style/earning.module.css";
import Section1Banner from "../../components/Earnings/Section1_Banner";
import Section2BreakdownOfEarning from "../../components/Earnings/Section2_Breakdown_of_earning";
import Section3InfoBubbles from "../../components/Earnings/Section3_InfoBubbles";
import Section4Payout from "../../components/Earnings/Section4_Payout";
import Section5Requirements from "../../components/Earnings/Section5_Requirements";
import Section6FAQs from "../../components/Earnings/Section6_FAQs";

function Earnings() {
  return (
    <div className={css?.EarningMD}>
      <Section1Banner />
      <Section2BreakdownOfEarning />
      <Section3InfoBubbles />
      <Section4Payout />
      <Section5Requirements />
      <Section6FAQs />
    </div>
  );
}

export default Earnings;
