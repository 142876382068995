import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const getBookingSummary =
  (userId, bookingId, setOrder) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}ForgotToStartBooking/GetOrderSummary?userId=${userId}&bookingId=${bookingId}`
      );
      setOrder(data);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export const startTimeResponse =
  (userId, bookingId, userEmail, response, history) => async (dispatch) => {
    try {
      await axios.post(
        `${baseUrl}ForgotToStartBooking/startTimeResponse?esp==${response}&bookingID=${bookingId}`,
        {}
      );
      history(
        response
          ? `/moveCompleted/${userId}/${bookingId}`
          : `/rejectSummary/${userEmail}`
      );
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };
