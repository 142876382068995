import React from "react";
import S1Banner from "../../components/DeliveryInfo/S1Banner";
import S2WhoCanDeliver from "../../components/DeliveryInfo/S2WhoCanDeliver";
import S3Brands from "../../components/DeliveryInfo/S3Brands";
import S4DeliveryGuidelines from "../../components/DeliveryInfo/S4DeliveryGuidelines";
import S5HowYouCanHelp from "../../components/DeliveryInfo/S5HowYouCanHelp";
import S6HowDoesItWork from "../../components/DeliveryInfo/S6HowDoesItWork";
import S7FAQ from "../../components/DeliveryInfo/S7FAQ";
import S8ActionButtons from "../../components/DeliveryInfo/S8ActionButtons";

const DeliveryInfo = () => {
  return (
    <div>
      <S1Banner />
      <S2WhoCanDeliver />
      <S3Brands />
      <S4DeliveryGuidelines />
      <S5HowYouCanHelp />
      <S6HowDoesItWork />
      <S7FAQ />
      <S8ActionButtons />
    </div>
  );
};

export default DeliveryInfo;
