import React from "react";
import Section1TopBanner from "../../components/BecomeAMoover/Section1TopBanner";
import Section2VehicleSlider from "../../components/BecomeAMoover/Section2VehicleSlider";
import Section3BecomeHelper from "../../components/BecomeAMoover/Section3BecomeHelper";
import Section4CompanyInfo from "../../components/BecomeAMoover/Section4CompanyInfo";
import Section5WhyMoovez from "../../components/BecomeAMoover/Section5WhyMoovez";
import Section6HowItWorks from "../../components/BecomeAMoover/Section6HowItWorks";
import Section7Benifits from "../../components/BecomeAMoover/Section7Benifits";
import Section8FAQs from "../../components/BecomeAMoover/Section8FAQs";

const BecomeAMoover = () => {
  return (
    <div>
      <Section1TopBanner />
      <Section2VehicleSlider />
      <Section3BecomeHelper />
      <Section4CompanyInfo />
      <Section5WhyMoovez />
      <Section6HowItWorks />
      <Section7Benifits />
      <Section8FAQs />
    </div>
  );
};

export default BecomeAMoover;
