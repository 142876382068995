import React from "react";
import css from "../../style/businessInfo.module.css";
import { ImCheckboxChecked } from "react-icons/im";

const S3HowItWorks = () => {
  return (
    <>
      <div className={css?.s3TopMd}>
        <h1 className={css?.s3H}>How does it work?</h1>
        <div className={css?.s3Flex}>
          <div className={css?.s3FlexL}>
            <h3 className={css?.s3FlexLH}>Vetted & verified movers</h3>
            <div className={css?.s3FlexLTxt}>
              Every moover must complete our onboarding process before they can
              be approved by our verification team. They must be physically fit,
              able to pass a background check, have proof of insurance and
              maintain a high customer service rating.
            </div>
          </div>
          <div className={css?.s3A_Md}>
            <div className={css?.s3A_Top}>
              <div className={css?.s3A_TopL}>
                <img
                  className={css?.s3A_TopLImg}
                  src="/images/man1.png"
                  alt="ceo"
                />
                <div className={css?.s3A_TopLInfo}>
                  <h4 className={css?.s3A_TopLH}>Nolan</h4>
                  <div className={css?.s3A_TopLTxt}>Moover</div>
                </div>
              </div>
              <img
                className={css?.s3A_TopR}
                src="/images/verified.png"
                alt="verified"
              />
            </div>
            <div className={css?.s3A_Top2}>
              <h3 className={css?.s3A_Top2Txt}>Requirements</h3>
              <h3 className={css?.s3A_Top2Txt}>4/4</h3>
            </div>
            <div className={css?.s3A_Line}>
              <div className={css?.s3A_LineInfo}>
                <h4 className={css?.s3A_LineHeading}>Background check</h4>
                <div className={css?.s3A_LineTxt}>
                  No criminal record, valid drivers license & more.
                </div>
              </div>
              <div className={css?.s3A_LineCheckbox}>
                <ImCheckboxChecked />
              </div>
            </div>
            <div className={css?.s3A_Line}>
              <div className={css?.s3A_LineInfo}>
                <div className={css?.s3A_LineHeading}>Strong & careful</div>
                <div className={css?.s3A_LineTxt}>
                  Experience lifting large items over 100lbs.
                </div>
              </div>
              <div className={css?.s3A_LineCheckbox}>
                <ImCheckboxChecked />
              </div>
            </div>
            <div className={css?.s3A_Line}>
              <div className={css?.s3A_LineInfo}>
                <div className={css?.s3A_LineHeading}>Equipment</div>
                <div className={css?.s3A_LineTxt}>
                  Well-maintained vehicle, dolly, straps and moving blankets.
                </div>
              </div>
              <div className={css?.s3A_LineCheckbox}>
                <ImCheckboxChecked />
              </div>
            </div>
            <div className={css?.s3A_Line}>
              <div className={css?.s3A_LineInfo}>
                <div className={css?.s3A_LineHeading}>Customer service</div>
                <div className={css?.s3A_LineTxt}>
                  All moovers must maintain a rating of 4.7 out of 5 or higher.
                </div>
              </div>
              <div className={css?.s3A_LineCheckbox}>
                <ImCheckboxChecked />
              </div>
            </div>
          </div>
        </div>
        <div className={css?.s3Flex}>
          <div className={css?.s3FlexL}>
            <img
              src="/images/app_use.png"
              alt="app-demo"
              className={css?.s3FlexLImg}
            />
          </div>
          <div className={css?.s3FlexL}>
            <h3 className={css?.s3FlexLH}>Easy to use app</h3>
            <div className={css?.s3FlexLTxt}>
              Give your business the flexibility to schedule deliveries directly
              or leave it to your customers. Simply direct them to our app and
              they’ll be able to have a vehicle and movers on their way in
              minutes.
            </div>
          </div>
        </div>
      </div>
      <div className={css?.s3FlexWhite}>
        <div className={css?.s3FlexL}>
          <h3 className={css?.s3FlexLH}>Delivery dashboard</h3>
          <div className={css?.s3FlexLTxt}>
            A customized dashboard will be created for you. Pricing, vehicle
            options and the booking process will all be adjusted to meet your
            business needs. Delivery insights can be viewed at any time.
          </div>
        </div>
        <div className={css?.s3FlexL}>
          <img
            src="/images/delivery_dashboard.png"
            alt="cash-demo"
            className={css?.s3FlexL2Img}
          />
        </div>
      </div>
    </>
  );
};

export default S3HowItWorks;
