import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import MoveCompleted from "../screens/Booking/moveCompleted";

const MobileMoveCompleted = () => {
  return (
    <Layout>
      <MoveCompleted />
    </Layout>
  );
};

export default MobileMoveCompleted;
