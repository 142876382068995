import React from "react";
import css from "../../style/earning.module.css";

const Section1_Banner = () => {
  return (
    <div className={css?.s1M}>
      <div className={css?.s1L}>
        <div className={css?.s1LHeading}>A guide to how earnings work</div>
        <div className={css?.s1LText}>
          Top moovers can earn up to $40-$50/hr. You might be wondering how
          that’s possible. Let’s take a closer look!
        </div>
      </div>
      <div className={css?.s1R}>
        <img
          className={css?.s1RImage}
          src="/images/driver.png"
          alt="loading_shipment"
        />
      </div>
    </div>
  );
};

export default Section1_Banner;
