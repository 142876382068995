import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import { AiFillCamera, AiFillDelete, AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  postBookingFiles,
  deleteBookingFile,
} from "../../store/actions/bookingActions";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomTooltip } from "../Tooltip/tooltip";

const Upload = ({
  id,
  bookingFiles,
  handleUpdateBookingObject,
  deliveryFiles,
}) => {
  const [fileData, setFileData] = React.useState(null);
  const [isBool, setBool] = React.useState(false);
  const [fileNameShow, setisNameShow] = React.useState(false);
  const [imageID, setImageId] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  // dispatch redux actions
  const dispatch = useDispatch();
  //function to upload media file
  const handleMediaFileUpload = (e) => {
    try {
      console.log("fileData", e?.target?.files);

      const formData = new FormData();
      const files = e.target.files;
      setFileData(files);
      for (let i = 0; i < files.length; i++) {
        console.log(formData);
        formData.append(files[0]?.type, files[i]);
      }
      postBookingFiles(id, formData, setLoader, handleUpdateBookingObject);
      setLoader(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // filter image files from array on the base of id
  const filterImageFiles = (fileId) => {
    const filteredFiles = bookingFiles?.filter((file) => file?.id !== fileId);
    dispatch(deleteBookingFile(fileId));
    return filteredFiles;
  };

  useEffect(() => {
    try {
      const formData = new FormData();
      if (bookingFiles?.length > 0) {
        bookingFiles?.map((i) => {
          if (i.id !== imageID) {
            console.log("id is remaing", i.id);
            formData.append(i.type, i.fileUrl);
            postBookingFiles(
              id,
              formData,
              setLoader,
              handleUpdateBookingObject
            );
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [imageID]);

  return (
    <div className={css?.UploadDiv}>
      <div className={css?.Level2Heading}>
        Upload video or pictures of the items getting moved{" "}
        <CustomTooltip
          title={
            <div className={css?.tooltipMainDiv}>
              <div className={css?.tooltipBullet}>
                <div className={css?.tooltipDotBox}>
                  <div className={css?.tooltipDot}>.</div>
                </div>
                <div className={css?.tooltipText}>
                  By uploading pictures and video of the items that need to be
                  moved, our moovers can properly assess the job before they
                  arrive.
                </div>
              </div>
              <div className={css?.tooltipBullet}>
                <div className={css?.tooltipDotBox}>
                  <div className={css?.tooltipDot}>.</div>
                </div>
                <div className={css?.tooltipText}>
                  If the vehicle you have selected is too small or more moovers
                  are required to complete the job, they will contact you once
                  your job has been submitted and request the appropriate
                  adjustments to be made. No adjustments can be made without
                  your approval first.
                </div>
              </div>
              <div className={css?.tooltipBullet}>
                <div className={css?.tooltipDotBox}>
                  <div className={css?.tooltipDot}>.</div>
                </div>
                <div className={css?.tooltipText}>
                  Sending the proper vehicle and number of moovers will save
                  everyone time and money.
                </div>
              </div>
            </div>
          }
        >
          <img
            src="images/tooltip.svg"
            style={{ display: "inline", width: "30px", cursor: "pointer" }}
            alt=""
          />
        </CustomTooltip>
      </div>

      {/* <div className={css?.cameraSpan}>
          <AiFillCamera />
        </div> */}

      <div
        className={css?.uploadMainDiv}
        onMouseEnter={() => setBool(true)}
        onMouseLeave={() => setBool(false)}
      >
        <div className={css?.cameraSpan}>
          <AiFillCamera />
        </div>

        {!bookingFiles?.length ? (
          <>
            <label
              style={{ cursor: "pointer", width: "500px" }}
              for="upload-booking-image"
            >
              Select file
            </label>
            <input
              style={{ width: "700px" }}
              id="upload-booking-image"
              type="file"
              name="Select File"
              multiple={true}
              defaultValue={fileData?.FileList?.map((i) => i?.File?.name)}
              className={css?.uploadImageCustom}
              class="hidden"
              onChange={(e) => handleMediaFileUpload(e)}
            />
          </>
        ) : isBool ? (
          <>
            <label
              style={{
                paddingTop: !isBool ? null : 20,
                cursor: "pointer",
                width: "500px",
              }}
              for="upload-booking-image"
            >
              Select file
            </label>

            <input
              style={{ width: "600px" }}
              id="upload-booking-image"
              type="file"
              name="Select File"
              multiple={true}
              defaultValue={fileData?.FileList?.map((i) => i?.File?.name)}
              className={css?.uploadImageCustom}
              class="hidden"
              onChange={(e) => handleMediaFileUpload(e)}
            />
          </>
        ) : (
          <p style={{ paddingTop: isBool ? null : 20 }}>
            {bookingFiles?.length && bookingFiles.length > 1
              ? `${bookingFiles?.length} files`
              : bookingFiles?.map((i) => i?.fileUrl)}
          </p>
        )}
      </div>
      {bookingFiles.length >= 1 && (
        <div
          style={{
            marginLeft: 22,
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => {
            setisNameShow(!fileNameShow);
          }}
        >
          <AiFillDelete size={24} color={"red"} />
          <p style={{ marginLeft: 7 }}>Delete images</p>
        </div>
      )}

      {loader ? (
        <div style={{ textAlignLast: "center" }}>
          <Box>
            <CircularProgress color="secondary" />
          </Box>
        </div>
      ) : (
        <div></div>
      )}

      {fileNameShow &&
        bookingFiles?.map((i) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginLeft: 22,
              }}
            >
              <p>{i?.fileUrl}</p>
              <AiOutlineClose
                style={{
                  color: "red",
                  paddingLeft: 15,
                  paddingBottom: 10,
                  cursor: "pointer",
                }}
                size={35}
                onClick={() => {
                  setImageId(i.id);
                  filterImageFiles(i?.id);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default Upload;
