import React from "react";
import Layout from "../layouts";
import BusinessDeliveryInfoScreen from "../screens/Info/BusinessDeliveryInfo";

const BusinessDeliveryInfo = () => {
  return (
    <Layout>
      <BusinessDeliveryInfoScreen />
    </Layout>
  );
};

export default BusinessDeliveryInfo;
