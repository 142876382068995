import React from "react";
import { blogList } from "../../blogs/blogsJson";
import css from "../../style/blogs.module.css";
import { useNavigate } from "react-router";

const BlogList = () => {
  const history = useNavigate();

  return (
    <div className={css?.bl_md}>
      {blogList?.map((blog) => (
        <div
          className={css?.bl_item_md}
          onClick={() => history(`/blog/${blog.link}`)}
        >
          <img className={css?.bl_item_img} src={`/images/${blog.image}`} />
          <div className={css?.bl_item_date}>{blog.date}</div>
          <div className={css?.bl_item_title}>{blog.title}</div>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
