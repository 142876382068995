import React from "react";
import * as Sentry from "@sentry/react";
import RatingScreen from "../screens/Booking/RatingScreen";
import Layout from "../layouts/index";
const MobileRatingPage = () => {
  return (
    <Layout>
      <RatingScreen />
    </Layout>
  );
};

export default MobileRatingPage;
