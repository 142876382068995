import React from "react";
import css from "../../style/businessInfo.module.css";

const S4Industries = () => {
  const itemList = [
    "Design & home staging",
    "Furniture",
    "Construction",
    "Flooring",
    "Real estate",
    "General retail",
    "Wholesale",
    "E-commerce",
  ];
  return (
    <div className={css?.s4Md}>
      <h2 className={css?.s4H}>Some of the industries we serve</h2>
      <div className={css?.s4List}>
        {itemList.map((item, index) => (
          <div className={css?.s4ListItem}>
            <img
              className={css?.s4Img}
              src={`/images/ind${index + 1}.svg`}
              alt={item}
            />
            <div className={css?.s4ListH}>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default S4Industries;
