import React, { useState, useEffect, useRef } from "react";
import css from "../../style/businessInfo.module.css";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modals/Modal";
import {
  // getBusinessAverageDeliveries,
  getBusinessCurrentIndustry,
  // getBusinessGeneralLeadTime,
  // getBusinessOrderPreference,
  // getBusinessPreferedContactMethod,
  // getBusinessVehicleOftenRequired,
  postBookingSignup,
} from "../../store/actions/businessActions";
import { FaChevronDown } from "react-icons/fa";
import { handleValidatePhoneNumber } from "../../Utilities/general";

const BusinessDropdown = ({
  title,
  options = [],
  changeFunction,
  idKey,
  valueKey,
}) => {
  const [toggleDD, setToggleDD] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const menuRef = useRef();
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  return (
    <>
      {options.length ? (
        <div className={css?.dropdownMainDiv}>
          <div
            className={css?.dropdownTextDiv}
            onClick={() => setToggleDD(!toggleDD)}
            ref={menuRef}
            title={title}
          >
            <div className={css?.DDText}>
              {selectedItem ? selectedItem : title}
            </div>
            <FaChevronDown />
          </div>
          <div
            className={css?.DDOptions}
            style={{ display: toggleDD ? "block" : "none" }}
          >
            {options.map((option) => (
              <div
                onClick={() => {
                  changeFunction(option[idKey]);
                  setSelectedItem(option[valueKey]);
                }}
                className={css?.DDOption}
                style={{
                  backgroundColor:
                    option[valueKey] === selectedItem
                      ? "var(--secondary)"
                      : "white",
                }}
              >
                {option[valueKey]}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const S1Form = () => {
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useNavigate();
  // dispatch actions
  const dispatch = useDispatch();
  // custom alert state
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });
  // Business dropdown data
  // const [BusinessAverageDeliveries, setBusinessAverageDeliveries] = useState(
  //   []
  // );
  const [BusinessCurrentIndustry, setBusinessCurrentIndustry] = useState([]);
  // const [BusinessGeneralLeadTime, setBusinessGeneralLeadTime] = useState([]);
  // const [BusinessOrderPreference, setBusinessOrderPreference] = useState([]);
  // const [BusinessPreferedContactMethod, setBusinessPreferedContactMethod] =
  //   useState([]);
  // const [BusinessVehicleOftenRequired, setBusinessVehicleOftenRequired] =
  //   useState([]);

  const [businessObject, setBusinessObject] = useState({
    name: "",
    // lastName: "",
    companyName: "",
    // jobTitle: "",
    contactNumber: "",
    email: "",
    contactId: "",
    industryId: "",
    vehicleId: "",
    averageDeliveries: "",
    lalaMoveId: "",
    bulkOrderDelivery: "",
    leadTimeId: "",
  });

  const HandleChange = (e) => {
    setBusinessObject({ ...businessObject, [e.target.name]: e.target.value });
  };

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // useEffect to show error modal
  useEffect(() => {
    try {
      // dispatch(getBusinessAverageDeliveries(setBusinessAverageDeliveries));
      dispatch(getBusinessCurrentIndustry(setBusinessCurrentIndustry));
      // dispatch(getBusinessGeneralLeadTime(setBusinessGeneralLeadTime));
      // dispatch(getBusinessOrderPreference(setBusinessOrderPreference));
      // dispatch(
      //   getBusinessPreferedContactMethod(setBusinessPreferedContactMethod)
      // );
      // dispatch(
      //   getBusinessVehicleOftenRequired(setBusinessVehicleOftenRequired)
      // );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleCheckNullOrUndefined = (item) => {
    try {
      if (item === null || item === undefined) return false;
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleBusinessSignup = () => {
    try {
      if (
        businessObject.name &&
        // businessObject.lastName &&
        businessObject.companyName &&
        // businessObject.jobTitle &&
        businessObject.contactNumber &&
        businessObject.email
      ) {
        if (
          // handleCheckNullOrUndefined(businessObject.averageId) &&
          // handleCheckNullOrUndefined(businessObject.contactId) &&
          handleCheckNullOrUndefined(businessObject.industryId)
          // &&
          // handleCheckNullOrUndefined(businessObject.vehicleId) &&
          // handleCheckNullOrUndefined(businessObject.lalaMoveId) &&
          // handleCheckNullOrUndefined(businessObject.leadTimeId)
        ) {
          dispatch(
            postBookingSignup(
              {
                ...businessObject,
                contactNumber: parseInt(businessObject.contactNumber),
                bulkOrderDelivery:
                  businessObject.bulkOrderDelivery === "true" ? true : false,
              },
              history
            )
          );
        } else alert("Please select all the options");
      } else alert("Please fill all fields");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const inputFields = [
    {
      name: "name",
      placeholder: "Contact Name",
      value: businessObject.name,
      onChange: HandleChange,
    },
    // {
    //   name: "lastName",
    //   placeholder: "Last Name",
    //   value: businessObject.lastName,
    //   onChange: HandleChange,
    // },
    {
      name: "companyName",
      placeholder: "Company Name",
      value: businessObject.companyName,
      onChange: HandleChange,
    },
    // {
    //   name: "jobTitle",
    //   placeholder: "Job Title",
    //   value: businessObject.jobTitle,
    //   onChange: HandleChange,
    // },
    {
      name: "contactNumber",
      placeholder: "Phone",
      value: businessObject.contactNumber,
      onChange: (e) =>
        handleValidatePhoneNumber(e, (num) =>
          setBusinessObject({
            ...businessObject,
            contactNumber: num,
          })
        ),
    },
    {
      name: "email",
      placeholder: "Email",
      value: businessObject.email,
      onChange: HandleChange,
    },
  ];

  const dropdownList = [
    // {
    //   title: "Preferred contact method",
    //   options: BusinessPreferedContactMethod,
    //   changeFunction: (e) =>
    //     setBusinessObject({ ...businessObject, contactId: e.target.value }),
    //   value: businessObject.contactId,
    //   idKey: "contactId",
    //   valueKey: "contactMethod",
    // },
    {
      title: "Industry",
      options: BusinessCurrentIndustry,
      changeFunction: (id) =>
        setBusinessObject({ ...businessObject, industryId: id }),
      value: businessObject.industryId,
      idKey: "industryId",
      valueKey: "industryName",
    },
    // {
    //   title: "What type of delivery vehicle will you require most often?",
    //   options: BusinessVehicleOftenRequired,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       vehicleId: e.target.value,
    //     }),
    //   value: businessObject.vehicleId,
    //   idKey: "vehicleId",
    //   valueKey: "vehicleName",
    // },
    // {
    //   title: "How many deliveries do you make on average per month?",
    //   options: BusinessAverageDeliveries,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       averageId: e.target.value,
    //     }),
    //   value: businessObject.averageId,
    //   idKey: "averageId",
    //   valueKey: "averageDeliveries",
    // },
    // {
    //   title: "How would you prefer to place order with moovez?",
    //   options: BusinessOrderPreference,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       lalaMoveId: e.target.value,
    //     }),
    //   value: businessObject.lalaMoveId,
    //   idKey: "lalaMoveId",
    //   valueKey: "orderPreferenceWithLalamove",
    // },
    // {
    //   title: "Do you require bulk order delivery (ex. 20+ orders per day)?",
    //   options: [
    //     {
    //       id: true,
    //       value: "Yes",
    //     },
    //     {
    //       id: false,
    //       value: "No",
    //     },
    //   ],
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       bulkOrderDelivery: e.target.value,
    //     }),
    //   value: businessObject.bulkOrderDelivery,
    //   idKey: "id",
    //   valueKey: "value",
    // },
    // {
    //   title:
    //     "How long is the general lead time from when the delivery order is placed to when it needs to be picked up?",
    //   options: BusinessGeneralLeadTime,
    //   changeFunction: (e) =>
    //     setBusinessObject({
    //       ...businessObject,
    //       leadTimeId: e.target.value,
    //     }),
    //   value: businessObject.leadTimeId,
    //   idKey: "leadTimeId",
    //   valueKey: "genLeadTime",
    // },
  ];

  const imagesInfo = ["70%", "40%", "40%", "40%", "40%", "100%"];

  return (
    <div className={css?.s1Md}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.s1_top}>
        <h1 className={css?.s1H1}>
          Move your business into a new phase of growth with reliable delivery.
        </h1>
        <h5 className={css?.s1Txt}>
          We specialize in on-demand big & bulky delivery.
        </h5>
      </div>
      <div className={css?.s1_bottom}>
        <div className={css?.s1_bottom_Left}>
          <h3 className={css?.s1H2}>
            Trusted delivery partner for big and small businesses.
          </h3>
          <div className={css?.s1ImagesDiv}>
            {imagesInfo.map((info, index) => (
              <img
                src={`/images/partner${index + 1}.png`}
                alt={`partner${index + 1}`}
                style={{ width: info, marginBottom: 20 }}
              />
            ))}
          </div>
        </div>
        <div className={css?.mainForm}>
          <h2 className={css?.s1H2}>Tell us about yourself</h2>
          {inputFields.map((inputParams, key) => (
            <input
              placeholder={inputParams.placeholder}
              value={inputParams.value}
              name={inputParams.name}
              onChange={inputParams.onChange}
              className={css?.fullInput}
              key={key}
            />
          ))}
          {dropdownList.map((dropdownParams, index) => (
            <BusinessDropdown {...dropdownParams} key={index} />
          ))}
          {/* <BusinessDropdown title /> */}
          <div className={css?.signupBtnMainDiv}>
            <button className={css?.submitBtn} onClick={handleBusinessSignup}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S1Form;
