import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const getFAQs = async (setFAQs, setFAQsLoading) => {
  try {
    const { data } = await axios.get(`${baseUrl}Faq`);
    console.log("FAQ from API=======", data);
    setFAQs(data);
    setFAQsLoading(false);
  } catch (error) {
    console.log(error);
    setFAQsLoading(false);
    Sentry.captureException(error);
  }
};
