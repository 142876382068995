import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

//Contact us Message
//This action will send user's feedback to admin side.
export const ContactUsMessage =
  (contactObj, setStatus, setError) => async (dispatch) => {
    setError("");
    console.log("contact object", contactObj);
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/SendContactUsMessage`,
        contactObj
      );
      console.log("contact us res data", data);
      setStatus("verify");
      return data;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setError("Something went wrong!");
    }
  };
