import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/ourStory.module.css";

const Section3 = () => {
  return (
    <div className={css?.S3M}>
      <div className={css?.s3L}>
        <div className={css?.s3Heading}>A message about our CEO</div>
        <div className={css?.s3Text}>
          moovez is a culmination of Simon’s East-West experience. Having spent
          8.5 years in tech-enabled logistics in Beijing, China, he witnessed
          the country's rapid transformation into a tech logistics powerhouse.
          This unique experience and perspective allowed him to identify areas
          where Canada was lagging behind. Simon teamed up with a group of
          like-minded and motivated problem solvers to build an innovative
          solution in an industry that has not kept up with the on-demand nature
          of the world.
        </div>
        <div className={css?.s3Text}>
          Their goal is to create a user-friendly and on-demand platform that
          utilizes the existing network of moving and delivery vehicles on the
          road to optimize the entire logistics grid.
        </div>
      </div>
      <div className={css?.s3R}>
        <img className={css?.s3Image} src="/images/ceo.png" alt="CEO" />
      </div>
    </div>
  );
};

export default Section3;
