export const blogList = [
  {
    date: "July 8th, 2024",
    title: "Move to Calgary: The Ultimate Moving Checklist & Packing List",
    image: "blog_0.png",
    description:
      "Moving to Calgary? This moving checklist & packing list covers all you need for a smooth move to Calgary - packing tips, finding a place, utilities, and more.",
    link: "movingToCalgary",
  },
  {
    date: "July 8th, 2024",
    title:
      "The Ultimate Guide to Hiring Professional Movers: Pros and Cons You Need to Know",
    image: "blog_1.png",
    description:
      "Moving can be a daunting task, whether you're relocating your home or office. The key to a successful move often lies in hiring the right professional movers. This comprehensive guide aims to help you navigate the process of hiring movers in Calgary, ensuring you make informed decisions that can save you time, stress, and money.",
    link: "hiringProfessionalMovers",
  },
];

export const blogsContent = {
  movingToCalgary: {
    date: "July 8th, 2024",
    title: "Move to Calgary: The Ultimate Moving Checklist & Packing List",
    image: "blog_0.png",
    description:
      "Moving to Calgary? This moving checklist & packing list covers all you need for a smooth move to Calgary - packing tips, finding a place, utilities, and more.",
    content: [
      { type: "line", data: "" },
      {
        type: "paragraph",
        data: "Alright, you've decided to move to Calgary - excellent choice! This city is killer, with its stunning Rocky Mountain views, buzzing food scene, and chill outdoor vibes. But before you get too hyped, there's some planning to do. Don't stress, though; we've got your back with this ultimate moving checklist and packing list to make sure your move to Calgary is as smooth as butter.",
      },
      {
        type: "heading",
        data: "Your Chill Packing List for Moving to Calgary",
      },
      {
        type: "paragraph",
        data: "Packing can be a drag, but with some intelligent planning, you can make it less painful. Start by decluttering - be honest about what you need and use. Then, grab some sturdy boxes and label them appropriately. Pro tip: pack a separate \"essentials\" box with things you'll need right away, like toiletries, chargers, snacks (moving is hungry work!), and anything you'll need right after your move to Calgary.",
      },
      { type: "heading", data: "Find a Place to Stay In Calgary" },
      {
        type: "paragraph",
        data: "On your moving checklist, find that perfect Calgary home, whether renting or buying—research different 'hoods to find your vibe. Sites like RentFaster and CREB listings are beneficial. And don't sleep on being close to the amenities, transit, and hangouts you'll frequent after moving to Calgary.",
      },
      {
        type: "heading",
        data: "Hire Professionals or DIY Your Move to Calgary?",
      },
      {
        type: "paragraph",
        data: "A giant moving checklist decision: hiring professionals or doing a DIY move to Calgary? If going solo, ensure you have helping hands and supplies like furniture covers and padding. Look into reputable moving companies for longer hauls to Calgary to make this checklist item easier. Moovez can help you move your stuff to wherever you want in Calgary.",
      },
      { type: "image", data: "blog_0_0.png" },
      {
        type: "heading",
        data: "Utilities Should Be On Your Calgary Moving Checklist",
      },
      {
        type: "paragraph",
        data: "Don't overlook setting up utilities on your moving checklist for Calgary. Get electricity, heat, internet, and more squared away before moving to Calgary. Dig to find the best Calgary providers and deals for your new area.",
      },
      { type: "heading", data: "Update Your Address For Your Move to Calgary" },
      {
        type: "paragraph",
        data: "This moving checklist step is easy to overlook, but the key is updating your mailing address when moving to Calgary. You don't want important mail going to your old place! Give Canada Post a heads up, and update the address for any bills, subscriptions, etc.",
      },
      {
        type: "heading",
        data: "Explore Calgary After Checking That Moving Checklist",
      },
      {
        type: "paragraph",
        data: "Once you've tackled the moving checklist for your move to Calgary, it's time for the fun stuff - exploring your new city! Check out local blogs and event listings to find the most excellent spots to eat, drink, see shows, and get that fresh mountain air. Don't be shy about introducing yourself to Calgarians - they are chill and friendly.",
      },
      { type: "line", data: "" },
      {
        type: "paragraph",
        data: "There you have it, squad - the ultimate moving checklist and packing list for making Calgary your new home base. Stay organized, do your research, and lean on your crew if you need help checking tasks off this list. If you need help with your move, Moovez has got you. Soon enough, you'll settle in and live like a real Calgary local! The adventures await in the heart of Alberta.",
      },
    ],
  },
  hiringProfessionalMovers: {
    date: "July 8th, 2024",
    title:
      "The Ultimate Guide to Hiring Professional Movers: Pros and Cons You Need to Know",
    image: "blog_1.png",
    description:
      "Moving can be a daunting task, whether you're relocating your home or office. The key to a successful move often lies in hiring the right professional movers. This comprehensive guide aims to help you navigate the process of hiring movers in Calgary, ensuring you make informed decisions that can save you time, stress, and money.",
    content: [
      { type: "heading", data: "Factors That Affect Moving Cost in Calgary" },
      {
        type: "paragraph",
        data: "Understanding the factors that influence moving costs is crucial for budgeting effectively. Here are the main elements that determine the cost of hiring movers in Calgary:",
      },
      {
        type: "bullet",
        heading: "Distance:",
        data: "Local moves within Calgary are generally less expensive than long-distance relocations.",
      },
      {
        type: "bullet",
        heading: "Volume and Weight:",
        data: "The more items you have, the higher the cost due to the need for larger or more vehicles.",
      },
      {
        type: "bullet",
        heading: "Services Required:",
        data: "Full-service moves, including packing, loading, and unpacking, cost more than basic transportation services.",
      },
      {
        type: "bullet",
        heading: "Time of Year:",
        data: "Moving during peak seasons, such as summer, can increase costs due to higher demand.",
      },
      {
        type: "heading",
        data: "Understanding the Breakdown of Your Moving Quote",
      },
      {
        type: "paragraph",
        data: "A clear understanding of your moving quote can help you avoid unexpected charges. Typically, a moving quote from professional movers in Calgary will include:",
      },
      {
        type: "bullet",
        heading: "Labor Costs:",
        data: "This includes the time taken by movers to pack, load, transport, and unload your belongings.",
      },
      {
        type: "bullet",
        heading: "Transportation Fees:",
        data: "These are costs associated with the moving truck, fuel, and mileage.",
      },
      {
        type: "bullet",
        heading: "Packing Supplies:",
        data: "Costs for boxes, tape, bubble wrap, and other packing materials.",
      },
      {
        type: "bullet",
        heading: "Insurance:",
        data: "Basic coverage is often included, but you may opt for additional insurance for valuable items.",
      },
      { type: "heading", data: "Additional Costs to Consider" },
      {
        type: "paragraph",
        data: "Beyond the basic quote, there are additional costs you should anticipate:",
      },
      {
        type: "bullet",
        heading: "Storage Fees:",
        data: "If there’s a gap between your move-out and move-in dates, you may need temporary storage.",
      },
      {
        type: "bullet",
        heading: "Specialty Item Fees:",
        data: "Items like pianos, artwork, or antiques might incur extra charges due to the special handling required.",
      },
      {
        type: "bullet",
        heading: "Access Fees:",
        data: "If movers need to navigate stairs, elevators, or long carry distances, additional fees may apply.",
      },
      {
        type: "bullet",
        heading: "Last-Minute Changes:",
        data: "Altering your moving date or the services required can lead to extra costs.",
      },
      { type: "heading", data: "Tips to Reduce Your Moving Costs" },
      {
        type: "paragraph",
        data: "Moving doesn't have to break the bank. Here are some practical tips to keep your moving costs down:",
      },
      {
        type: "bullet",
        heading: "Declutter:",
        data: "Sell, donate, or dispose of items you no longer need. Less stuff means lower moving costs.",
      },
      {
        type: "bullet",
        heading: "Pack Yourself:",
        data: "Handle the packing to save on labor costs. Just ensure items are packed securely.",
      },
      {
        type: "bullet",
        heading: "Book Early:",
        data: "Schedule your move well in advance to avoid peak season rates and secure better deals.",
      },
      {
        type: "bullet",
        heading: "Compare Quotes:",
        data: "Get multiple quotes from different moving companies in Calgary to find the best rate.",
      },
      { type: "image", data: "blog_1_0.png" },
      { type: "heading", data: "Choosing a Reputable Moving Company" },
      {
        type: "paragraph",
        data: "Selecting a reputable moving company is crucial for a smooth move. Here’s how to find the best movers in Calgary:",
      },
      {
        type: "bullet",
        heading: "Research and Reviews:",
        data: "Check online reviews and ask for recommendations from friends or family.",
      },
      {
        type: "bullet",
        heading: "Verify Credentials:",
        data: "Ensure the moving company is licensed and insured.",
      },
      {
        type: "bullet",
        heading: "In-Person Estimates:",
        data: "Request in-home or in-office estimates to get a more accurate quote.",
      },
      {
        type: "bullet",
        heading: "Ask Questions:",
        data: "Inquire about their experience, services offered, and any potential extra charges.",
      },
      {
        type: "heading",
        data: "Ensuring a Smooth and Cost-Effective Move in Calgary",
      },
      {
        type: "paragraph",
        data: "Hiring professional movers in Calgary can make your relocation seamless and stress-free. By understanding the factors affecting moving costs, breaking down your quote, considering additional expenses, and implementing cost-saving tips, you can ensure a smooth and cost-effective move. Choosing a reputable moving company will further guarantee that your belongings are in safe hands. With this guide, you’re well-equipped to tackle your next move with confidence.",
      },
      { type: "line", data: "" },
      {
        type: "paragraph",
        data: "Remember, the right preparation and choice of movers can transform your moving experience from overwhelming to manageable. Happy moving!",
      },
    ],
  },
};
