import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/booking.module.css";
import Tooltip from "@mui/material/Tooltip";

const LoadSize = ({ options = [], handleUpdateBookingObject, isHalfLoad }) => {
  const returnCss = (index) => {
    try {
      if (!index && isHalfLoad)
        return { backgroundColor: "var(--primary)", color: "#fff" };
      else if (index && !isHalfLoad)
        return { backgroundColor: "var(--primary)", color: "#fff" };
      return {};
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.loadSizeMainDiv}>
      <div className={css?.Level2Heading} style={{ width: "100%" }}>
        Load Size{" "}
        <Tooltip title="Some text">
          <img
            src="/images/tooltip.svg"
            style={{ display: "inline", width: "30px", cursor: "pointer" }}
            alt=""
          />
        </Tooltip>
      </div>
      <div className={css?.loadSizeBtnsGrp}>
        {options.map((loadSize, index) => (
          <div
            className={css?.loadSizeBtn}
            onClick={() => {
              try {
                handleUpdateBookingObject(
                  "isHalfLoad",
                  loadSize === "Full" ? false : true,
                  "16"
                );
              } catch (error) {
                Sentry.captureException(error);
                console.log(error);
              }
            }}
            style={returnCss(index)}
          >
            {loadSize + " load"}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadSize;
