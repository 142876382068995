import React, { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.botpress.cloud/webchat/v0/inject.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.botpressWebChat.init({
        composerPlaceholder: "Type here...",
        botConversationDescription: "",
        botId: "3c4acb16-85b7-4693-a451-e94b8a9995b8",
        hostUrl: "https://cdn.botpress.cloud/webchat/v0",
        messagingUrl: "https://messaging.botpress.cloud",
        clientId: "3c4acb16-85b7-4693-a451-e94b8a9995b8",
        webhookId: "40f0cd11-774b-4da0-b85a-b5475ef6c6d4",
        lazySocket: true,
        themeName: "prism",
        botName: "Chat with us:",
        emailAddress: "team@moovez.ca",
        stylesheet:
          "https://webchat-styler-css.botpress.app/prod/code/8b00e886-cc1c-4859-9fa6-60e912a86503/v78092/style.csshttps://webchat-styler-css.botpress.app/prod/code/8b00e886-cc1c-4859-9fa6-60e912a86503/v78616/style.css",
        frontendVersion: "v0",
        enableConversationDeletion: true,
        theme: "prism",
        themeColor: "#332a88",
        allowedOrigins: [],
      });
    };
  }, []);

  return <div id="webchat" />;
};

export default Chatbot;
