export const prohebitedTypes = [
  "locality",
  "sublocality",
  "country",
  "administrative_area_level_1",
  "administrative_area_level_2",
  "administrative_area_level_3",
];
const google = window?.google;
// const alberta = new google.maps.LatLng(53.9333, -114);
let bounds = new google.maps.LatLngBounds();
bounds.extend({ lat: 49, lng: -120 });
bounds.extend({ lat: 60.00000073873844, lng: -110.00000111717036 });
export const searchOptions = google
  ? {
      // types: ["address"],
      // location: alberta,
      // radius: 300000,
      locationRestriction: bounds,
      componentRestrictions: { country: "ca" },
    }
  : {};

// need to add businesses in google maps
