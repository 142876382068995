import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { getMyTrips } from "../../store/actions/myTripsActions";
import { useParams } from "react-router";
import TripCard from "../../components/tripCard/tripCard";
import css from "../../style/myTrips.module.css";
import { CircularProgress } from "@material-ui/core";
import { BOOKING_TYPES } from "../../store/constants/baseUrl";
import { FaChevronDown } from "react-icons/fa";

const MyTrips = () => {
  //my trip object
  const [myTripsState, setmyTripsState] = useState();
  const [filter, setfilter] = useState("All");
  const [toggleDD, setToggleDD] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const menuRef = useRef();
  // dispatch redux actions
  const dispatch = useDispatch();
  // getting userId from params
  const { userId } = useParams();
  // calling my trips state
  const { myTripsInfo } = useSelector((state) => state.myTrips);
  // calling my trip API through action
  useEffect(() => {
    try {
      dispatch(getMyTrips(userId, filter));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [filter]);
  // populating my trip state
  useEffect(() => {
    try {
      if (myTripsInfo) {
        setmyTripsState(myTripsInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [myTripsInfo]);
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  return (
    <div className={css?.tripsMainDiv}>
      <div className={css?.tripsLeftDiv}>
        <div className={css?.tripsHeading}>calendar overview</div>
      </div>
      <div className={css?.tripsRightDiv}>
        <div className={css?.tripsTopLine}>
          <div className={css?.tripsHeading}>my trips</div>
          <div className={css?.dropdownMainDiv}>
            <div
              className={css?.dropdownTextDiv}
              onClick={() => setToggleDD(!toggleDD)}
              ref={menuRef}
            >
              {/* <select
            onChange={(e) => setfilter(e.target.value)}
            className={css?.filterDropdown}
          > */}
              {selectedItem ? selectedItem : "All"}
              <FaChevronDown />
            </div>
            <div
              className={css?.DDOptions}
              style={{ display: toggleDD ? "block" : "none" }}
            >
              {BOOKING_TYPES.map((type) => (
                <div
                  onClick={() => {
                    setfilter(type.value);
                    setSelectedItem(type.option);
                  }}
                  className={css?.DDOption}
                  style={{
                    backgroundColor:
                      type.option === selectedItem
                        ? "var(--secondary)"
                        : "white",
                  }}
                >
                  {type.option}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={css?.tripsCardsDiv}>
          {myTripsState ? (
            myTripsState?.map((trip) => <TripCard data={trip} />)
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "40vh",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTrips;
