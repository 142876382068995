import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import VehicleSpecs from "../screens/Info/VehicleInfo";

const VehicleInfo = () => {
  return (
    <Layout>
      <VehicleSpecs />
    </Layout>
  );
};

export default VehicleInfo;
