import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/bookingSummary.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/loginActions";
import { getBookingSummary } from "../../store/actions/bookingSummary";

const OrderList = ({ name, value, index }) => {
  return (
    <div
      className={css?.itemMainDiv}
      style={{ backgroundColor: index % 2 ? "white" : "#E5E4E2" }}
    >
      <div className={css?.itemKey}>{name}</div>
      <div className={css?.itemValue}>{value}</div>
    </div>
  );
};
const OrderSummary = () => {
  const history = useNavigate();
  const { userId, bookingId } = useParams();
  const dispatch = useDispatch();

  const [Summary, setSummary] = useState([]);
  const [bookingInfo, setBookingInfo] = useState([]);

  useEffect(() => {
    try {
      let user = localStorage.getItem("wemoovezUserProfile");
      if (user) {
        user = JSON.parse(user);
        if (user.id.toString() === userId)
          dispatch(getBookingSummary(userId, bookingId, setBookingInfo));
        else {
          localStorage.setItem(
            "redirectToSummary",
            JSON.stringify({ userId, bookingId })
          );
          dispatch(logout(history, "/login"));
        }
      } else {
        localStorage.setItem(
          "redirectToSummary",
          JSON.stringify({ userId, bookingId })
        );
        history("/login");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleAcceptBooking = () => {
    history("/moveCompleted");
  };
  const handleRejectBooking = () => {
    history("/rejectSummary");
  };

  useEffect(() => {
    try {
      if (bookingInfo?.bookingTotalModel) {
        const total = bookingInfo.bookingTotalModel;
        const tempSummary = [];
        if (total.base !== null && total.base !== undefined)
          tempSummary.push({ name: "Base fare", value: `$${total.base}` });
        if (total.totalDistance !== null && total.totalDistance !== undefined)
          tempSummary.push({ name: "Distance", value: total.totalDistance });
        if (total.subtotal !== null && total.subtotal !== undefined)
          tempSummary.push({
            name: "Subtotal Amount",
            value: `$${total.subtotal}`,
          });
        if (total.gstandPst !== null && total.gstandPst !== undefined)
          tempSummary.push({
            name: `GST (${
              total.totalTaxGstpstrate ? total.totalTaxGstpstrate : 5
            }%)`,
            value: "$" + total.gstandPst,
          });
        if (total.totalCharge !== null && total.totalCharge !== undefined)
          tempSummary.push({
            name: "Total Charge",
            value: "$" + total.totalCharge,
          });
        setSummary(tempSummary);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingInfo]);

  return (
    <div className={css?.orderSummaryMainDiv}>
      {Summary?.length ? (
        <div className={css?.cardMainDiv}>
          <div className={css?.headingMainDiv}>Order Summary</div>
          <div className={css?.BookingNoDiv}>Booking # {bookingInfo?.id}</div>
          {Summary.map((properties, index) => (
            <OrderList {...properties} index={index} />
          ))}
          <div className={css?.buttonsDiv}>
            <div
              className={css?.acceptBtn}
              onClick={() => handleAcceptBooking()}
            >
              Accept
            </div>
            <div
              className={css?.rejectBtn}
              onClick={() => handleRejectBooking()}
            >
              Reject
            </div>
          </div>{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderSummary;
