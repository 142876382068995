import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import Privacy from "../screens/Info/Privacy";

const PrivacyPage = () => {
  return (
    <Layout>
      <Privacy />
    </Layout>
  );
};

export default PrivacyPage;
