import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Terms.module.css";
import { NavHashLink } from "react-router-hash-link";

const Terms = () => {
  return (
    <div className={css?.termsMainDiv}>
      <div className={css?.mainHeading}>moovez PLATFORM TERMS OF SERVICE</div>
      <div className={css?.termDate}>Last Updated: January 1, 2024</div>
      <div className={css?.paragraph}>
        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLYAS IT CONSTITUTES A
        LEGALLY BINDING AGREEMENT BETWEEN YOU AND THE APPLICABLE MOOVEZ ENTITY
        (AS FURTHER DEFINED IN SECTION 1 OF PART I) IN RESPECT OF ACCESS AND USE
        OF MOOVEZ’S WEBSITE LOCATED AT{" "}
        <a href="https://www.moovez.ca/">WWW.MOOVEZ.CA</a>, AND ANY SUBDOMAINS
        USEDIN CONNECTION WITH OR RELATED TO THE SAME AND ANY FUTURE VERSIONS
        (THE <b>“WEBSITE”</b>) AS WELLAS MOOVEZ MOBILE APPLICATIONS (THE{" "}
        <b>“MOOVEZ PLATFORM APP”</b>)(TOGETHER THE MOOVEZ PLATFORM APPAND THE
        WEBSITE CONSTITUTE THE <b>“MOOVEZ PLATFORM”</b>) THESE TERMS OF SERVICE
        APPLY TO ALL USERS OF THE MOOVEZ PLATFORM, INCLUDING USERS WHO PROVIDERS
        OF COURIER SERVICES TO OTHER USERS OF THE MOOVEZ PLATFORM, USERS WHO
        PURCHASE SERVICES THROUGH THE MOOVEZ PLATFORM, USERS WHO DOWNLOAD THE
        MOOVEZ PLATFORM APP AND USERS WHO SIMPLY VIEW THE CONTENT ON OR
        AVAILABLE THROUGH THE WEBSITE (THE <b>“SERVICES”</b>). YOU MUST HAVE
        REACHED THE AGE OF MAJORITY IN YOUR JURISDICTION IN ORDER TO PLACE
        ORDERS THROUGH THE MOOVEZ PLATFORM AS A CUSTOMER OR TO OPERATE AS A
        COURIER (AS SUCH TERMS ARE DEFINED IN SECTION 2 OF PART I). IF YOU ARE
        USING THE MOOVEZ PLATFORM ON BEHALF OF ANOTHER PERSON OR A CORPORATE
        ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND
        SUCH PERSON OR ENTITY TO THESE TERMS OF SERVICE. THE TERM <b>“USER”</b>,{" "}
        <b>“YOU”</b>
        OR <b>“YOUR”</b> REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS
        APPLICABLE, ACCESSING OR OTHERWISE USING MOOVEZ PLATFORM OR THE MOOVEZ
        SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE MOOVEZ PLATFORM AND THE
        MOOVEZ SERVICES ARE MADE AVAILABLE TO FACILITATE THE DELIVERY OF PARCELS
        AND OTHER PERSONAL ITEMS (INCLUDING BUT NOT LIMITED TO FURNITURE,
        CONSTRUCTION MATERIALS, HOUSEHOLD ITEMS, ETC.) THAT MAY BE DELIVERED BY
        COURIERS. YOU UNDERSTAND THAT MOOVEZ’S TECHNOLOGYAND SERVICES FACILITATE
        THESE TRANSACTIONS IN CERTAIN JURISDICTIONS ONLY AND THAT MOOVEZIS NOT A
        COURIER NOR IS MOOVEZ RESPONSIBLE FOR FULFILLING DELIVERIES PLACED BY
        CUSTOMERS WITH COURIERS.
      </div>
      <div className={css?.paragraph}>
        <b>
          BY ACCESSING, BROWSING AND USING THE MOOVEZ PLATFORM, YOU INDICATE
          THAT YOU HAVE READ AND ACCEPT THESE TERMS OF SERVICE. IF YOU DO NOT
          ACCEPT THESE TERMS OF SERVICE, THEN YOU MAY NOT ACCESS OR USE THE
          MOOVEZ PLATFORM OR MOOVEZ SERVICES OR ANY OF THEIR CONTENT OR SERVICES
        </b>
      </div>
      <div className={css?.paragraph}>
        <b>IMPORTANT NOTICE:</b> PLEASE BE AWARE THAT SECTIONS 9 AND 10 OF PART
        I CONTAIN IMPORTANT DISCLAIMERS AND LIMITATIONS OF LIABILITY. IN
        ADDITION, SECTION 12 OF PART I CONTAINS PROVISIONS GOVERNING HOW CLAIMS
        THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT
        LIMITATION, ANY CLAIMS
      </div>
      <div className={css?.paragraph}>
        THAT AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF THIS
        AGREEMENT. IN PARTICULAR, IT CONTAINS AN ALTERNATIVE DISPUTE RESOLUTION
        PROCESS AND YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF
        AGAINST US ON AN INDIVIDUAL BASIS, NOTAS A PLAINTIFF OR CLASS MEMBER IN
        ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING AND YOU ARE WAIVING
        YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON
        YOUR CLAIMS.
      </div>
      <div className={css?.paragraph}>
        <b>
          THESE TERMS OF SERVICE ARE SUBJECT TO CHANGE BY MOOVEZ IN ITS SOLE
          DISCRETION AT ANY TIME TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
          LAW
        </b>{" "}
        When changes are made, moovez will make a new copy of the Terms of
        Service available on the moovez Platform. We will also update the "Last
        Updated" date at the top of the Terms of Service. If we make any
        material changes, and you have registered with us to create an account
        we may alsosend an e-mail to you at the last e-mail address you provided
        to us pursuant to the Terms of Service. Any changes to the Terms of
        Service will be effective immediately after posting notice of such
        changes on the moovez Platform. moovez may require you to provide
        consent to the updatedTerms of Service in a specified manner before
        further use of the moovez Platform and/or the moovez Services is
        permitted. If you do not agree to any change(s) after receiving a notice
        of such change(s), you shall stop using the moovez Platform and/or the
        moovez Services. Otherwise, yourcontinued use of the moovez Platform
        and/or moovez Services constitutes your acceptance of such change(s).
        PLEASE REGULARLY CHECK THE MOOVEZ PLATFORM TO VIEW THE MOST CURRENT
        TERMS.
      </div>
      <div className={css?.paragraph}>
        <b>INDEX</b> You should read these Terms of Service in their entirety,
        however, this index is provided for ease of reference to enable you to
        navigate to specific sections of the Terms.
      </div>
      <div className={css?.indexL1}>
        <div className={css?.indexL1index}>I.</div>{" "}
        <div className={css?.indexL1text}>
          <NavHashLink
            to="/terms#generalTerms"
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "start" })
            }
            activeStyle={{ color: "red" }}
            // etc...
          >
            {" "}
            GENERAL TERMS
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>1.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#moovezParties"
            scroll={(el) =>
              el.scrollIntoView({ behavior: "auto", block: "start" })
            }
            activeStyle={{ color: "red" }}
            // etc...
          >
            moovez Parties
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>2.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#UsersandApplicableLegalTerms"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Users and Applicable Legal Terms
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>3.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#moovezAccounts"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            moovezAccounts
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>4.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#LicensedAccess"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Licensed Access to the moovez Platform and Permitted Use
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>5.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#NoUnlawfulorProhibitedUse"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            No Unlawful or Prohibited Use
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>6.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Intellectual Property Terms
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>7.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#AvailabilityandUpdates"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Advertising and Third-Party Websites
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>8.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#AvailabilityandUpdates"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Platform Updates and Availability
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>9.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#DISCLAIMERS"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Disclaimers
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>10.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#NOIMPLIEDWARRANTIES"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            No Implied Warranties; Limitations of Liability
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>11.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#Indemnification"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Indemnification
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>12.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#DisputeResolution"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Governing Law and Dispute Resolution
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>13.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#Miscellaneous"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Miscellaneous
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL1}>
        <div className={css?.indexL1index}>II.</div>
        <div className={css?.indexL1text}>
          <NavHashLink
            to="/terms#ORDERINGTERMS"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            ORDERING TERMS
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>1.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#ServicesandProducts"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            {" "}
            Access, moovez Services and Products
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>2.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#moovezCustomerAccount"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            moovez CustomerAccount
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>3.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#PlacingDeliveries"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Placing Deliveries
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>4.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#ThirdPartyDrivers"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            {" "}
            Customer Care, Third Party Drivers and Refunds
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>5.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#PaymentProcessingServices"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Payment Processing Services
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>6.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#PriceandPayment"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Price and Payment
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL1}>
        <div className={css?.indexL1index}>III.</div>
        <div className={css?.indexL1text}>
          <NavHashLink
            to="/terms#REFERAFRIENDPROGRAM"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            REFER-A-FRIEND PROGRAM
          </NavHashLink>
        </div>
      </div>
      <div className={css?.indexL2}>
        <div className={css?.indexL2index}>1.</div>
        <div className={css?.indexL2text}>
          <NavHashLink
            to="/terms#REFERALPROGRAM"
            activeClassName={css?.hashlink}
            activeStyle={{ color: "red" }}
            // etc...
          >
            Refer-A-Friend Program
          </NavHashLink>
        </div>
      </div>
      <div className={css?.bullet1} id="generalTerms">
        <div className={css?.bullet1Index}>I.</div>
        <div className={css?.bullet1text}>GENERAL TERMS</div>
      </div>
      <div className={css?.bullet2}>
        <div className={css?.bullet2Index}>1.</div>
        <div className={css?.bullet2text} id="moovezParties">
          moovez Parties
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          If you are accessing or using the moovez Platform within Canada,
          theseTerms of Service form an agreement between you and MUV Logistics
          Network Technologies Ltd., a company incorporated under the laws of
          Canada.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          MUV Logistics Network Technologies Ltd. is referred to in these Terms
          of Service as the <b>“moovez Group”</b>. For the avoidance of doubt,
          moovez does not include any third parties such as Vendors and Drivers
          (as such terms aredefined in Section 2 of Part I offering services
          through the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet2}>
        <div className={css?.bullet2Index}>2.</div>
        <div className={css?.bullet2text} id="UsersandApplicableLegalTerms">
          Users and Applicable Legal Terms
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>Definition of Users.</b> The moovez Platform facilitates the
          ordering by consumers/end users of the moovez Platform{" "}
          <b>("Customers")</b>
          of deliveryand courier services through independently contracted
          couriers who are users of the moovez Platform (the <b>"Drivers"</b>).
          Collectively, Customers, Drivers constitute <b>"Users"</b> of the
          moovez Platform and these Terms of Service apply to all Users
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>moovez Privacy Policy.</b> Please note that important information
          about moovez’s practices on the collection, use and disclosure of
          Users’ personal information submitted via the Website or the moovez
          Platform App is governed by our Privacy Policy, the terms of which are
          incorporated into this Terms of Service. Please see further:
          <a href="https://www.iliketomuvit.com/Home/MUVPrivacyPolicy">
            https://www.iliketomuvit.com/Home/MUVPrivacyPolicy
          </a>{" "}
          (<b>"Privacy Policy"</b>).
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          <b>Additional Terms for Drivers.</b> In addition to these Terms of
          Service and the Privacy Policy, Drivers are subject to additional
          terms agreed in separate agreements with moovez in respect of the
          services offered by such Users. In the event of any conflict between
          these Terms of Services and the separate agreement you have with
          moovez, the terms of the separate moovez agreement will govern.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          <b>
            Additional Terms and Conditions for moovez Platform Web App Users.
          </b>
          To the extent the other terms and conditions of these Terms of Service
          are less restrictive than, or otherwise conflict with, the terms and
          conditions of this Section, the more restrictive or conflicting terms
          and conditions in this Section apply, but solely with respect to the
          use of Google Maps services andcontent.
        </div>
      </div>
      <div className={css?.bullet3Note}>
        The moovez Platform may use services and content from Google Maps or
        Google Earth. You acknowledge and agree that you are bound by the{" "}
        <a href="">Google Maps/Google Earth Additional Terms of Service</a>{" "}
        (including the <a href="">Google Privacy Policy</a>).
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          <b>Text Messaging, Notifications, and Communications.</b> By creating
          an account on the moovez Platform, you agree that the Services may
          send you informational text (SMS) messages as part of the normal
          business operation of your use of the Services. You may opt-out of
          receiving text (SMS) messages from moovez Group at any time by sending
          an email to <b>[INSERT LINK]</b> indicating that you no longer wish to
          receive such messages, along with the phone number of the mobile
          device receiving the messages. You acknowledge that opting out of
          receiving text (SMS) messages may impact your use of the Services.
        </div>
      </div>
      <div className={css?.bullet2} id="moovezAccounts">
        <div className={css?.bullet2Index}>1.</div>
        <div className={css?.bullet2text}>moovez Accounts</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1</div>
        <div className={css?.bullet3Text}>
          <b>Accurate Information.</b> You represent and warrant that all
          information supplied by you on the moovez Platform (including
          information provided byyou to create an account for use of the moovez
          Platform (a <b>"moovez Account"</b>)) or in connection with your use
          of the moovez Services is true, accurate, current and complete
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>Security of moovez Accounts.</b> Any moovez Account must be kept
          secure and you agree that you will not share or disclose your moovez
          Account credentials with anyone. No members of the moovez Group will
          be liable forany loss or damage arising from your failure to
          safeguarding your moovez Account, use a strong password or limit its
          use to your moovez Account. You may browse some of the moovez Platform
          without creating a moovez Account.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          <b>Discretionary Access to moovez Accounts.</b> moovez reserves the
          right to deny your request for a moovez Account (including usernames)
          and to disable or terminate access to any moovez Account (including
          usernames) issued to you at any time in moovez’s sole discretion. If
          moovez disables access to a moovez Account issued to you, you will be
          prevented from accessing the moovez Platform or the moovez Services,
          your moovez Account details, Deliveries or other information that is
          associated with your moovez Account.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          <b>Suspension, Term, Termination and Deletion of moovez Accounts.</b>{" "}
          We may suspend or terminate your ability to access the moovez
          Platform, or cease providing you with all or part of the moovez
          Services at any time for any or no reason, including, if we believe:
          (i) you may have violated these Terms of Service, (ii) you may create
          risk or possible legal exposure for us; (iii) prolonged inactivity; or
          (iv) our provision of the moovez Services to you is no longer
          commercially viable. Suspension or termination may also include
          removal of some or all of the materials uploaded by you. We may make
          reasonable efforts to notify you by the email address associated with
          your account, through the moovez Platform or the next time you attempt
          to access your account, depending on the circumstances. You
          acknowledge and agree that all suspensions or terminations may be made
          by moovez in its sole discretion and that no members of the moovez
          Group will be liable to you or any third-party for any suspension or
          termination of your access or for the removal of any of the materials
          uploaded by you to the moovez Platform. Any suspension or termination
          of this Agreement by moovez will be in addition to any and all other
          rights and remedies that moovez may have.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          <b>Account Deletion and Termination.</b> You may request the deletion
          of your account by requesting (by email or through any then-available
          interfaces) thatyour moovez Account be deleted, ceasing use of the
          moovez Platform, the moovez Services and uninstalling and removing all
          local software components thereof, if any. If you request deletion of
          your moovez Customer Account, we may disable your moovez Customer
          Account but keep the disabled moovez Customer Account for fraud
          prevention or other lawful purposes. If we have received all
          outstanding payment from you, you may request termination these Terms
          of Service at any time by requesting by email (or any then-available
          interface on the moovez Platform) that your moovez Account be deleted.
          We may terminate these Terms of Service at any time by giving notice
          to you, at our discretion, by email at your current email address on
          file with us or through the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet2} id="LicensedAccess">
        <div className={css?.bullet2Index}>4.</div>
        <div className={css?.bullet2text}>
          Licensed Access to The moovez Platform and Permitted Use
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          Subject to your compliance with these Terms of Service, moovez hereby
          grants you a personal, revocable, non-exclusive and non-transferable
          license to use the moovez Platform in accordance with these Terms of
          Service in the following manner depending on the type of User that you
          are:
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>1.</div>
        <div className={css?.bullet4Text}>
          for Customers, you may use the moovez Platform to request
          courier/delivery services and pay for these services. You may alsouse
          feedback features of the moovez Platform to rate, post comments on
          Drivers (as applicable);
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>2.</div>
        <div className={css?.bullet4Text}>
          for Drivers, you may create an account and use the moovez Platformto
          manage and fulfill requests for deliveries
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>3.</div>
        <div className={css?.bullet4Text}>
          for all Customers and Drivers, you acknowledge and agree that the
          Services are made available solely for your personal,noncommercial
          use. YOU ACKNOWLEDGE AND AGREE THAT MOOVEZ GROUPDOES NOT PROVIDE
          TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION
          CARRIER AND THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES ARE
          PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED
          BY MOOVEZ GROUP OR ANY OF ITS AFFILIATES.
        </div>
      </div>
      <div className={css?.bullet4Note}>
        (i)-(iii) constitute the <b>"Permitted Use"</b> as applicable for a
        specific type of User.
      </div>

      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>Reservation of Rights.</b> moovez retains the right, at its sole
          discretion, to deny access to anyone to the moovez Platform or the
          moovez Services, atany time and for any reason (or no reason at all),
          including, but not limited to, for violation of these Terms of Service
          or use other than the Permitted Use. You will cease and desist from
          any such access or use of the moovezPlatform or moovez Services
          immediately upon request by moovez.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          <b>Geographic Application of the moovez Platform.</b> The moovez
          Platform and/or specific services may not be available at all or any
          times and in all orany jurisdictions. Furthermore, nothing on the
          moovez Platform constitutes an offer or solicitation to buy or sell
          any product or service to anyone in any jurisdiction in which such an
          offer or solicitation may be prohibited by law.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          Changes to the moovez Platform. moovez reserves the right to change or
          allow a third party to change any information, material or content
          contained on or provided through the moovez Platform (the "Content")
          at any time, andfrom time to time, without notice. For greater
          certainty, the Content include the User Content (as defined below).
        </div>
      </div>
      <div className={css?.bullet2} id="NoUnlawfulorProhibitedUse">
        <div className={css?.bullet2Index}>5.</div>
        <div className={css?.bullet2text}>No Unlawful or Prohibited Use</div>
      </div>
      <div className={css?.bullet3Note}>
        You may not, without moovez's prior written permission, use the moovez
        Platform, the moovez Services or any Content (including other User’s
        User Content) for purposes other than the applicable Permitted Use.
        Without limiting the generality of the foregoing, you will not, and will
        not permit anyone else to, or attempt to use the moovez Platform and/or
        the Content to:
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          "frame", "mirror" or otherwise incorporate the moovez Platform, the
          moovezServices or the Content or any part thereof on any commercial or
          non- commercial website;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          access, monitor or copy any part of the moovez Platform, the moovez
          Services or the Content using any robot, spider, scraper or other
          automatedmeans or any manual process for any purpose without moovez’s
          express written permission;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>violate any laws;</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          violate the restrictions in any robot exclusion headers on the Content
          or themoovez Platform or bypass or circumvent other measures employed
          to prevent or limit access to the moovez Services or the moovez
          Platform;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          remove (or permit anyone else to remove) any watermarks, labels or
          other legal or proprietary notices included in the moovez Platform or
          the Content;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>6.</div>
        <div className={css?.bullet3Text}>
          modify or attempt to modify (or permit anyone else to modify or
          attempt to modify) the moovez Platform, including any modification for
          the purpose ofdisguising or changing any indications of the ownership
          or source of the moovez Platform;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>7.</div>
        <div className={css?.bullet3Text}>
          forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any material transmitted to or through the moovez
          Platform or impersonate another person or organization or misrepresent
          their affiliationwith a person or entity;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>8.</div>
        <div className={css?.bullet3Text}>
          attempt to, assist, authorise or encourage others to circumvent,
          disable ordefeat any of the security features or components, such as
          digital rights management software or encryption, that protect the
          moovez Platform or the moovez Services;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>9.</div>
        <div className={css?.bullet3Text}>
          copy, reproduce, modify, translate, distribute, transfer, sell,
          publish, broadcast, perform, transmit, license or circulate in any
          form any part of themoovez Platform or the Content;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>10.</div>
        <div className={css?.bullet3Text}>
          license, sell, rent, lease, transfer, assign, distribute, host, or
          otherwise commercially exploit the moovez Platform;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>11.</div>
        <div className={css?.bullet3Text}>
          create derivative works based on the moovez Platform, the moovez
          Services or the Content, in whole or in part, or decompile,
          disassemble,reverse engineer or other exploit any part of the moovez
          Platform, the moovez Services or the Content;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>12.</div>
        <div className={css?.bullet3Text}>
          use of access the moovez Platform in a manner that violates the rights
          (including, but not limited to contractual, intellectual property or
          proprietaryrights) of any third party; or
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>13.</div>
        <div className={css?.bullet3Text}>
          upload to or transmit through the moovez Platform any information,
          images, text, data, media or other content that is offensive, harmful,
          tortuous, hateful,obscene, defamatory or violates any laws or is
          otherwise objectionable, in each case as determined by moovez in its
          sole discretion.
        </div>
      </div>
      <div className={css?.bullet2} id="IntellectualPropertyTerms">
        <div className={css?.bullet2Index}>6.</div>
        <div className={css?.bullet2text}>Intellectual Property Terms</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1</div>
        <div className={css?.bullet3Text}>
          <b>Content and Intellectual Property</b>
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>i.</div>
        <div className={css?.bullet4text}>
          moovez Content and Intellectual Property Rights. The moovez Platform
          and all content contained therein (excluding User Contentas defined in
          Section 6(b) below), is owned or licensed by moovez and protected by
          copyright, trade-mark and other intellectual property laws ("moovez
          Content"). moovez expressly reserves all rights in the moovez Platform
          and all materials provided by moovez in connection with these Terms of
          Service that are not specifically granted to you. You acknowledge that
          all right, title and interest in the moovez Platform, all materials
          provided by moovez in connection with these Terms of Service
          (including the moovez Content), and any update, adaptation,
          translation, customization or derivative work thereof, and all
          intellectual property rights therein will remain with moovez (or third
          party suppliers or licensors, if applicable), and that the moovez
          Platform and all materials providedby moovez hereunder are licensed
          and not "sold" to you
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>ii.</div>
        <div className={css?.bullet4text}>
          <b>User Content.</b> All information, data, text, software, music,
          sound, photographs, graphics, video, messages or other materials,
          whether publicly posted or privately transmitted to the moovez
          Platform by Users ("User Content"), is the sole responsibility of such
          Users. Thismeans that the User, and not members of the moovez Group,
          are entirely responsible for all such material uploaded, posted,
          emailed, transmitted or otherwise made available on the moovez
          Platform. moovez may monitor User Content from time to time but moovez
          does not guarantee the accuracy, integrity or quality of such content.
          Under no circumstances will any members of the moovez Group be liable
          in any way for any User Content including for any loss or damage of
          any kind incurred as a result of the viewing or useof any materials
          posted, emailed, transmitted or otherwise made available via the
          moovez Platform. To the extent your User Content contains any
          personally identifiable information, that data is handled in
          accordance with the moovez Privacy Policy and all other User Content
          will be considered non-confidential.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>iii.</div>
        <div className={css?.bullet4text}>
          <b>User-Related Content.</b> moovez may collect data, information,
          records, files, material or other content from your local computer
          (including mobile) system or from third-parties with your permission,
          and (in each case) including all results from processing such data,
          including compilations, and derivative works thereof ("User-Related
          Content"). You grant to moovez Group a perpetual, transferrable,
          irrevocable, royalty-free, worldwide and sublicensable license to
          access, collect, store and use any such User-Related Content. To the
          extent your User-Related Content contains any personally
          identifiableinformation, that data is handled in accordance with the
          moovez Privacy Policy. User-Related Content is not considered
          confidential orproprietary
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>User Content License to moovez.</b> By submitting, posting or
          displaying User Content on or through the moovez Platform or otherwise
          to moovez, you grant moovez Group, a worldwide, non-exclusive,
          royalty-free, perpetual, transferable, and fully sublicensable right
          to use, refrain from using, remove, reproduce, modify, edit, copy,
          adapt, publish, translate, create derivative works from, distribute,
          transmit, perform display and otherwise use User Content, in whole or
          in part. You further grant moovez the right (although moovez does not
          have the obligation) to pursue at law any person or entity that
          violates your or moovez’s rights in the User Content by a breach of
          these Terms of Service. You represent and warrant that you have all
          the rights, power and authority necessary to grant the rights granted
          herein to any User Content that you submit. For the avoidance of
          doubt, moovez has no obligation to post or display any User Content on
          the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          <b>Use of moovez Content and User Content by other Users.</b> You may
          only use moovez Content and other Users Content (eg. menus provided by
          Vendors) for the applicable Permitted Use. If you print extracts from
          the moovez Platform for your own personal, non- commercial use, you
          must notmodify the digital or papers of such materials or use any
          graphics, pictures, photographs or videos separately from any
          accompanying text
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          <b>Terms on Submissions/Feedback and Other User Content</b>
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>i.</div>
        <div className={css?.bullet4text}>
          Creating Submissions and Other User Content. Through the capabilities
          of the moovez Platform you may be able to provide ratings
          ("Submissions"). Submissions are a subset of User Content. In creating
          and posting any Submissions (or other User Content) you represent and
          warrant that you will not post or transmitto or from the moovez
          Platform any material or content which does or may:
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>1.</div>
        <div className={css?.bullet5text}>
          Breach any applicable local, national or international law;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>2.</div>
        <div className={css?.bullet5text}>Be unlawful or fraudulent;</div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>3.</div>
        <div className={css?.bullet5text}>
          Amount to unauthorised advertising;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>4.</div>
        <div className={css?.bullet5text}>
          Contain any defamatory, obscene or offensive material;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>5.</div>
        <div className={css?.bullet5text}>
          Promote violence or discrimination;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>6.</div>
        <div className={css?.bullet5text}>
          Infringe the intellectual property rights of another person;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>7.</div>
        <div className={css?.bullet5text}>
          Breach any legal duty owed to a third party (such as a dutyof
          confidence);
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>8.</div>
        <div className={css?.bullet5text}>
          Promote illegal activity or invade another’s privacy;
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>9.</div>
        <div className={css?.bullet5text}>
          Give the impression that they originate from us; or
        </div>
      </div>
      <div className={css?.bullet5}>
        <div className={css?.bullet5Index}>10.</div>
        <div className={css?.bullet5text}>
          Be used to impersonate another person or to misrepresentyour
          affiliation with another person.
        </div>
      </div>
      <div className={css?.bullet4Note}>
        You will be solely liable for any damages resulting from any violation
        of the foregoing restrictions, or any other harm resulting from your
        posting of Submissions or other User Content to the moovez Platform.
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>ii.</div>
        <div className={css?.bullet4text}>
          <b>Status of Submissions on the moovez Platform.</b> We reserve the
          right to remove or edit at any time any Submissions or other User
          Content posted, uploaded or transmitted to the moovez Platform that we
          determine breaches the restrictions in Section 6(d)(i) above or is
          otherwise objectionable or may expose us or any third parties to any
          harm or liability of any type, or for any reason. Any Submissions on
          the moovez Platform is for information purposes only and does not
          constitute advice from us or the opinions of anyone within the moovez
          Group. User Content may reflect the opinions of Customers who have
          ordered through the moovez Platform and any statements, advice or
          opinions provided by such persons are theirs only. Accordingly, to the
          fullest extent permitted by law, no members of the moovez Group assume
          any responsibilityor liability to any person for any User Content,
          including without limitation any mistakes, defamation, obscenity,
          omissions or falsehoods that you may encounter in any such materials.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>iii.</div>
        <div className={css?.bullet4text}>
          <b>Public Display of Submissions.</b> You acknowledge that moovez
          maychoose to provide attribution of your Submissions (for example,
          listinga date, User’s name and city on a User’s rating that you
          submit) at moovez’s discretion, and that such User Content may be
          visible to other Users and visitors to the moovez Platform. By
          providing a rating/review you acknowledge and consent to us using your
          given name in association with the rating/review. All ratings/reviews
          that we receive may be moderated before we publish them. We may do
          this to make sure that ratings/reviews fit our content guidelines.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          <b>Note on Privacy.</b> If you access any Person Information through
          or in connection with the moovez Platform you agree that you will
          treat such Personal Information in accordance with moovez's current
          Privacy Policy and you will not collect or harvest any Personal
          Information, including PersonalInformation of other users, except as
          permitted by these Terms of Service.
        </div>
      </div>
      <div className={css?.bullet2} id="AvailabilityandUpdates">
        <div className={css?.bullet2Index}>7.</div>
        <div className={css?.bullet2text}>Availability and Updates</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          moovez may alter, suspend, or discontinue the moovez Platform or
          Serviceat any time and for any reason or no reason, without notice.
          The moovez Platform or Service may be unavailable from time to time
          due to maintenance or malfunction of computer or network equipment or
          other reasons. moovez may periodically add or update the information
          and materials on this the moovez Platform without notice.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          While we try to ensure the moovez Platform is normally available
          twenty four (24) hours a day, we do not undertake any obligation to do
          so, and no members of the moovez Group will be liable to you if the
          moovez Platformis unavailable at any time or for any period.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          moovez may upgrade or alter the moovez Platform at any time. moovez
          mayremotely access the moovez Platform (including for greater
          certainty any moovez Platform App) at any time for any lawful reason,
          in particular to update the software or to perform software
          maintenance.
        </div>
      </div>
      <div className={css?.bullet2} id="DISCLAIMERS">
        <div className={css?.bullet2Index}>8.</div>
        <div className={css?.bullet2text}>DISCLAIMERS</div>
      </div>
      <div className={css?.bullet3Note}>
        THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING QUEBEC, DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF LEGAL WARRANTIES, CONDITIONS OR
        REPRESENTATIONS. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
        EXCLUSIONS OR LIMITATIONS IN THESE TERMS OF SERVICE (INCLUDING THE
        FOLLOWING DISCLAIMERS AND LIMITATIONS OF LIABILITY) MAY NOTAPPLYAND YOU
        MAY HAVE ADDITIONAL RIGHTS.
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>Content:</b> While we try to ensure that information on the moovez
          Platform iscorrect, we do not promise it is accurate or complete. We
          may make changes to the material on the moovez Platform including to
          Services and prices described on it, at any time without notice. The
          material on the moovez Platform may be out of date, and we make no
          commitment to update that material.
        </div>
      </div>
      <div className={css?.bullet3Note}>
        The foregoing disclaimers do not affect Customer’s statutory rights
        against any Vendor or Driver.
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>Service Disclaimers:</b>
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>i.</div>
        <div className={css?.bullet4text}>
          <b>Viruses.</b> The downloading and viewing of the moovez Platform or
          the Content is done at your own risk. moovez cannot and does not
          guarantee or warrant that the moovez Platform or the Content are
          compatible with your computer system or that the moovez Platformor the
          Content, or any links from the moovez Platform or the Content, will be
          free of viruses, worms, malware, trojan horses or disabling devices or
          other code that manifests contaminating or destructive properties. You
          are responsible for implementing safeguards to protect the security
          and integrity of your computer system, and you are responsible for the
          entire cost of any service, repairs or connections of and to your
          computer system that may benecessary as a result of your use of the
          moovez Platform.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>ii.</div>
        <div className={css?.bullet4text}>
          <b>Communications Not Confidential.</b> moovez does not guarantee
          theconfidentiality of any communications made by you through the
          moovez Platform. Although moovez generally adheres to the accepted
          industry practices in securing the transmission of data to, from and
          through the moovez Platform, you understand, agree and acknowledge
          that moovez cannot and does not guarantee the security of data
          transmitted over the Internet or public networks in connection with
          your use of the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>NO IMPLIED WARRANTIES AND DISCLAIMERS.</b> THE MOOVEZ SERVICES, THE
          MOOVEZ PLATFORM AND THE CONTENTARE PROVIDED "AS IS" WITHOUT WARRANTY
          OR CONDITION OF ANY KIND. TO THE FULLEST EXTENTPERMITTED BY LAW,
          MOOVEZ DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY
          KIND WITH RESPECT TO THE MOOVEZ SERVICES, MOOVEZ PLATFORM AND THE
          CONTENT WHETHER EXPRESS, IMPLIED, STATUTORY OR COLLATERAL, INCLUDING,
          WITHOUT LIMITATION, THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
          OR THAT THE MOOVEZ SERVICES, THE MOOVEZ PLATFORM OR THE CONTENTARE OR
          WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION. MOOVEZ GROUP
          MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE
          RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE
          SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE
          SERVICES, OR THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD
          PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR
          USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION
          THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
          UNDER APPLICABLE LAW
        </div>
      </div>
      <div className={css?.bullet2} id="NOIMPLIEDWARRANTIES">
        <div className={css?.bullet2Index}>9.</div>
        <div className={css?.bullet2text}>
          NO IMPLIED WARRANTIES; LIMITATIONS OF LIABILITY
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>NO IMPLIED WARRANTIES AND DISCLAIMERS.</b> THE MOOVEZ SERVICES, THE
          MOOVEZ PLATFORM AND THE CONTENTARE PROVIDED "AS IS" WITHOUT WARRANTY
          OR CONDITION OF ANY KIND. TO THE FULLEST EXTENTPERMITTED BY LAW,
          MOOVEZ DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY
          KIND WITH RESPECT TO THE MOOVEZ SERVICES, MOOVEZ PLATFORM AND THE
          CONTENT WHETHER EXPRESS, IMPLIED, STATUTORY OR COLLATERAL, INCLUDING,
          WITHOUT LIMITATION, THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
          OR THAT THE MOOVEZ SERVICES, THE MOOVEZ PLATFORM OR THE CONTENTARE OR
          WILL BE ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION. MOOVEZ GROUP
          MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE
          RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE
          SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE
          SERVICES, OR THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD
          PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR
          USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION
          THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
          UNDER APPLICABLE LAW
        </div>
      </div>

      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>EXCLUSION OF INDIRECT DAMAGES.</b> IN NO EVENT WILL MOOVEZ OR ANY
          MEMBER OF THE MOOVEZ GROUP BE LIABLE, WHETHER BASED ON WARRANTY,
          CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER LEGAL
          THEORY, FOR ANY DAMAGES OF ANY KIND AT LAW (INCLUDING, WITHOUT
          LIMITATION, INDIRECT, INCIDENTAL,CONSEQUENTIAL, SPECIAL, EXEMPLARY OR
          PUNITIVE DAMAGES, LOST PROFITS, LOSS OF REVENUE, LOSS OF USE, LOSS OF
          DATA, PERSONAL INJURY, FINES, FEES, PENALTIES OR OTHER LIABILITIES),
          WHETHER OR NOT MOOVEZ IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
          RESULTING FROM OR RELATED TO THE USE OF, OR THE INABILITY TO MAKE USE
          OF, THE MOOVEZ SERVICES, THE MOOVEZ PLATFORM OR THE CONTENT.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          <b>NO RESPONSIBILITY FOR COURIERS.</b> COURIERS OFFERING SERVICES
          THROUGH THE MOOVEZ PLATFORM ARE INDEPENDENT PERSONS OR ORGANIZATIONS
          AND NOT REPRESENTATIVES, AGENTS OR EMPLOYEES OF MOOVEZ. MOOVEZ IS
          THEREFORE NOTLIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS,
          WARRANTIES, CONTRACTUAL BREACHES OR NEGLIGENCE OF ANY VENDORS OR
          COURIERS OR FOR ANY PERSONAL INJURY, DEATH, PROPERTY DAMAGE, OR OTHER
          DAMAGES OR EXPENSES RESULTING THEREFROM AND TAKES NO RESPONSIBILITY
          WHATSOEVER FOR THE PRODUCTS OR SERVICES OFFERED BY COURIERS.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          <b>LIMITATION OF LIABILITY.</b> MOOVEZ GROUP SHALL NOT BE LIABLE FOR
          INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL
          DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR
          PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING
          FROM ANY USE OF THE SERVICES, EVEN IF MOOVEZ GROUP HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. MOOVEZ GROUP SHALL NOT BE LIABLE FOR
          ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR
          RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE
          SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY
          THIRD PARTY PROVIDER, EVEN IF MOOVEZ GROUP HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. MOOVEZ GROUP SHALL NOT BE LIABLE FOR
          DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND MOOVEZ
          GROUP’S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY
          TRANSPORTATION PROVIDERS PROVIDING TRANSPORTATION SERVICES REQUESTED
          THROUGH SOME REQUEST BRANDS MAY OFFER MOVING TRANSPORTATION SERVICES
          AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL
          MOOVEZ GROUP’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES
          FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED 100 HUNDRED
          DOLLARS ($100).
        </div>
      </div>

      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          Additional costs: You assume full and sole responsibility for any
          additional or associated costs that you may incur in connection with
          or as a result of your use of the moovez Platform, including without
          limitation costs relatingto the servicing, repair or adaptation of any
          equipment, software or data that you may own, lease, license or
          otherwise use. THE LIMITATIONS ABOVE REFLECTS A FAIR ALLOCATION OF
          RISK BUT FOR WHICH MOOVEZ WOULD NOT MAKE THE MOOVEZ PLATFORM AND/OR
          MOOVEZ SERVICES AVAILABLE TO USERS. THE LIMITATIONS SPECIFIED IN THIS
          SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN
          THESE TERMS ISFOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </div>
      </div>

      <div className={css?.bullet2}>
        <div className={css?.bullet2Index}>10.</div>
        <div className={css?.bullet2text}>Indemnification</div>
      </div>
      <div className={css?.bullet3Note}>
        You agree to defend, indemnify and hold harmless moovez Group, its
        licensors andtheir directors, officers, agents, contractors, partners,
        representatives and employees from and against any threated or actual
        claims, causes of action, demands, recoveries, losses, liabilities,
        damages, fines, penalties or other costs or expenses of any kind or
        nature including but not limited to reasonable legal fees, incurred in
        connection with or as a result of:
      </div>

      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          your use of the moovez Platform or use of the Services, in any manner
          whatsoever;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          your breach of these Terms of Service or any documents referenced
          herein;
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          your violation of any law or the rights of a third party, including
          third party providers (including, withoutlimitation, intellectual
          property rights);
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          your breach of or failure to perform in respect of any Deliveries made
          by you or by any third party acting on your behalf or with your
          permission; or
          <br />
          moovez reserves the right, at its own expense, to assume the exclusive
          defence and control of any matter otherwise subject to indemnification
          by you hereunder, and you shall cooperate as fully as reasonably
          required by moovez
        </div>
      </div>

      <div className={css?.bullet2} id="DisputeResolution">
        <div className={css?.bullet2Index}>11.</div>
        <div className={css?.bullet2text}>
          Governing Law and Dispute Resolution
        </div>
      </div>

      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>Governing Law.</b> These Terms of Service will be governed by the
          laws of the Province of Alberta, unless you access the moovez Platform
          or receive the moovez Services in Quebec, in which case the laws of
          the Province of Quebec apply to the extent of such access or use of
          the moovez Services, and the federal laws of Canada applicable
          therein. These laws apply to youraccess to or use of the, the moovez
          Platform, the moovez Services or the Content, notwithstanding your
          domicile, residency or physical location. The moovez Platform, the
          moovez Services and the Content are intended for use only in
          jurisdictions where they may lawfully be offered for use.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>Dispute Resolution Process.</b>
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>i.</div>
        <div className={css?.bullet4text}>
          Except to the extent restricted by applicable law, if there is any
          dispute or controversy between (1) you and (2) moovez or any members
          of the moovez Group, including any dispute or controversy arising out
          of or relating to these Terms of Service, the moovez Platform or
          moovez Services, any interactions or transactions between (1) you and
          (2) moovez or any members of the moovez Group, or in respect of any
          legal relationship associatedwith or derived from these Terms of
          Service, including the validity, existence, breach, termination,
          construction or application, or the rights, duties or obligations of
          you or us, (each, a <b>"Dispute"</b>), the disputing person will serve
          notice on the other person and each you and us must use good faith
          efforts to resolve the Dispute informally.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>ii.</div>
        <div className={css?.bullet4text}>
          Except to the extent restricted by applicable law, if the Dispute is
          notresolved after twenty (20) business days of a person serving notice
          on the other party that there is a Dispute, you and us agree that the
          Dispute will be finally resolved by confidential arbitration before a
          single arbitrator in accordance with the Arbitration Rules of the ADR
          Institute of Canada, Inc. The seat of the arbitration will be Ontario
          or wherever convenient or necessary acting reasonably. There will be
          no appeals of any kind. The language of the arbitration will be
          English unless otherwise required by applicable law or agreed to by
          you and us. moovez will pay the reasonable arbitration costs.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>iii.</div>
        <div className={css?.bullet4text}>
          Except to the extent restricted by applicable law, you and us agree
          that you and us will resolve any Dispute on an individual basis. Any
          claim you may have must be brought individually, in your individual
          capacity and not as a representative plaintiff or class member, and
          you will not join such claim with claims of any other person or
          entity, or bring, join or participate in a class action lawsuit,
          collective or representative proceeding of any kind (existing or
          future) against any members of the moovez Group.
        </div>
      </div>
      <div className={css?.bullet4}>
        <div className={css?.bullet4Index}>iv.</div>
        <div className={css?.bullet4text}>
          Nothing in this Section will prohibit us from seeking interim measures
          from a court, including preliminary or injunctive relief of breach of
          youof any intellectual property rights
        </div>
      </div>

      <div className={css?.bullet2} id="Miscellaneous">
        <div className={css?.bullet2Index}>12.</div>
        <div className={css?.bullet2text}>Miscellaneous</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          <b>Interpretation.</b> Unless the context clearly requires otherwise,
          (a) references to the plural include the singular, the singular the
          plural, the partthe whole, (b) references to any gender include all
          genders, (c) “including” has the inclusive meaning frequently
          identified with the phrase “but not limited to” and (d) references to
          “hereunder” or “herein” relate to this Agreement. The section headings
          in this Agreement are for reference and convenience only and shall not
          be considered in the interpretation of these Terms of Service.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          <b>Entire Agreement.</b> If you are a Customer, these Terms of
          Service, the Privacy Policy and any documents incorporated by
          reference herein constitute the entire agreement between moovez and
          you pertaining to the subject matter hereof and supersede all prior or
          contemporaneous communications and proposals, whether electronic, oral
          or written, between you and moovez with respect to the moovez
          Platform. A printed version of these Terms of Service and of any
          notice given in electronic form will be admissible in judicial or
          administrative proceedings based upon or relating to these Terms of
          Service to the same extent and subject to the same conditions as other
          business documents and records originally generated and maintained in
          printed form.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          Notices. moovez may, in its sole discretion, provide any notices to
          you in connection with these Terms of Service through the moovez
          Platform or by email at the then-current email address for you on file
          with moovez. moovez may be contacted in writing at:
          <br />
          <a className={css?.redLink} href="support@wemoovez.com">
            support@wemoovez.com
          </a>
          <br />
          moovez may change its notice contact information from time to time by
          posting updated contact details on the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          <b>No Waiver.</b> moovez's failure to insist upon or enforce strict
          performance of any provision of these Terms of Service will not be
          construed as a waiver of any provisions or right.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          <b>Force Majeure.</b> moovez will not be liable to you for any failure
          of or delay inthe performance of its obligations under these Terms of
          Service for the period that such failure or delay is due to causes
          beyond moovez's reasonable control, including but not limited to acts
          of God, power outages, internet disconnectivity, war, strikes or
          labour disputes, embargoes, government orders or any other force
          majeure event.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>6.</div>
        <div className={css?.bullet3Text}>
          <b>Severability.</b> If any of the provisions contained in these Terms
          of Service are determined to be void, invalid or otherwise
          unenforceable by a court of competent jurisdiction, the provision
          shall be modified by the court and interpreted so as best to
          accomplish the objectives of the original provision to the fullest
          extent permitted by law, and the remaining provisions of this
          Agreement shall remain in effect.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>7.</div>
        <div className={css?.bullet3Text}>
          <b>Delivery of Precedence.</b> If any of the provisions contained in
          these Terms of Service conflict with the terms of another agreement
          between you and us, then these Terms of Service will prevail.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>8.</div>
        <div className={css?.bullet3Text}>
          <b>Language.</b> These Terms of Services are made available in English
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>9.</div>
        <div className={css?.bullet3Text}>
          <b>Assignment.</b> You may not transfer any of your rights or
          obligations under these Terms of Service without our prior written
          consent. We may transfer any of our rights or obligations under these
          Terms of Service without your prior written consent to any of our
          affiliates or any business that we enter intoa joint venture with,
          purchase or are sold to.
        </div>
      </div>
      <div className={css?.bullet1} id="ORDERINGTERMS">
        <div className={css?.bullet1Index}>II.</div>
        <div className={css?.bullet1text}>ORDERING TERMS</div>
      </div>
      <div className={css?.bullet2} id="ServicesandProducts">
        <div className={css?.bullet2Index}>1.</div>
        <div className={css?.bullet2text}>
          Access, moovez Services and Products
        </div>
      </div>
      <div className={css?.bullet3Note}>
        Through the moovez Platform you may place orders for Deliveries from
        Drivers, and moovez may provide customer care support between you and
        Drivers (these services and associated services such as order
        management, payment processingfor Deliveries and provision of access to
        the moovez Platform provided by moovez from time to time are the{" "}
        <b>"moovez Services"</b>). For avoidance of doubt, moovez does not
        provide delivery services directly and no members of the moovez Group
        will be liable for any transactions between Customers, and Drivers.
        moovez may provide services to Drivers through a separate agreement
        executed with such parties.
      </div>
      <div className={css?.bullet2} id="moovezCustomerAccount">
        <div className={css?.bullet2Index}>2.</div>
        <div className={css?.bullet2text}>moovez Customer Account</div>
      </div>
      <div className={css?.bullet3Note}>
        To use the moovez Platform and most features of the moovez Services, you
        are required to successfully create a user account (the{" "}
        <b>"moovez Customer Account"</b>) using the available interfaces at the
        moovez Platform.
      </div>
      <div className={css?.bullet2} id="PlacingDeliveries">
        <div className={css?.bullet2Index}>3.</div>
        <div className={css?.bullet2text}>Placing Deliveries</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          Once you have created your moovez Customer Account you may request a
          delivery from point A to point B, select the load you need for your
          delivery, select the amount of labour you will require loading and
          offloading your items, and provide any delivery instructions. Your
          delivery details may be edited at any time prior to placing a
          Delivery. Once you have made your selection, you may proceed to place
          your Delivery. At this time, you will have the opportunity to review
          your Delivery details. After you review and confirm that the delivery
          details are correct, you may proceed to place your Delivery, provide
          your payment card information, and a Delivery will be created and sent
          to the Driver. You are responsible to ensure that all of your Delivery
          details, including billing, delivery address and other information is
          current, complete and accurate. Your payment card will be charged when
          you place your Delivery if the Delivery is a Card Delivery (as defined
          in section 6(b)) and subject to the payment terms and conditions of
          Section 6 herein. Once you have placed your Delivery, you will be
          charged a fee for cancellation. You will not be entitled to change
          your payment card once a delivery is already in progress and you will
          not be entitled to a full refund (except where required by law).
          Notwithstanding that you are not entitled as of right to change your
          payment card during an ongoing delivery or cancel your Delivery and
          expect a full refund (except where prohibited bylaw), if you wish to
          amend or cancel your Delivery for a total or partial refund of your
          Delivery’s price, you can do so and will be charged accordingto the
          stage at which the Delivery was cancelled. You can email or call the
          moovez support phone line at the contact information page provided on
          the moovez Platform for more information
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          Because of standard banking procedures, once you have placed a Card
          Delivery and your payment has been authorised, your bank or card
          issuer will "hold" the full amount of your Delivery. If your Delivery
          is not confirmed by the Driver or cancelled for any other reason, your
          bank or card issuer will not transfer the funds for the Delivery to
          us, and will instead release the relevant amount back into your
          available balance. However, this may take a period of typically up to
          5 working days (and in some cases up to 30 days, depending on your
          bank or card issuer). You acknowledge and agree that no members of the
          moovez Group nor the relevant Driver will be responsible or liable to
          you in relation to this delay by your bank or card issuer in the
          release of funds back into your account.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          You agree that moovez or the Driver will have no liability to you for
          Deliveriesthat are not confirmed, are cancelled or that the Driver has
          been unable or unwilling to fulfill.
        </div>
      </div>
      <div className={css?.bullet2} id="ThirdPartyDrivers">
        <div className={css?.bullet2Index}>4.</div>
        <div className={css?.bullet2text}>
          Customer Care, Third Party Drivers and Refunds
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          moovez may offer customer care in respect of issues relating to the
          Drivers.If you have any problems, questions or suggestions with
          respect to the moovez Platform or your Delivery, please contact
          moovez’s customer care team (and not the Driver) via email or phone at
          the contact information provided on the moovez Platform.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          moovez may provide, in its discretion, the refund or compensation by
          payment to your payment credit or debit card or providing the Customer
          with moovez Credits (as defined below).
        </div>
      </div>
      <div className={css?.bullet2} id="PaymentProcessingServices">
        <div className={css?.bullet2Index}>5.</div>
        <div className={css?.bullet2text}>Payment Processing Services</div>
      </div>
      <div className={css?.bullet3Note}>
        Payment processing services for the moovez Platform are provided by{" "}
        <b>Stripe, Inc.</b>
        (collectively referred to as the{" "}
        <b>"Payment Processing Services Provider"</b>). You hereby authorize
        moovez to share with the Payment Processing Services Provider that
        financial information, banking details and transaction information
        related to your use of the payment processing services and the moovez
        Platform. You also agree that the moovez Group is not responsible for
        the use or misuse of such financial information, banking details and
        transaction information by the Payment Processing Services Provider or
        any third party.
      </div>
      <div className={css?.bullet2} id="PriceandPayment">
        <div className={css?.bullet2Index}>6.</div>
        <div className={css?.bullet2text}>Price and Payment</div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>1.</div>
        <div className={css?.bullet3Text}>
          Delivery prices listed on the moovez Platform from Vendor in Canada
          are inCanadian dollars, Taxes and delivery charges may be added at
          checkout and will be payable by you along with the Delivery price.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>2.</div>
        <div className={css?.bullet3Text}>
          Payment for Deliveries must be made with an accepted credit or debit
          cardthrough the moovez Platform (<b>"Card Deliveries"</b>). You
          understand that use of the Services may result in charges to you for
          the services or goods you receive from a Third Party Provider (
          <b>“Charges ”</b>). After you have received services or goods obtained
          through your use of the Service, moovez Group will facilitate your
          payment of the applicable Charges on behalf of the Third Party
          Provider as such Third Party Provider’s limited payment collection
          agent. Payment of the Charges in such manner shall be considered the
          same as payment made directly by you to the Third Party Provider.
          Charges will be inclusive of applicable taxes where required by law.
          Charges paid by you are final and non-refundable, unless otherwise
          determined by moovez Group. You retain the right to request lower
          Charges from a Third Party Provider for services or goods received by
          you from such Third Party Provider at the time you receive such
          services or goods. moovez Group will respond accordingly to any
          request from a Third Party Provider to modify the Charges for a
          particular service or good.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>3.</div>
        <div className={css?.bullet3Text}>
          All Charges are due immediately and payment will be facilitated by
          moovez Group using the preferred payment method designated in your
          account, after which moovez Group will send you a receipt by email. If
          your primary Account payment method is determined to be expired,
          invalid or otherwise not able to be charged, you agree that moovez
          Group may, as the Third Party Provider’s limited payment collection
          agent, use a secondary payment method in your Account, if available.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>4.</div>
        <div className={css?.bullet3Text}>
          For Card Deliveries, you will be charged at the time of successful
          completion of your Delivery. The moovez Platform uses the third party
          payment processors to effect charges onto your debit or credit card.
          You consent to the collection and use of your information (including,
          if applicable, personal information) by such payment processing
          service as necessary to process your payments. We reserve the right to
          change, or to stop accepting, any permitted payment method at any time
          in our sole discretion. You agree we may charge your payment card for
          any order placed and for any additional amounts (including any taxes)
          as may be applicable in connection with your purchase. You are
          responsible to ensure that all of your billing information is current,
          complete, and accurate. We will provide you with an online or emailed
          billing summary statement which you may review, save, or print at your
          discretion. This is the only billing statement that will be provided
          by us.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>5.</div>
        <div className={css?.bullet3Text}>
          moovez or the Driver is under no obligation to fulfill your Delivery
          at the incorrect price. Any such differences will not entitle you to a
          refund or a reduction in price of your Delivery.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>6.</div>
        <div className={css?.bullet3Text}>
          Delivery charges and any other fees are subject to change at any time
          at moovez’s discretion.
        </div>
      </div>
      <div className={css?.bullet3}>
        <div className={css?.bullet3Index}>7.</div>
        <div className={css?.bullet3Text}>
          <b>
            If you fail to make any payment when due, without limiting our other
            rights and remedies: (i) Provider may charge interest on the past
            due amount at the rate of twelve percent (12%) per annum or, if
            lower, the highest rate permitted under applicable law; (ii)
            Customer shall reimburse moovez Group for all costs incurred by
            Provider in collecting any late payments or interest, including
            legal fees on a solicitor and own client basis, court costs, and
            collection agency fees.
          </b>
        </div>
      </div>
      <div className={css?.bullet1} id="REFERAFRIENDPROGRAM">
        <div className={css?.bullet1Index}>III.</div>
        <div className={css?.bullet1text}>
          REFER-A-FRIEND PROGRAM, AND MOOVEZ CREDITS
        </div>
      </div>
      <div className={css?.bullet2} id="REFERALPROGRAM">
        <div className={css?.bullet2Index}>1.</div>
        <div className={css?.bullet2text}>Refer-A-Friend Program</div>
      </div>
      <div className={css?.bulletAlphabel}>
        <div className={css?.bulletAlphabelIndex}>(A)</div>
        <div className={css?.bulletAlphabelText}>
          If you are a moovez Customer Account holder, you may be entitled to
          voluntarily participate in the moovez refer a friend program (the{" "}
          <b>"ReferralProgram"</b>). A unique alphanumeric code is assigned to
          your moovez code (<b>"Referral Code"</b>). To participate in the
          Referral Program, you will need to refer friends who have never placed
          an order with us ("Friends") and inviteyour Friends to use the moovez
          Platform with your Referral Code.
        </div>
      </div>
      <div className={css?.bulletAlphabel}>
        <div className={css?.bulletAlphabelIndex}>(B)</div>
        <div className={css?.bulletAlphabelText}>
          You will earn a moovez credit or discount (
          <b>"Referral moovez Credit"</b>) in the amount we determined from time
          to time once your Friend (<b>"Referred User"</b>) has completed an
          eligible order on the moovez Platform (<b>"Eligible Delivery"</b>).
          Criteria for Eligible Deliveries is determined in our sole discretion
          from time to time. Referral Credit will be applied automatically
          against your item subtotal on your next Card Delivery, up to and
          including the item subtotal before taxes, tip, and delivery fee. If
          any credit happens to remain after being applied to the order, that
          credit will remain on your account. Referral Credits are subject to
          expiration and must be used within the timeframe, if stated or
          otherwise determined by us from time to time. The amount of Referral
          Credits earned and redeemed are subject to change at any time at
          moovez’s sole discretion.
        </div>
      </div>
      <div className={css?.bulletAlphabel}>
        <div className={css?.bulletAlphabelIndex}>(C)</div>
        <div className={css?.bulletAlphabelText}>
          The Referral Code may only be used for personal and non-commercial
          purposes. You may share your code with your personal connections via
          social media, email, or word of mouth, where you are the primary
          content owner. In cases where you are not the primary owner of the
          content or website (e.g., Wikipedia, coupon websites, affiliate
          websites, discussion forums, etc.), public distribution is not
          allowed. Promoting or advertising your Referral Code via search engine
          marketing or any other paid advertising media (e.g.,
          Bing/Yahoo/Adwords) is not allowed. You are not allowed to
          misrepresent your relationship with any members of the moovezGroup or
          with the moovez Services to any third parties, and you will not make
          any warranty or representation on behalf of any members of the moovez
          Group. You are not allowed to refer yourself or members of your
          household for the purposes of earning Referral Credit.
        </div>
      </div>
      <div className={css?.bulletAlphabel}>
        <div className={css?.bulletAlphabelIndex}>(D)</div>
        <div className={css?.bulletAlphabelText}>
          moovez reserves the right, at its sole discretion, to deactivate your
          account(s) and revoke the Referral Credits of any account holder who
          it finds or believes to be earning or using Referral Credits against
          these Terms of Service, or who is manipulating or abusing the moovez
          Servicesor the moovez Platform.
        </div>
      </div>
      <div className={css?.bulletAlphabel}>
        <div className={css?.bulletAlphabelIndex}>(E)</div>
        <div className={css?.bulletAlphabelText}>
          For greater certainty, we reserve the right, at any time and in our
          sole discretion, to add to or amend these Terms of Service in relation
          to the use ofReferral Program or to vary or terminate the operation of
          a Referral moovez Credit issued pursuant to the Referral Program at
          any time without notice, including without limitation these Terms of
          Service, Referral moovez Credit, Gift cards, credit or other
          promotions
        </div>
      </div>
    </div>
  );
};

export default Terms;
