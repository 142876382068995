const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getFormattedTime = (time) => {
  const TimeArr = time.split(":");
  if (!TimeArr?.length || TimeArr?.length < 2) return "";
  let timeSymbol = "am";
  if (TimeArr[0] > 11) timeSymbol = "pm";
  return `${TimeArr[0]}:${TimeArr[1]} ${timeSymbol}`;
};

export const formatDate = (initialDate) => {
  const myDate = new Date(initialDate);
  return `${monthNames[myDate.getMonth()]} ${myDate.getDate()}`;
};

export const formatDateAndTime = (initialDateTimeString, separator) => {
  const dateTimeArr = initialDateTimeString?.split(separator);
  let timeString = "";
  if (!dateTimeArr?.length) return "-";
  else if (dateTimeArr?.length === 2)
    timeString = getFormattedTime(dateTimeArr[1]);
  return `${formatDate(dateTimeArr[0])}  ${timeString ? " " + timeString : ""}`;
};
