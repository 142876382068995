import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import ThankYou from "../screens/Booking/ThankYou";

const ThankYouPage = () => {
  return (
    <Layout>
      <ThankYou />
    </Layout>
  );
};

export default ThankYouPage;
