import React from "react";
import css from "../../style/deliveryInfo.module.css";

const S2WhoCanDeliver = () => {
  const items = [
    {
      heading: "Small Items",
      text: "For situations where you’re too busy or don’t have the energy to make a special trip across the city. We have moovers on the road who will gladly pickup and deliver your items. Household goods, boxes, storage bins, packages, parcels, sports equipment, curb-side/in-store pickups, donations, and so on.",
      image: "small_item.png",
    },
    {
      heading: "Large Items",
      text: "These are items that might not fit in your vehicle or you need the muscle to help. We have vehicles that work for all load sizes and can send 1, 2 or even 3 moovers to help with the heavy lifting. Couches, mattresses, other furniture, appliances, construction material, store inventory, and so on.",
      image: "large_item.png",
    },
    {
      heading: "Junk removal",
      text: "Typically junk removal or dump runs will fall under the Mooving category. See delivery guidelines below but if it’s just a few items and a quick load up we will accept the job as a delivery. Additional landfill charges will apply.",
      image: "junk_removal.png",
    },
  ];
  return (
    <div className={css?.s2Md}>
      <h2 className={css?.s2H}>What can we deliver?</h2>
      <div className={css?.s2T}>
        From boxes to furniture, chances are we can handle it for you. Here’s
        what you need to know:
      </div>
      <div className={css?.s2Cards}>
        {items.map((item) => (
          <div className={css?.s2Card}>
            <img src={`/images/${item.image}`} className={css?.s2CardImg} />
            <h4 className={css?.s2CardH}>{item.heading}</h4>
            <p className={css?.s2CardT}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default S2WhoCanDeliver;
