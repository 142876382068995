import React from "react";
import * as animationData from "./loadingAnimation.json";
import Lottie from "react-lottie";
import css from "../../style/booking.module.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const LoadingLottie = ({showPaymentMessage=true}) => {
  return (
    <div className={css?.lodingOverlay}>
      <div className={css?.loadingDiv}>
        <img
          src="/images/moovez_frog.png"
          alt="moovez image"
          className={css?.loadingFrog}
        />
        <Lottie options={defaultOptions} height={60} width={200} />
        <div className={css?.paymentProcessingMessage} style={{display:showPaymentMessage?"block":"none"}}>hang tight, your booking is being processed!</div>
      </div>
    </div>
  );
};

export default LoadingLottie;
