import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/howMovEzWorks.module.css";
import { getFAQs } from "../../store/actions/CommonActions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

const FAQAnswer = ({ answer }) => {
  if (!answer) return <></>;
  return (
    <div style={{}}>
      {answer?.h ? <div style={{ margin: "15px 0" }}>{answer.h}</div> : <></>}
      {answer?.p ? <div style={{ margin: "15px 0" }}>{answer.p}</div> : <></>}
      {Array.isArray(answer?.b) ? (
        <div>
          {answer.b.map((bullet) => (
            <div>
              <GoDotFill
                style={{
                  color: "var(--primary)",
                  margin: "0 10px 4px 20",
                  fontSize: 14,
                }}
              />
              {bullet}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {Array.isArray(answer?.b2) ? (
        <div>
          {answer.b2.map((bullet) => (
            <div style={{ padding: "0 0 0 40px" }}>{bullet}</div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {answer?.i ? (
        <div style={{ margin: "15px 0" }}>
          <i>{answer.i}</i>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
const FAQAccordion = ({ question, answer, expanded, setExpanded, index }) => {
  return (
    <div className={css?.s8FAQM}>
      <Accordion
        className={css?.s8FAQAcc}
        expanded={expanded === index}
        onClick={() => setExpanded(expanded === index ? -1 : index)}
      >
        <AccordionSummary
          expandIcon={<BsChevronDown />}
          collapse
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css?.s8FAQSummary}
        >
          <div className={css.s8AccordionQuestion}>{question}</div>
        </AccordionSummary>
        <AccordionDetails className={css.s8AccordionAnswer}>
          {Array.isArray(answer) ? (
            answer.map((A) => <FAQAnswer answer={A} />)
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Section8 = () => {
  const [FAQs, setFAQs] = useState([
    {
      question: "How do I schedule a moovez job?",
      answer: [
        {
          p: "You can schedule your moovez job through our mobile app or website via moovez.ca.",
        },
        {
          p: "To start, choose your job type. We have four options: Delivery, Moving, Movers and Business. Make sure to read the descriptions & choose the job type that is right for your needs. Next, you’ll be asked to enter your job details. These include: pickup and drop off locations (add a stop if there are multiple pickups), vehicle type, pictures and/or video of what needs to be moved, and finally choose when you'd like us to arrive.",
        },
        {
          p: "Once your job is paired to a moover(s), you will receive a confirmation email or text depending on if your order was placed through the website or mobile app.",
        },
      ],
    },
    {
      question: "When does moovez operate?",
      answer: [
        {
          p: "Our typical hours are Monday - Sunday 8 AM - 9 PM. You can schedule a job outside of these hours but there’s no guarantee any of our moovers will be available to do it. We encourage you to schedule jobs in advance to guarantee a specific day and time.",
        },
      ],
    },
    {
      question: "What can moovez move or deliver?",
      answer: [
        {
          p: "We strive to accommodate a wide range of household and office items, but there are some exceptions and prohibited items. If you're uncertain whether your item or load is suitable for our service, please reach out to us at support@wemoovez.com, and we'll be happy to provide you with more information.",
        },
        { p: "Prohibited items:" },
        {
          p: "Industrial equipment, alcohol, recreational drugs, medications, firearms, and hazardous or illegal items.",
        },
      ],
    },
    {
      question: "How much does a moovez job cost?",
      answer: [
        {
          p: "Our pricing varies depending on the type of job you require. Please find below examples of each type of job we provide:",
        },
        {
          p: "Delivery pricing is determined by three factors: 1) the type of vehicle you select, 2) the number of moovers you need for the job, and 3) the distance between the pickup and drop-off locations. Our system will provide a quote based on these factors, and you will not be charged until the job is completed successfully. Please note that our delivery service is designed for 1-5 items, and loading should take no longer than 15 minutes.",
        },
        {
          p: "Here's an example of a Pickup Truck Delivery, with 1 moover, covering a distance of 15km:",
          b2: [
            "Subtotal: $55.00",
            "Base fare: $9.00",
            "Distance fee (15km): $13.50",
            "Base service fee: $2.50",
            "Labour surcharge: $30.00",
            "Discount: -",
            "GST (5%): $2.75",
            "Total: $57.75",
          ],
        },
        {
          p: "Moving jobs are designed for bigger jobs that will require a longer loading time. We calculate the price based on the work time and a flat rate (30 minutes intra-city) travel time that we add to every job. Please review our vehicle specifications to ensure that your items will fit in the selected vehicle.",
        },
        {
          p: "Here's an example of a 26ft Moving Truck, with 2 moovers, that takes two hours of work time to complete and 30 minutes travel time (added to all intra-city moving jobs):",
          b2: [
            "$120 (hourly rate for 26ft Truck with 2 moovers) x 2.5 (2 hours work time + 30 min travel time) = $300.00 + $15.00 GST (5%) = $315.00.",
          ],
        },
        {
          p: "Movers jobs are also calculated based on work time and a flat rate (30 minutes intra- city) travel time that we add to every job. These jobs are for when you need the muscle, but you don't need the truck. Perhaps you need help moving furniture around the house or unloading a shipping container that got dropped on your driveway.",
        },
        {
          p: "Here's an example of a Movers job, with 2 moovers, that takes 1.5 hours work time to complete and 30 minutes travel time (added to all intra-city movers jobs):",
          b2: [
            "$85 (hourly rate for 2 moovers) x 2 (1.5 hours work time + 30 min travel time) = $170.00 + $8.50 GST (5%) = $178.50.",
          ],
        },
        {
          p: "For Business Delivery, we offer customized solutions based on your delivery needs. Please click on our Business Delivery sign-up form here. Once we receive your submission, one of our Customer Service representatives will be in touch within 2-3 business days.",
        },
      ],
    },
    {
      question:
        "How many items are included in the price of the move and delivery?",
      answer: [
        {
          p: "Our pricing varies depending on the type of job you require. Delivery jobs are designed for 1-5 items, while Moving jobs are limited only by the cargo space of the vehicle. If your items exceed the cargo capacity of the vehicle, multiple trips may be required so make sure you pick a vehicle big enough for your job.",
        },
        {
          p: "Delivery pricing is determined by three factors: 1) the type of vehicle you select, 2) the number of moovers you need for the job, and 3) the distance between the pickup and drop-off locations. Our system will provide a quote based on these factors.",
        },
        {
          p: "Moving and Movers jobs are all calculated based on work time and a flat rate (30 minutes intra-city) travel time that we add to every job.",
        },
        {
          p: "For Business Delivery, we offer a customized solution based on your delivery needs. Please reach out and speak to one of our Customer Service reps for more info.",
        },
      ],
    },
    {
      question: "Will moovez disassemble/assemble my items?",
      answer: [
        {
          p: "Our moovers come equipped with basic tools needed for most assembly work. However, if your items require special tools, please have them prepared for our moovers. Please note that complicated and highly technical disassembly/assembly jobs are outside the scope of our service.",
        },
        {
          p: "If your job requires disassembly or assembly, we recommend submitting it as a Moving job. Delivery jobs are designed for fast, simple transportation and loading should take no longer than 15 minutes. Jobs that require disassembly and/or assembly, with loading time included, typically take more than 15 minutes to complete.",
        },
        {
          p: "We want to ensure that we provide the best service possible, so we ask that you please submit your job accurately so that we can assign the appropriate resources and pricing. If you have any questions or concerns, please don't hesitate to contact our customer service team.",
        },
      ],
    },
    {
      question: "What will the moovers bring?",
      answer: [
        {
          p: "Our moovers come prepared with moving dollies, moving blankets, straps, and other basic equipment to safeguard your items during transportation. However, we don't provide moving boxes or mattress covers. If you require additional packing materials, we suggest preparing those beforehand.",
        },
        {
          p: "Please ensure all of your items are properly boxed and ready for our moover(s) when they arrive to ensure a hassle-free move. If you want to protect your mattress during the move, kindly have a mattress cover available for our movers to use.",
        },
        {
          p: "We are here to assist you with some minor packing needs, but to ensure a smooth and efficient move or delivery process, we recommend that you prepare as much as possible in advance.",
        },
      ],
    },
    {
      question: "What area does moovez cover?",
      answer: [
        {
          p: "Currently, moovez provides moving and delivery services to Calgary and surrounding areas. However, we are continuously expanding our service areas and will soon be available in other Canadian cities and provinces. Stay tuned for updates on our expansion!",
        },
        {
          p: "If you need something moved out of the city or out of province please reach out and we will do our best to accommodate it.",
        },
      ],
    },
    {
      question: "Are there any restrictions?",
      answer: [
        {
          p: "Yes, there are certain restrictions that we have in place for safety and regulatory reasons. We cannot transport hazardous materials, such as flammable liquids, explosives, or chemicals. Additionally, we cannot move machinery, industrial equipment, pianos, live animals and perishable goods. Please let us know if you have any questions about specific items you would like to move, and we will be happy to advise you accordingly.",
        },
      ],
    },
    {
      question: "When will my items arrive?",
      answer: [
        {
          p: "After submitting an order and getting paired with a moover, your items will typically be picked up within 30-45 minutes or at the scheduled pickup time. Once your items are loaded, they will be transported directly to the drop-off location. Delivery and move times depend on various factors such as the size of the job, distance between locations, road conditions, and traffic. We strive to complete each job as efficiently as possible while ensuring the safety and security of your items and our moovers during transportation.",
        },
      ],
    },
    {
      question: "Do I need to be at the pickup location or destination?",
      answer: [
        {
          p: "We require that someone be present at both the pickup and drop-off locations for your move or delivery. You can choose whether to be there yourself or to designate someone else. To add additional contact information, you can do so during your ordering process.",
        },
        {
          p: "If you're scheduling an in-store or curbside pickup, you can simply wait at home while our moovers handle the delivery of your items. Just be sure to attach a photocopy of your receipt when scheduling the delivery or send a copy of it to the moovers through the app when they're on their way to the store.",
        },
        {
          p: "If you're donating or recycling items, you don't need to accompany us to the destination. We'll take care of it for you. However, for donations, we do ask that you confirm with the donation center that they are accepting the items you wish to donate.",
        },
      ],
    },
    {
      question: "Can I have multiple stops in a single job?",
      answer: [
        {
          p: "Yes, you can have multiple stops in a single job. Our platform is designed to handle multiple stops efficiently, and our moovers will be provided with the most optimized routes to make the process as smooth as possible. When scheduling your job, please ensure that you include all necessary stops and provide accurate contact information for each location during the booking process. Our system will generate a quote for the job accordingly, and all payments will be processed seamlessly through the app once the job is completed successfully.",
        },
      ],
    },
    {
      question: "What are the dimensions of the moovez vehicles?",
      answer: [
        {
          p: "Pickup Truck:",
          b: [
            "Cargo space (LxWxH): 6.5ft x 5.0ft x 1.5ft",
            "Max load (lbs): 2,000-2,500",
          ],
        },
        {
          p: "Cargo Van:",
          b: [
            "Cargo space (LxWxH): 9.5ft x 5.5ft x 4.5ft",
            "Max load (lbs): 3,500-4,000",
          ],
        },
        {
          p: "16-20ft Truck:",
          b: [
            "Cargo space (LxWxH): 17.0ft x 7.5ft x 7.0ft",
            "Max load (lbs): 5,500-6,000",
          ],
        },
        {
          p: "26ft Truck:",
          b: [
            "Cargo space (LxWxH): 26.0ft x 8.0ft x 8.5ft",
            "Max load (lbs): 12,500-13,000",
          ],
          i: "Please see our vehicle specifications page for more detailed information.",
        },
      ],
    },
    {
      question: "How do I make changes to my job after placing it?",
      answer: [
        {
          p: "If you need to make changes to your scheduled job, simply log into your account on our app and make the necessary updates. If you encounter any issues while trying to make changes, please don't hesitate to contact our Customer Support team at (780)-243-2186 for assistance.",
        },
        {
          p: "Please note that if your job has already been assigned to a moover and is in-progress, you should immediately reach out to the assigned moover(s) to inform them of any changes or issues. Depending on the stage of the job and whether the moover was en route, there may be a cancellation fee if the job is cancelled. We recommend communicating any changes as early as possible to avoid any potential fees or inconveniences.",
        },
      ],
    },
    {
      question: "How do I pay?",
      answer: [
        {
          p: "You will need to add a payment method to your account before you can book a job with us. Rest assured, you will only be charged upon successful completion of your move or delivery, and there is no need for cash. Our seamless payment process is handled through the app or website, making it easy and convenient for you to pay once the job is done.",
        },
      ],
    },
    {
      question: "When do I pay?",
      answer: [
        {
          p: "After your job is successfully completed, the card associated with your account will be charged automatically. You will then have the option to leave a rating and review for your moovers, and provide an optional tip through the moovez app or website.",
        },
      ],
    },
    {
      question: "Do I get charged a cancellation fee?",
      answer: [
        {
          p: "Cancellation fees may apply depending on the stage of your job in the process. Fees will vary based on factors such as the job type, type of vehicle, and number of moovers booked for the job. If the moover(s) are already in-progress and en route to the pickup location, the cancellation fee will be higher.",
        },
      ],
    },
    {
      question: "How do I sign up for a Business account?",
      answer: [
        {
          p: "Please complete our business onboarding form, which can be found on the homepage of our website or mobile app. This form allows us to learn more about your business and your specific moving or delivery needs. A customer service representative will be in touch within three business days of submitting the form.",
        },
        {
          p: "If you have any additional questions, please don't hesitate to reach out to us at support@wemoovez.com.",
        },
      ],
    },
    {
      question: "Is there insurance coverage on my delivery?",
      answer: [
        {
          p: "All moovez jobs are backed by a $5,000,000 Commercial General Liability Policy. In the unlikely event that anything is lost or damaged during your move or delivery, please contact our Customer Support team at support@wemoovez.com immediately and we'll work to resolve the issue as quickly as possible. Every case is dealt with on a case-by-case basis, and our team will work closely with you to come up with a fair and reasonable solution. If you have any questions or concerns about our insurance coverage, please don't hesitate to reach out to us.",
        },
      ],
    },
  ]);
  // const [FAQsLoading, setFAQsLoading] = useState(true);
  const [expanded, setExpanded] = useState(-1);
  useEffect(() => {
    try {
      // getFAQs(setFAQs, setFAQsLoading);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <div className={css?.s8M}>
      <div className={css?.s8Heading}>Frequently asked questions</div>
      {/* {FAQsLoading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : ( */}
      <div className={css?.s8FAQs}>
        {FAQs?.map((FAQ, index) => (
          <FAQAccordion
            {...FAQ}
            setExpanded={setExpanded}
            expanded={expanded}
            index={index}
          />
        ))}
      </div>
      {/* )} */}
    </div>
  );
};

export default Section8;
