import React from "react";
import css from "../../style/booking.module.css";

const ConfirmDetailsCard = ({ heading, detail, Icon }) => {
  return (
    <div className={css?.confirmDetailsBox}>
      <div className={css?.confirmDetailsHeading}>
        {Icon} {heading}
      </div>
      <div className={css?.confirmDetailsText}>{detail}</div>
    </div>
  );
};

export default ConfirmDetailsCard;
