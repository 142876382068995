import React from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import { BsFillPersonFill } from "react-icons/bs";
import { ImMobile2 } from "react-icons/im";
import { HiPlusCircle } from "react-icons/hi";
import { handleValidatePhoneNumber } from "../../Utilities/general";

const RecipentInfo = ({
  pickUpPersonName,
  pickUpPersonPhone,
  pickUpInstructions,
  stops,
  dropoffLocation,
  dropOffPersonName,
  dropOffPersonPhone,
  dropOffInstructions,
  handleUpdateBookingObject,
  errorObject,
  setErrorObject,
}) => {
  // alphabets
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleReturnText = (e) => {
    let text = e?.target?.value;
    if (!text) return "";
    let textArr = /[a-zA-Z][a-zA-Z ]*/.exec(text);
    return textArr?.length ? textArr.join("") : "";
  };

  return (
    <div className={css?.recipendInfoMainDiv}>
      <div className={css?.TopHeading} style={{ width: "100%" }}>
        Confirm details
      </div>
      <div className={css?.RecipentHeadingText}>
        Contact info for recipient who will be at each location
      </div>
      <div className={css?.recipentInfoCardsDiv}>
        <div className={css?.recipentInfoCardMainDiv}>
          <div className={css?.recipentInfoCardTopLine}>
            <div className={css?.recipentInfoCardBadge}>Pickup</div>
          </div>
          <div className={css?.recipentInfoCardName}>
            <BsFillPersonFill className={css?.recipentInfoCardIcon} />
            <input
              className={
                errorObject.pickUpPersonName
                  ? css?.recipentInfoCardInputError
                  : css?.recipentInfoCardInput
              }
              placeholder="Name (Pickup location)"
              value={pickUpPersonName}
              onChange={(e) => {
                setErrorObject({ ...errorObject, pickUpPersonName: false });
                handleUpdateBookingObject(
                  "pickUpPersonName",
                  handleReturnText(e),
                  "18.1"
                );
              }}
            />
          </div>
          <div className={css?.recipentInfoCardName}>
            <ImMobile2 className={css?.recipentInfoCardIcon} />
            <input
              value={pickUpPersonPhone}
              className={
                errorObject.pickUpPersonPhone
                  ? css?.recipentInfoCardInputError
                  : css?.recipentInfoCardInput
              }
              placeholder="Mobile number"
              onChange={(e) => {
                setErrorObject({ ...errorObject, pickUpPersonPhone: false });
                handleValidatePhoneNumber(e, (num) => {
                  handleUpdateBookingObject("pickUpPersonPhone", num, "18.2");
                });
              }}
            />
          </div>
          <div className={css?.recipentInfoCardInstriction}>
            <HiPlusCircle className={css?.recipentInfoCardIcon} />
            <input
              style={{ backgroundColor: "white" }}
              className={css?.recipentInfoCardInput}
              placeholder="Additional instructions (optional)"
              value={pickUpInstructions}
              onChange={(e) =>
                handleUpdateBookingObject(
                  "pickUpInstructions",
                  e.target.value,
                  "18.3"
                )
              }
            />
          </div>
        </div>
        {stops?.map((stopData, index) => (
          <div className={css?.recipentInfoCardMainDiv}>
            <div className={css?.recipentInfoCardTopLine}>
              <div className={css?.recipentInfoCardBadge}>
                Pickup {index + 2}
              </div>
            </div>
            <div className={css?.recipentInfoCardName}>
              <BsFillPersonFill className={css?.recipentInfoCardIcon} />
              <input
                className={css?.recipentInfoCardInput}
                placeholder={`Name (Pickup ${index + 2} location)`}
                value={stops[index]?.personName}
                onChange={(e) => {
                  try {
                    const stopInfo = stops;
                    stopInfo[index].personName = handleReturnText(e);
                    handleUpdateBookingObject("stops", stopInfo, "18.4");
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
              />
            </div>
            <div className={css?.recipentInfoCardName}>
              <ImMobile2 className={css?.recipentInfoCardIcon} />
              <input
                className={css?.recipentInfoCardInput}
                placeholder="Recipient mobile number"
                value={stops[index]?.personPhone}
                onChange={(e) =>
                  handleValidatePhoneNumber(e, (num) => {
                    const stopInfo = stops;
                    stopInfo[index].personPhone = num;
                    handleUpdateBookingObject("stops", stopInfo, "18.5");
                  })
                }
              />
            </div>
            <div className={css?.recipentInfoCardInstriction}>
              <HiPlusCircle className={css?.recipentInfoCardIcon} />
              <input
                style={{ backgroundColor: "white" }}
                className={css?.recipentInfoCardInput}
                placeholder="Additional instructions (optional)"
                value={stops[index]?.instructions}
                onChange={(e) => {
                  try {
                    const updatedStops = stops;
                    stops[index].instructions = e.target.value;
                    handleUpdateBookingObject("stops", updatedStops, "18.6");
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
              />
            </div>
          </div>
        ))}
        {dropoffLocation && (
          <div className={css?.recipentInfoCardMainDiv}>
            <div className={css?.recipentInfoCardTopLine}>
              <div className={css?.recipentInfoCardBadge}>Drop-off</div>
            </div>
            <div className={css?.recipentInfoCardName}>
              <BsFillPersonFill className={css?.recipentInfoCardIcon} />
              <input
                className={
                  errorObject.dropOffPersonName
                    ? css?.recipentInfoCardInputError
                    : css?.recipentInfoCardInput
                }
                placeholder="Name (Drop-off location)"
                value={dropOffPersonName}
                onChange={(e) => {
                  setErrorObject({ ...errorObject, dropOffPersonName: false });
                  handleUpdateBookingObject(
                    "dropOffPersonName",
                    handleReturnText(e),
                    "18.7"
                  );
                }}
              />
            </div>
            <div className={css?.recipentInfoCardName}>
              <ImMobile2 className={css?.recipentInfoCardIcon} />
              <input
                value={dropOffPersonPhone}
                className={
                  errorObject.dropOffPersonPhone
                    ? css?.recipentInfoCardInputError
                    : css?.recipentInfoCardInput
                }
                placeholder="Mobile number"
                onChange={(e) => {
                  setErrorObject({ ...errorObject, dropOffPersonPhone: false });
                  handleValidatePhoneNumber(e, (num) => {
                    handleUpdateBookingObject(
                      "dropOffPersonPhone",
                      num,
                      "18.8"
                    );
                  });
                }}
              />
            </div>
            <div className={css?.recipentInfoCardInstriction}>
              <HiPlusCircle className={css?.recipentInfoCardIcon} />
              <input
                style={{ backgroundColor: "white" }}
                className={css?.recipentInfoCardInput}
                placeholder="Additional instructions (optional)"
                value={dropOffInstructions}
                onChange={(e) =>
                  handleUpdateBookingObject(
                    "dropOffInstructions",
                    e.target.value,
                    "18.9"
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipentInfo;
