import React from "react";
import css from "../../style/moverInfo.module.css";

const S2Qualities = () => {
  const LIST = [
    {
      title: "simple",
      description:
        "Enter your addresses, select a vehicle & the number of moovers, set a time, and then sit back & let us do the heavy lifting",
    },
    {
      title: "affordable",
      description:
        "Based on the information provided, a price range will be generated. Each vehicle option has a different hourly rate based on the # of moovers chosen.",
    },
    {
      title: "reliable",
      description:
        "All of our moovers are vetted & verified and backed by our multimillion dollar insurance policy",
    },
  ];
  return (
    <div className={css?.S2_Md}>
      {LIST.map((item) => (
        <div className={css?.S2_Card_Md}>
          <img
            className={css?.S2_Img}
            src={`/images/${item.title}.svg`}
            alt={item.title}
          />
          <h3 className={css?.S2_Title}>{item.title}</h3>
          <p className={css?.S2_Description}>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default S2Qualities;
