import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";

const SectionE = () => {
  return (
    <div className={css?.S5MainDIv}>
      <div className={css?.s5Left}>
        <img src="images/box_image.png" alt="" class="img-fluid" />
      </div>
      <div className={css?.s5Right}>
        <div className={css?.S5TopTxt}>What’s not to love</div>
        <div className={css?.S5Heading}>About moovez?</div>
        <div className={css?.S5CardsDiv}>
          <div className={css?.S5Card2}>
            <div className={css?.S5CardImg}>
              <img src="images/a1.svg" alt="" />
            </div>
            <div className={css?.S5CardHeading}>
              Choose the vehicle that fits your delivery needs
            </div>
            <div className={css?.S5CardTxt}>
              Need that new sofa picked up? Are you moving into a new apartment?
              Select the vehicle that is right for your job.
            </div>
          </div>

          <div className={css?.S5Card}>
            <div className={css?.S5CardImg}>
              <img src="images/a2.svg" alt="" />
            </div>
            <div className={css?.S5CardHeading}>
              Someone to help with the heavy lifting
            </div>
            <div className={css?.S5CardTxt}>
              {" "}
              Our strong moover(s) will arrive to load your stuff, secure it
              safely, and place it right where you want it at the final
              destination.
            </div>
          </div>

          <div className={css?.S5Card2}>
            <div className={css?.S5CardImg}>
              <img src="images/a3.svg" alt="" />
            </div>
            <div className={css?.S5CardHeading}>
              Pricing that beats bribing friends
            </div>
            <div className={css?.S5CardTxt}>
              Coordinating busy schedules is a pain. When you finally do find
              friends that can help you move, pizza and beer isn’t cheap.
            </div>
          </div>

          <div className={css?.S5Card}>
            <div className={css?.S5CardImg}>
              <img src="images/a4.svg" alt="" />
            </div>
            <div className={css?.S5CardHeading}>
              Do it all from the comfort of your couch
            </div>
            <div className={css?.S5CardTxt}>
              You can book a job in just a few simple steps. We come to you and
              before you know it, all your items will be safely moved or
              delivered wherever you need them to go.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionE;
