import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import RejectSummary from "../screens/Booking/summaryRejectPage";

const MobileRejectSummary = () => {
  return (
    <Layout>
      <RejectSummary />
    </Layout>
  );
};

export default MobileRejectSummary;
