import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/userProfile.module.css";
import css1 from "../../style/booking.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  postProfileImage,
  getUserProfile,
  postUserProfile,
  deleteProfile,
} from "../../store/actions/userProfileActions";
import { useParams, useNavigate } from "react-router";
import { profileImageURL } from "../../store/constants/baseUrl";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../components/stripe/PaymentForm";
import ModalComponent from "../../components/Modals/Modal.js";
import {
  userPaymentMethods,
  setDefaultPaymentMethod,
  deletePaymentMethod,
} from "../../store/actions/paymentActions";
import { referAFriend } from "../../store/actions/friendReferralActions";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { stripeTestPromise } from "../../store/constants/baseUrl";

const UserProfile = () => {
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonClicked2, setButtonClicked2] = useState(false);
  const [buttonClicked3, setButtonClicked3] = useState(false);
  // state to display errors
  const [error, seterror] = useState("");

  const [loader, setLoader] = useState(false);
  // state to display success message
  const [success, setsuccess] = useState("");
  //bring labels into text
  const [firstNameToggle, setfirstNameToggle] = useState(true);
  const [cardHolderToggle, setcardHolderToggle] = useState(true);
  const [lastNameToggle, setlastNameToggle] = useState(true);
  const [emailToggle, setemailToggle] = useState(true);
  const [contactNoToggle, setcontactNoToggle] = useState(true);
  const [DOBToggle, setDOBToggle] = useState(false);
  const [stripeloader, setstripeLoader] = useState(false);
  const [changesSavedMessage, setChangesSavedMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { email } = useParams();
  const history = useNavigate();
  // userProfileObj
  const [userObj, setuserObj] = useState();
  // paymentMethodsArr
  const [paymentMethodArr, setpaymentMethodArr] = useState();
  const [cardHolderName, setcardHolderName] = useState("");

  // open delete profile modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [addStripe, setAddStripe] = useState(false);

  const handleUpdateProfile = () => {
    try {
      if (!buttonClicked) {
        setButtonClicked(true);
        setDOBToggle(false);
        dispatch(
          postUserProfile(userObj, setChangesSavedMessage, setShowModal)
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleDeleteProfile = () => {
    dispatch(deleteProfile(userProfileInfo?.id, history, seterror));
    setOpenDeleteModal(false);
  };
  //change screen
  const [changeScreen, setchangeScreen] = useState(0);
  // friend email for referrals
  const [referralEmail, setreferralEmail] = useState("");

  const handleReferAFriend = () => {
    try {
      if (referralEmail === userInfo?.email) {
        seterror("You cannot refer yourself!");
        return;
      }
      const res = ValidateEmail(referralEmail);

      if (res) {
        if (!buttonClicked3) {
          setButtonClicked3(true);
          dispatch(
            referAFriend(
              referralEmail,
              userInfo?.id,
              seterror,
              setsuccess,
              setLoader
            )
          );
          setLoader(true);
        }
        // setreferralEmail("");
      } else {
        setreferral(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  //Validating Email

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  //upload user profile image
  const handleMediaFileUpload = (e) => {
    try {
      // setimagesToUpload(e?.target?.files);
      console.log(e?.target?.files);
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append(file?.type, file);

      dispatch(
        postProfileImage(
          userObj?.id,
          userObj?.email,
          formData,
          setChangesSavedMessage,
          setShowModal
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // make a card default
  const makeDefault = (e, cardId) => {
    try {
      if (e?.target?.checked === true) {
        dispatch(setDefaultPaymentMethod(userObj?.id, cardId));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // delete card
  const handleDeleteCard = (cardId) => {
    try {
      console.log("func fired");
      dispatch(deletePaymentMethod(userObj?.id, cardId));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  // call user profile get API
  useEffect(() => {
    try {
      console.log("getting user profile");
      dispatch(getUserProfile(email));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  // dispatch actions
  const dispatch = useDispatch();
  // get loginInfo from reducer
  const { userProfileInfo } = useSelector((state) => state?.userProfile);

  // getting user details from reducer
  const { loginInfo } = useSelector((state) => state.loginReducer);

  const [userInfo, setuserInfo] = useState();
  // getting payment info freom reducer
  const { paymentMethodInfo } = useSelector(
    (state) => state?.paymentMethodReducer
  );
  const [referral, setreferral] = useState(false);
  const [Stripeopen, setStripeOpen] = React.useState(false);

  const handleStripeClose = () => setStripeOpen(false);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setAddStripe(false);

  const handlereferralClose = () => setreferral(false);
  // call user payment methods get API
  useEffect(() => {
    try {
      if (userProfileInfo?.id) {
        dispatch(userPaymentMethods(userProfileInfo?.id));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userProfileInfo]);

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  useEffect(() => {
    try {
      let makeBtnClickable2;

      if (buttonClicked2) {
        makeBtnClickable2 = setTimeout(() => {
          setButtonClicked2(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable2);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked2]);

  useEffect(() => {
    try {
      let makeBtnClickable3;

      if (buttonClicked3) {
        makeBtnClickable3 = setTimeout(() => {
          setButtonClicked3(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable3);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked3]);
  // populate userObj from redux
  useEffect(() => {
    try {
      if (userProfileInfo) {
        console.log("obj set", userProfileInfo);
        setuserObj(userProfileInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userProfileInfo]);

  // function to set userinfo
  useEffect(() => {
    try {
      if (loginInfo) {
        console.log("User is logged in", loginInfo);
        setuserInfo(loginInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [loginInfo]);

  //populate paymentMethodArr from redux
  useEffect(() => {
    try {
      if (paymentMethodInfo) {
        setpaymentMethodArr(paymentMethodInfo);
        console.log("payment method", paymentMethodInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [paymentMethodInfo]);

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className={css1?.profileSettingsMainDiv}>
      <ModalComponent
        text={changesSavedMessage}
        openState={showModal}
        closeFunction={() => setShowModal(false)}
        btnText1={"Okay"}
        btnClickFunc1={() => setShowModal(false)}
      />
      <ModalComponent
        text={
          "Are you sure you want to delete your profile?Your profile will be permanently deleted and you will not be able to recover your account."
        }
        openState={openDeleteModal}
        closeFunction={() => setOpenDeleteModal(false)}
        btnText1={"Yes, I am sure"}
        btnClickFunc1={handleDeleteProfile}
        btnText2={"Cancel"}
        btnClickFunc2={() => setOpenDeleteModal(false)}
      />
      <Modal
        open={referral}
        onClose={handlereferralClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Oops ! Something went wrong. Please check and re-enter the email.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css1?.roundBtn}
              onClick={async () => {
                handlereferralClose();
                setLoader(false);
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>

      {/* //Stripe Token added to backend */}
      <Modal
        open={addStripe}
        onClose={handleStripeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Postal Code and CVC verified.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css1?.roundBtn}
              onClick={async () => {
                handleClose();

                setstripeLoader(false);
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>

      <div className={css?.profileSettingsInnerMain}>
        <div className={css?.sidePannel}>
          <div className={css?.sidePannelHeading}>
            account
            {userObj?.credit && (
              <div className={css?.referralEarningInAccount}>
                <span>Referral Earnings:</span>
                <span>{userObj?.credit}$</span>
              </div>
            )}
          </div>
          <div
            className={css?.sidePannelOptions}
            style={{
              backgroundColor: changeScreen === 0 ? "#f4f4fc" : "",
            }}
            onClick={() => setchangeScreen(0)}
          >
            <span className={css?.sidePannelOptionsIcon}>
              <i class="fas fa-user-alt"></i>
            </span>
            <span className={css?.sidePannelOptionsText}>Profile settings</span>
          </div>
          <div
            className={css?.sidePannelOptions}
            onClick={() => setchangeScreen(1)}
            style={{
              backgroundColor: changeScreen === 1 ? "#f4f4fc" : "",
            }}
          >
            <span className={css?.sidePannelOptionsIcon}>
              <i class="far fa-credit-card"></i>
            </span>
            <span className={css?.sidePannelOptionsText}>Payment Setup</span>
          </div>
          <div
            className={css?.sidePannelOptions}
            onClick={() => setchangeScreen(2)}
            style={{
              backgroundColor: changeScreen === 2 ? "#f4f4fc" : "",
            }}
          >
            <span className={css?.sidePannelOptionsIcon}>
              <i class="fas fa-link"></i>
            </span>
            <span className={css?.sidePannelOptionsText}>Friend Referrals</span>
          </div>
        </div>
        {changeScreen === 0 ? (
          <div className={css?.accountDetails}>
            <div className={css?.accountDetailsHeading}>profile settings</div>
            {/* profile image */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>Profile image</div>
              <div className={css?.accountDetailsBoxInputBox}>
                <span className={css?.accountDetailsBoxInputBoxLabel}>
                  {userObj?.profileDisplayImageUrl && (
                    <img
                      src={`${userObj?.profileDisplayImageUrl}`}
                      alt="profile image"
                      className={css?.userProfileImageImage}
                    />
                  )}
                </span>
                <label
                  for="upload-profile-image"
                  className={css?.uploadImageSpan}
                >
                  Upload document
                </label>
                <input
                  id="upload-profile-image"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleMediaFileUpload(e)}
                  className={css?.uploadImageInput}
                ></input>
              </div>
            </div>
            {/* first name */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>Your first name*</div>
              <div className={css?.accountDetailsBoxInputBox}>
                {firstNameToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {userObj?.firstName}
                  </span>
                ) : (
                  <input
                    value={userObj?.firstName}
                    onChange={(e) =>
                      setuserObj({ ...userObj, firstName: e.target.value })
                    }
                    className={css?.accountDetailsBoxInputBoxInput}
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setfirstNameToggle(!firstNameToggle)}
                >
                  <i class="far fa-edit"></i>
                </span>
              </div>
            </div>
            {/* last name */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>Your last name*</div>
              <div className={css?.accountDetailsBoxInputBox}>
                {lastNameToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {userObj?.lastName}
                  </span>
                ) : (
                  <input
                    value={userObj?.lastName}
                    onChange={(e) =>
                      setuserObj({
                        ...userObj,
                        lastName: e.target.value,
                      })
                    }
                    className={css?.accountDetailsBoxInputBoxInput}
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setlastNameToggle(!lastNameToggle)}
                >
                  <i class="far fa-edit"></i>
                </span>
              </div>
            </div>
            {/* email */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                Contact Email (Not log in email!)*
              </div>
              <div className={css?.accountDetailsBoxInputBox}>
                {emailToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {userObj?.secondaryEmail}
                  </span>
                ) : (
                  <input
                    value={userObj?.secondaryEmail}
                    onChange={(e) =>
                      setuserObj({
                        ...userObj,
                        secondaryEmail: e.target.value,
                      })
                    }
                    className={css?.accountDetailsBoxInputBoxInput}
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setemailToggle(!emailToggle)}
                >
                  <i class="far fa-edit"></i>
                </span>
              </div>
            </div>
            {/* contactNo */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                Your phone number*
              </div>
              <div className={css?.accountDetailsBoxInputBox}>
                {contactNoToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {userObj?.phone}
                  </span>
                ) : (
                  <input
                    value={userObj?.phone}
                    onChange={(e) =>
                      setuserObj({ ...userObj, phone: e.target.value })
                    }
                    className={css?.accountDetailsBoxInputBoxInput}
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setcontactNoToggle(!contactNoToggle)}
                >
                  <i class="far fa-edit"></i>
                </span>
              </div>
            </div>
            {/* Date Of Birth */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>Date of Birth</div>
              <div className={css?.accountDetailsBoxInputBox}>
                {!DOBToggle ? (
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {userObj?.birthDate?.split(" ")[0]}
                  </span>
                ) : (
                  <input
                    type="date"
                    value={userObj?.birthDate?.split(" ")[0]}
                    onChange={(e) =>
                      setuserObj({
                        ...userObj,
                        birthDate: e.target.value,
                      })
                    }
                    className={css?.accountDetailsBoxInputBoxInput}
                  ></input>
                )}
                <span
                  className={css?.accountDetailsBoxInputBoxEditIcon}
                  onClick={() => setDOBToggle(!DOBToggle)}
                >
                  <i class="far fa-edit"></i>
                </span>
              </div>
            </div>

            <button className={css?.submitButton} onClick={handleUpdateProfile}>
              Save
            </button>
            <button
              className={css?.submitButton2}
              onClick={() => setOpenDeleteModal(true)}
            >
              Delete Profile
            </button>
            <h5 style={{ color: "red" }}>{error ? error : ""}</h5>
          </div>
        ) : // )
        changeScreen === 1 ? (
          <div className={css?.accountDetails}>
            <div className={css?.accountDetailsHeading}>PAYMENT METHOD</div>
            <div className={css?.paymentSec}>
              <div className={css?.paymentHeading}>
                Save card for future use
              </div>
              <div className={css?.paymentText}>
                Manage your payment method here for future moovez bookings.
              </div>
              {paymentMethodArr?.length > 0 ? (
                <div className={css?.paymentTable}>
                  <div className={css?.paymentTableHeading}>
                    <div className={css?.paymentTableHeadingCard}>Card</div>
                    <div className={css?.paymentTableHeadingDelete}>Delete</div>
                  </div>
                  {paymentMethodArr?.map((paymentMethod) => (
                    <div className={css?.paymentTableLine}>
                      <div className={css?.paymentTableLineCard}>
                        {paymentMethod?.stripeCardLastFourDigits}
                      </div>
                      <div className={css?.paymentTableLineDefault}>
                        <input
                          className={css?.defaultPaymentCheckbox}
                          type="checkbox"
                          checked={paymentMethod?.isDefault}
                          onChange={(e) =>
                            makeDefault(e, paymentMethod?.stripeCardId)
                          }
                        />
                      </div>
                      <div className={css?.paymentTableLineDelete}>
                        <span
                          className={css?.paymentTableLineDeleteIcon}
                          onClick={() =>
                            handleDeleteCard(paymentMethod?.stripeCardId)
                          }
                        >
                          <i class="fas fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className={css?.cardMainBody}>
              <div className={css?.paymentHeading}>
                {/* Save Card for Future Use */}
              </div>
              <div className={css?.cardMainBodyCred}>
                {/* card holder's name */}
                <div className={css?.accountDetailsBoxInputBox2}>
                  {!cardHolderToggle ? (
                    <span className={css?.accountDetailsBoxInputBoxLabel}>
                      {cardHolderName ? cardHolderName : "Card Holder's Name"}
                    </span>
                  ) : (
                    <input
                      value={cardHolderName}
                      onChange={(e) => setcardHolderName(e.target.value)}
                      className={css?.accountDetailsBoxInputBoxInput}
                      placeholder="Card Holder's Name"
                    ></input>
                  )}
                  <span
                    className={css?.accountDetailsBoxInputBoxEditIcon}
                    onClick={() => setcardHolderToggle(!cardHolderToggle)}
                  >
                    <i class="far fa-edit"></i>
                  </span>
                </div>
                <Elements stripe={stripeTestPromise}>
                  <PaymentForm
                    userId={userProfileInfo?.id}
                    setAddStripe={setAddStripe}
                    setstripeLoader={setstripeLoader}
                    submitWrapper={(cb, e) => {
                      e.preventDefault();
                      if (!buttonClicked2) {
                        setButtonClicked2(true);
                        cb(e);
                      }
                    }}
                  />
                </Elements>
                {stripeloader ? (
                  <div style={{ textAlignLast: "center", paddingTop: "0%" }}>
                    <Box>
                      <CircularProgress color="secondary" />
                    </Box>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={css?.accountDetails}>
            <div className={css?.accountDetailsHeading}>friend referrals</div>
            <div className={css?.friendsReferrals}>
              <div className={css?.friendsReferralsText}>
                {/* An invitation link would be sent to your friend via email.
                  <br /> You and your friend will get $5 wemoovez credit when your
                  friend signs up to wemoovez using that link.
                  <br /> You and your friend can use that credit in your future
                  bookings. */}
                {userObj?.credit && (
                  <p>You have earned ${userObj?.credit} so far.</p>
                )}
                Invite your friends to order through wemoovez, using your unique
                link, and you will each receive a $5 credit when they place
                their first order.
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {success ? success : ""}
                </p>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  {Error ? Error : ""}
                </p>
              </div>
              <div className={css?.friendsReferralsBox}>
                <div className={css?.centerFields}>
                  <input
                    placeholder="Email for referral"
                    value={referralEmail}
                    onChange={(e) => {
                      setreferralEmail(e.target.value);
                      seterror("");
                    }}
                    className={css?.friendsReferralsBoxInput}
                  ></input>
                </div>
                <div style={{ color: "red" }}>{error ? error : ""}</div>
                <div className={css?.centerFields}>
                  <button
                    onClick={handleReferAFriend}
                    className={css?.friendsReferralsBoxBtn}
                  >
                    Refer a friend
                  </button>
                </div>

                {loader ? (
                  <div style={{ textAlignLast: "center", paddingTop: "10%" }}>
                    <Box>
                      <CircularProgress color="secondary" />
                    </Box>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
