import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/loginStyles.module.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/loginActions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Fade from "@material-ui/core/Fade";
import ModalComponent from "../../components/Modals/Modal";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

// import { GoogleLogin, GoogleLogout } from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import AppleLogin from "react-apple-login";

function WemoovezLogin() {
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useNavigate();
  // dispatch actions
  const dispatch = useDispatch();
  // login with fb clicked
  const componentClicked = (data) => {
    console.log("fb login component clicked", data);
  };
  // facebook login
  const responseFacebook = (response) => {
    console.log("login", response);
  };
  //   google login
  const responseGoogle = (response) => {
    if (response.accessToken !== null) {
      alert("Login success");
    } else {
      seterror("Login with google Failed");
      console.log(response);
    }
  };

  //loader
  const [loader, setLoader] = useState(false);
  //getting login error

  const [loginErr, setloginErr] = useState(false);
  // getting error
  const { loginError } = useSelector((state) => state.loginReducer);
  // email
  const [email, setemail] = useState("");
  // password
  const [password, setpassword] = useState("");
  // show password toggle
  const [showPassword, setShowPassword] = useState("password");
  // terms and conditions
  const [TnC, setTnC] = useState(false);
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // Sign In
  const handleLogin = () => {
    try {
      if (email && password) {
        setLoader(true);
        const loginData = {
          email: email,
          password: password,
        };
        // console.log("login data from signup component", loginData);
        dispatch(login(loginData, seterror, history, setLoader));
      } else {
        setModalFields({
          ...modalFields,
          open: true,
          text: "Please fill all the fields!",
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  const toggleShowPassword = () => {
    if (showPassword === "text") setShowPassword("password");
    else setShowPassword("text");
  };
  return (
    <div className={css?.loginMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.loginForm}>
        <img
          src="/images/Moovez-Dark.svg"
          alt=""
          className={css?.signupImage}
        />
        <div className={css?.posterMainHeading} style={{ fontWeight: "700" }}>
          Welcome back to moovez!
        </div>
        <div
          className={css?.posterMainHeading}
          style={{ fontSize: 20, fontWeight: "500" }}
        >
          Let’s get your stuff moved.
        </div>
        <input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className={css?.fullInput}
        ></input>
        <div className={css?.fullInput} style={{ display: "flex" }}>
          <input
            value={password}
            type={showPassword}
            placeholder="Password"
            className={css?.passwordInput}
            onChange={(e) => setpassword(e?.target?.value)}
          ></input>
          {showPassword === "password" ? (
            <BsFillEyeFill
              className={css?.passwordEye}
              onClick={() => toggleShowPassword()}
            />
          ) : (
            <BsFillEyeSlashFill
              className={css?.passwordEye}
              onClick={() => toggleShowPassword()}
            />
          )}
        </div>
        {loginErr ? (
          <div className={css?.signinRedirectError}>
            <Fade in={loginErr === true} timeout={1000}>
              <p>Login failed, please try again.</p>
            </Fade>
          </div>
        ) : (
          <div></div>
        )}
        {/* handleLogin */}
        <div className={css?.TnCMainDiv}>
          <input
            type="checkbox"
            checked={TnC}
            onChange={(e) => setTnC(e.target.checked)}
            className={css?.TnCCheckbox}
          ></input>
          Keep me signed in
        </div>
        {loader ? (
          <div>
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="secondary" />
            </Box>
          </div>
        ) : (
          <div></div>
        )}
        <div className={css?.signupBtnMainDiv}>
          <button
            className={css?.signupBtn}
            style={{ marginRight: "10px" }}
            onClick={handleLogin}
          >
            Log in
          </button>
          <div
            className={css?.signinTxt}
            onClick={() => history("/forgotPassword")}
          >
            Forgot password?{" "}
          </div>
        </div>

        <div className={css?.signinRedirect}>
          Don't have an account?{" "}
          <span
            className={css?.signinTxt}
            style={{ fontWeight: 500 }}
            onClick={() => history("/Signup")}
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
}

export default WemoovezLogin;
