import css from "../../style/booking.module.css";

const BreakdownOfFare = ({ bookingTotalModel }) => {
  const handleReturnVal = (val, symbol) => {
    return val ? symbol + val : "-";
  };
  return (
    <div>
      <div className={css?.costBreakdownTopText}>Price Breakdown</div>
      <div className={css?.costBreakdownHeading}>Pricing Estimate</div>
      <div className={css?.priceDiv}>
        <div className={css?.priceDivTop}>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Hourly rate</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.hourlyRate, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>
              Est. work time (hrs)
            </span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.workTime, "")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>
              Travel time (hrs)
            </span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.travelTime, "")}
            </span>
          </div>
          <div
            className={css?.priceBreakdownLine}
            style={{ fontWeight: "700" }}
          >
            <span className={css?.priceBreakdownLeftWord}>Est. subtotal</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalChargeBTax, "$")}
            </span>
          </div>
        </div>

        <div className={css?.priceDivMid}>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Service Fee</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalServiceFee, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>
              GST ( {bookingTotalModel?.totalTaxGstpstrate}% )
            </span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.gstandPst, "$")}
            </span>
          </div>
          <div className={css?.priceBreakdownLine}>
            <span className={css?.priceBreakdownLeftWord}>Discount</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.discount, "")}
            </span>
          </div>
        </div>
        <div className={css?.priceDivBottom}>
          <div
            className={css?.priceBreakdownLine}
            style={{ fontStyle: "bold", fontSize: "20px" }}
          >
            <span className={css?.priceBreakdownLeftWord}>Est. total:</span>
            <span className={css?.priceBreakdownRightWord}>
              {handleReturnVal(bookingTotalModel?.totalCharge, "$")}
            </span>
          </div>
        </div>
      </div>
      <div className={css?.estimationNote}>
        This estimate is based on the information provided. The final total will
        be calculated based on the total work-time needed to complete the job.
      </div>
    </div>
  );
};

export default BreakdownOfFare;
