import React from "react";
import css from "../../style/BecomeAMoover.module.css";

const Section3 = () => {
  return (
    <div className={css?.S3MainDiv}>
      <div className={css?.S3Heading}>Don’t have a vehicle?</div>
      <div className={css?.S3Text}>
        No problem. Sign up with “no vehicle” and use your muscle to become a
        helper or receive jobs that don’t require a vehicle. Some jobs just
        require help with the heavy lifting and not the transporting.
      </div>
      <img className={css?.S3Image} src="/images/dont_have.png" alt="muscle" />
    </div>
  );
};

export default Section3;
