import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import css from "../../style/footer.module.css";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const NewFooter = () => {
  const [height, width] = useWindowSize();
  // history for routing
  const history = useNavigate();
  return (
    <div className={css?.footerMainDiv}>
      <div className={css?.headerFourDivs}>
        <div className={css?.footerInfo}>
          <img src="images/logo.svg" alt="" className="img-fluid" />
          <p>We help you move your things.</p>
          <div className={css?.socialLinksDiv}>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/wemoovez")
              }
            >
              <i class="fab fa-facebook-f"></i>
            </button>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href = "https://www.instagram.com/wemoovez/")
              }
            >
              <i class="fab fa-instagram"></i>
            </button>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href =
                  "https://www.linkedin.com/company/moovez/")
              }
            >
              <i class="fab fa-linkedin-in"></i>
            </button>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href = "https://twitter.com/wemoovez")
              }
            >
              <i class="fab fa-twitter"></i>
            </button>
          </div>
        </div>
        {width > 778 ? (
          <>
            <div className={css?.footerCenterDiv}>
              <div className={css?.centerHeading}>Company info</div>
              <div
                className={css?.centerData}
                onClick={() => history("/howItWorks")}
              >
                How it works
              </div>
              <div className={css?.centerData} onClick={() => history("/help")}>
                How can we help
              </div>
              {/* <div
                className={css?.centerData}
                onClick={() => history( "/Blog")}
              >
                Blog
              </div> */}
              <div
                className={css?.centerData}
                onClick={() => history("/ContactUs")}
              >
                Contact us
              </div>
              <div className={css?.centerData} onClick={() => history("/FAQs")}>
                FAQ
              </div>
            </div>
            <div className={css?.footerCenterDiv}>
              <div className={css?.centerHeading}>Quick links</div>
              <div
                className={css?.centerData}
                onClick={() => history("/Login")}
              >
                Log in
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/Signup")}
              >
                Sign up
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/OurStory")}
              >
                Our story
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/Earnings")}
              >
                Earnings
              </div>
              <div className={css?.centerData} onClick={() => history("/blog")}>
                Blogs
              </div>
            </div>
          </>
        ) : (
          <>
            <details className={css?.centerDetails}>
              <summary className={css?.centerDetailsHeading}>
                Company Info
              </summary>
              <div
                className={css?.centerData}
                onClick={() => history("/howItWorks")}
              >
                How it works
              </div>
              <div className={css?.centerData} onClick={() => history("/help")}>
                How can we help
              </div>
              {/* <div
                className={css?.centerData}
                onClick={() => history( "/Blog")}
              >
                Blog
              </div> */}
              <div
                className={css?.centerData}
                onClick={() => history("/ContactUs")}
              >
                Contact Us
              </div>
              <div className={css?.centerData} onClick={() => history("/FAQs")}>
                FAQ
              </div>
            </details>

            <details className={css?.centerDetails}>
              <summary className={css?.centerDetailsHeading}>
                Quick Links
              </summary>
              <div
                className={css?.centerData}
                onClick={() => history("/Login")}
              >
                Log in
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/Signup")}
              >
                Sign up
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/OurStory")}
              >
                Our story
              </div>
              <div
                className={css?.centerData}
                onClick={() => history("/Earnings")}
              >
                Earnings
              </div>
              <div className={css?.centerData} onClick={() => history("/Blog")}>
                Blogs
              </div>
            </details>
          </>
        )}

        <div className={css?.buttonsDiv}>
          <div
            className={css?.greenbtn}
            onClick={() =>
              (window.location.href =
                "https://moversignup.moovez.ca/Driver/MooverSignUp")
            }
          >
            Become a moover
          </div>
          <div className={css?.greenbtn} onClick={() => history("/")}>
            Schedule a pickup
          </div>
        </div>
      </div>
      <div className={css?.copyrightDiv}>
        <p>
          Copyright © moovez.{" "}
          <button
            className={css?.bottomLine}
            onClick={() => history("/privacy")}
          >
            Read our Privacy Policy
          </button>{" "}
        </p>
      </div>
      {/* <!--END OF COPY RIGHT--> */}
    </div>
  );
};

export default NewFooter;
