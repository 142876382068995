import {
  GET_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_REQUEST,
} from "../constants/paymentMethodConstants";

// used for showing user payment method
export const paymentMethodReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        paymentMethodInfo: null,
        paymentMethodLoading: true,
      };
    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethodLoading: false,
        paymentMethodInfo: payload,
        paymentMethodError: "",
      };
    case GET_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        paymentMethodLoading: false,
        paymentMethodError: payload,
      };
    default:
      return state;
  }
};
