import React from "react";
import SectionB from "../../components/HomeComponents/SectionB";
import SectionC from "../../components/HomeComponents/SectionC";
import SectionD from "../../components/HomeComponents/SectionD";
import SectionE from "../../components/HomeComponents/SectionE";
import SectionF from "../../components/HomeComponents/SectionF";
import SectionA from "../../components/HomeComponents/Tabs";
import css from "../../style/Home.module.css";

function Index() {
  return (
    <div className={css?.homeMainDiv}>
      <div className={css?.S1MainDiv}>
        <SectionA />
        <div className={css?.S1ImgDiv}>
          <img
            src="images/banner.jpg"
            alt="Banner"
            className={css?.S1BackgroundImage}
          />
        </div>
      </div>
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <SectionF />
    </div>
  );
}

export default Index;
