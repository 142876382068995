import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import NewHeader from "../components/Headers/NewHeader";
import NewFooter from "../components/Footers/NewFooter";
import { populateUser } from "../store/actions/loginActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import css from ".././style/bookingIndexPage.module.css";
import { clearBookingsFromLocalStorage } from "../Utilities/general";

const Layout = ({ children, bg }) => {
  // dispatch actions
  const dispatch = useDispatch();
  // history for routing
  const history = useNavigate();
  // get object from the local storage and populate values
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      const tempUser = JSON.parse(localStorage.getItem("wemoovezUserProfile"));
      // const tempUser = "";
      if (tempUser) {
        console.log(tempUser);
        dispatch(populateUser(tempUser, history));
      }
      window.addEventListener("beforeunload", clearBookingsFromLocalStorage());
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        margin: 0,
        backgroundColor: bg ? bg : "#F6F6FD",
      }}
      className={css?.ScrollBar}
    >
      <NewHeader />
      {children}
      <NewFooter />
    </div>
  );
};

export default Layout;
