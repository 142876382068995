import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useSelector, useDispatch } from "react-redux";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import css from "../../style/vehicleSpecs.module.css";
import { vehicleSpecsImageURL } from "../../store/constants/baseUrl";
import { useParams } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";

const BulletPoints = (text, title) => {
  return (
    <div className={css?.BulletMainDiv}>
      <FaCircle className={css?.bulletCircle} />
      <span className={css?.bulletText}>{title + text}</span>
    </div>
  );
};
const VehicleSpecs = () => {
  // get vehicle id from params
  const { vehicleId } = useParams();
  // hold current vehicle specs
  const [vehicleList, setvehicleList] = useState([]);
  const [currVehicle, setcurrVehicle] = useState();
  // dispatch redux actions
  const dispatch = useDispatch();
  // getting single vehicle info
  useEffect(() => {
    try {
      if (vehicleId) dispatch(getAllVehicles(vehicleId));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleId]);
  // getting vehicle info
  const { vehicleTypesInfo, vehicleTypesLoading } = useSelector(
    (state) => state.vehicleTypesReducer
  );
  // scroll to the top
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      console.log("current vehicle updated", vehicleList);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleList]);
  // populate current vehicle
  useEffect(() => {
    try {
      if (Array.isArray(vehicleTypesInfo)) {
        setvehicleList(vehicleTypesInfo);
        const index = vehicleTypesInfo.findIndex(
          (vehicle) => vehicle.id == vehicleId
        );
        setcurrVehicle(vehicleTypesInfo[index > -1 ? index : 0]);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleTypesInfo]);
  return (
    <div className={css?.vehicleSpecsMainDiv}>
      {vehicleTypesLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className={css?.lMd}>
            <div className={css?.lH}>Vehicle details</div>
            {vehicleList.map((vehicle, index) => (
              <div
                className={css?.lItem}
                key={vehicle.id}
                style={{
                  background:
                    currVehicle.id === vehicle.id ? "#EEECFA" : "#ffffff",
                }}
                onClick={() => setcurrVehicle(vehicleList[index])}
              >
                {vehicle.title}
              </div>
            ))}
          </div>
          {currVehicle && (
            <div className={css?.rMd}>
              <div className={css?.rImgMd}>
                <img
                  src={`${vehicleSpecsImageURL}${currVehicle.pictureUrl}`}
                  alt={vehicleList?.title + " image"}
                  className={css?.rImg}
                />
              </div>
              <div className={css?.rContent}>
                <div className={css?.rLContent}>
                  <div className={css?.contentHeading}>
                    {currVehicle.title} Info:
                  </div>
                  {BulletPoints(
                    ` ${currVehicle.length}ft x ${currVehicle.width}ft x ${currVehicle.height}ft`,
                    "Cargo space (LxWxH):"
                  )}
                  {currVehicle?.cargoSpace?.maxLoad
                    ? BulletPoints(
                        currVehicle.cargoSpace.maxLoad,
                        "Max load (lbs) "
                      )
                    : ""}
                </div>
                <div className={css?.rRContent}>
                  {currVehicle?.recommendedUse?.length ? (
                    <>
                      <div className={css?.contentHeading}>Recommended use</div>
                      {currVehicle.recommendedUse.map((use) =>
                        BulletPoints(use?.useLine, "")
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className={css?.vehicleSpecsDisclaimer}>
                  <i>
                    All measurements are approximate. Cargo space varies based
                    on make and model of vehicle. We categorize our vehicles
                    based on industry standard dimensions but these may vary
                    slightly according to the vehicle that gets matched to your
                    order.
                  </i>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VehicleSpecs;
