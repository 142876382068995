import React from "react";
import Layout from "../layouts/index";
import HelpScreen from "../screens/Info/Help";

const Help = () => {
  return (
    <Layout>
      <HelpScreen />
    </Layout>
  );
};

export default Help;
