import React from "react";
import css from "../../style/BecomeAMoover.module.css";

const Section1 = () => {
  return (
    <div className={css?.s1M}>
      <div className={css?.s1L}>
        <div className={css?.s1LHeading}>
          Become a moover and start earning.
        </div>
        <div className={css?.s1LText}>
          Earn money helping people move their stuff.
        </div>
        <div
          className={css?.s1LBtn}
          onClick={() =>
            (window.location.href =
              "https://moversignup.moovez.ca/Driver/MooverSignUp")
          }
        >
          Sign up now
        </div>
      </div>
      <div className={css?.s1R}>
        <img
          className={css?.s1RImage}
          src="/images/become_mover_banner.png"
          alt="moover"
          width="100%"
        />
      </div>
    </div>
  );
};

export default Section1;
