import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import { useNavigate } from "react-router";

const SectionD = () => {
  // history for routing
  const history = useNavigate();
  return (
    <div className={css?.S4MainDiv}>
      <div className={css?.S4Right}>
        <div className={css?.S4LeftHeading}>
          Quit bribing your friends with &#127829; and &#127866; to help you
          move.
        </div>
        <div className={css?.S4Text}>
          We provide scheduled pickups or on-demand (same-day) moving & delivery
          support by pairing your job to our closest available moover(s). The
          days of renting a truck and breaking your back to lift heavy furniture
          are over.
        </div>
        <button onClick={() => history("/Signup")} className={css?.roundBtn3}>
          Sign up now
        </button>
      </div>
      <div className={css?.S4Left}>
        <img src="images/friend.png" alt="" style={{ width: "85%" }} />
      </div>
    </div>
  );
};

export default SectionD;
