import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const CustomTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    placement={placement ?? "top"}
    enterTouchDelay={1}
    classes={{ popper: className }}
    leaveTouchDelay={10000}
    leaveDelay={100}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--primary)",
    padding: "0px",
    borderRadius: "15px",
  },
}));
