import React from "react";
import css from "../../style/deliveryInfo.module.css";

const S4DeliveryGuidelines = () => {
  const items = [
    {
      image: "number_mover.svg",
      text: "Choose the number of moovers required for the job. All jobs have at least 1 moover. Note: Large items like couches and mattresses require 2 PEOPLE to move. If you submit a large item job with 1 moover, this means our moover is expecting someone to help to load & unload the vehicle.",
    },
    {
      image: "baggage.svg",
      text: "Delivery jobs are quick and should take approximately 15 minutes to load & unload your items. 1-3 items is the max number of items. Anything above that, please submit a Mooving job. Our Mooving category is designed for multiple item jobs with longer loading/unloading time.",
    },
  ];
  return (
    <div className={css?.s4Md}>
      <h2 className={css?.s4H}>Delivery guidelines</h2>
      <p className={css?.s4T}>
        For a successful delivery, make sure your load meets these requirements
      </p>
      <div className={css?.s4Cards}>
        {items.map((item) => (
          <div className={css?.s4Card}>
            <img src={`images/${item.image}`} className={css?.s4CardImg} />
            <p className={css?.s4CardText}>{item.text}</p>
          </div>
        ))}
      </div>
      <div className={css?.s4Btn}>View Space</div>
    </div>
  );
};

export default S4DeliveryGuidelines;
