import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
} from "../constants/LoginConstants";
import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const referAFriend =
  (email, id, setError, setSuccess, setLoader) => async (dispatch) => {
    try {
      console.log("firend referral Action", email, id);
      const { data } = await axios.get(
        `${baseUrl}FriendReferralsAPI/${id}?email=${email}`
      );
      console.log("friend referral API response", data);
      setSuccess(`Referral email has been sent to ${email}`);
      setLoader(false);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setError("Something went wrong! Please try again later...");
      setLoader(false);
    }
  };

// friend referral signup action
// this action is called in Registerwemoovez/:code page
// it redirects to confirm email page
export const referralSignup =
  (loginData, referralCode, setError, history) => async (dispatch) => {
    console.log("signup cred", loginData);
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}Auth/signup/?referralCode=${referralCode}`,
        // config,
        loginData
      );
      console.log("signup data from api", data);
      history(`/confirmEmail/${data?.email}`);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      dispatch({
        type: LOGIN_FAIL,
        payload: "Signup Failed,Please try again!",
      });
      setError("Signup Failed,Please try again!");
    }
  };
