import React from "react";
import css from "../../style/blogs.module.css";

const socialLinks = [
  { img: "fb", link: "https://www.facebook.com/wemoovez" },
  { img: "ig", link: "https://www.instagram.com/wemoovez/" },
  { img: "linkedIn", link: "https://www.linkedin.com/company/moovez/" },
  { img: "twitter", link: "https://twitter.com/wemoovez" },
];

const BlogItemRenderer = ({ item }) => {
  switch (item.type) {
    case "heading":
      return <div className={css?.bd_c_heading}>{item.data}</div>;
    case "paragraph":
      return <div className={css?.bd_c_paragraph}>{item.data}</div>;
    case "bullet":
      return (
        <div className={css?.bd_c_bullet_mg}>
          <span className={css?.bd_c_bullet_dot}></span>
          <div className={css?.bd_c_bullet_text_area}>
            <span className={css?.bd_c_bullet_heading}>{item.heading}</span>
            {item.data}
          </div>
        </div>
      );
    case "image":
      return (
        <div className={css?.bd_c_img_mg}>
          <img className={css?.bd_c_img} src={`/images/${item.data}`} />
        </div>
      );
    case "line":
      return <div className={css?.bd_c_blank}></div>;
    default:
      return <></>;
  }
};

const BlogList = ({ blog }) => {
  return blog ? (
    <div className={css?.bd_mg}>
      <div className={css?.bd_desc}>{blog.description}</div>
      <div className={css?.bd_info}>
        <div className={css?.bd_info_r}>
          <img
            className={css?.bd_logo}
            src="/images/moovez_blog_logo.svg"
            alt="Moovez logo"
          />
          <div className={css?.bd_date}>{blog.date}</div>
        </div>
        <div className={css?.bd_info_l}>
          {socialLinks.map((item) => (
            <img
              className={css?.bd_social}
              src={`/images/${item.img}_blue.svg`}
              alt={item.img}
              onClick={() => window.open(item.link, "_blank")}
            />
          ))}
        </div>
      </div>
      <div className={css?.bd_main_img_mg}>
        <img
          className={css?.bd_main_img}
          src={`/images/${blog.image}`}
          alt={blog.title}
        />
      </div>
      {blog.content?.map((item) => (
        <BlogItemRenderer item={item} />
      ))}
    </div>
  ) : (
    <></>
  );
};

export default BlogList;
