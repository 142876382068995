import React from "react";
import * as Sentry from "@sentry/react";

function MUVPress() {
  return (
    <div>
      <div id="blog" className="muv_team_pages">
        <div id="press">
          <div className="banner_section inner_page_banner press_banner">
            <div className="inner_content main_section">
              <div className="full_width_res aligned_center">
                <div className="block_display">
                  <h1
                    style={{ fontSize: 37, letterSpacing: 10 }}
                    className="center_text color-var(--text)-white"
                  >
                    Press
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="inner_content">
            <div className="full_width_res aligned_center">
              <div className="block_display">
                <h1>
                  <br />
                  <span
                    className="color-dark-purp"
                    style={{
                      fontSize: 18,
                      fontWeight: 300,
                      lineHeight: 2.5,
                      textAlign: "center",
                    }}
                  >
                    Our press team is always on the lookout for compelling
                    collaborations, unique opportunites and community outreach.
                    Please don't hesitate to reach out if you'd like to chat or
                    work together.
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                </h1>
              </div>
            </div>
          </div>

          <div className="inner_content">
            <div className="press">
              <div className="full_width_res press_inner one_half">
                <a
                // href="/Home/MUVPressOpen2020"
                >
                  <div className="side_by_side_section_2_2_image">
                    <img
                      src="../../Content/themes/images/site/Press/Open2020/Assets/Thumbnail.png"
                      alt="Your Best Value Proposition"
                      title="Your Best Value Proposition"
                    />
                  </div>

                  <div className="side_by_side_section_2_2_text">
                    <span className="date_text">December 22, 2020</span>
                    <h2>Open for Business!</h2>
                  </div>
                </a>
              </div>

              <div className="cl"></div>
            </div>
            <div className="sign_up_rider_or_driver" style={{ marginTop: 5 }}>
              <div className="full_width_res sign_up_right">
                <img
                  src="../../Scripts/muv_team/img/compressed-banner/sign_up_to_be_a_rider_or_a_driver.svg"
                  alt="Sign up to be a rider or a driver."
                  title="Sign up to be a rider or a driver."
                />
              </div>
              <div className="full_width_res sign_up_left">
                <span style={{ fontSize: 16 }}>
                  Get in touch
                  <br />
                  <br />
                  <br />{" "}
                </span>
                <h2>
                  Contact us for press inquiries at{" "}
                  <a
                    href="mailto:press@wemoovez.com"
                    style={{ fontSize: 25, color: "#F6BF4F" }}
                  >
                    press@wemoovez.com
                  </a>
                </h2>
              </div>
              <div className="cl"></div>
            </div>
          </div>
          <div className="cl"></div>
          <div className="cl"></div>
        </div>
      </div>
    </div>
  );
}

export default MUVPress;
