import React from "react";
// importing the css
import css from "../../style/booking.module.css";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RoundButton from "../Booking/RoundButton";
import CircularProgress from "@mui/material/CircularProgress";

//Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 4,
  color: "var(--text)",
};

const ModalComponent = ({
  text,
  openState,
  closeFunction,
  btnText1,
  btnClickFunc1,
  btnText2,
  btnClickFunc2,
  loader,
}) => {
  return (
    <Modal
      open={openState}
      sx={{ border: "none" }}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {text}
        </Typography>
        <div style={{ marginLeft: "12%" }}>
          {btnText1 && (
            <div className={css?.roundBtn} onClick={btnClickFunc1}>
              {btnText1}
            </div>
          )}
          {btnText2 && (
            <RoundButton text={btnText2} clickFunction={btnClickFunc2} />
          )}
          {loader && (
            <div style={{ textAlignLast: "center", paddingTop: "10%" }}>
              <Box>
                <CircularProgress color="secondary" />
              </Box>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
