import React from "react";
import Layout from "../layouts/index";
import Signup from "../screens/Account/Signup";

const SignupPage = () => {
  return (
    <Layout>
      <Signup />
    </Layout>
  );
};

export default SignupPage;
