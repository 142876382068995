import React from "react";
import S1Top from "../../components/MoverInfo/S1Top";
import S2Qualities from "../../components/MoverInfo/S2Qualities";
import S3Testimonials from "../../components/MoverInfo/S3Testimonials";
import S4HowItWorks from "../../components/MoverInfo/S4HowItWorks";
import S5AllNeeds from "../../components/MoverInfo/S5AllNeeds";

const MoverInfo = () => {
  return (
    <div>
      <S1Top />
      <S2Qualities />
      <S3Testimonials />
      <S4HowItWorks />
      <S5AllNeeds />
    </div>
  );
};

export default MoverInfo;
