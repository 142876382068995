import React from "react";
import Layout from "../layouts/index";
import ReferralSignup from "../screens/Account/ReferralSignup";

const ReferralSignupPage = () => {
  return (
    <Layout>
      <ReferralSignup />
    </Layout>
  );
};

export default ReferralSignupPage;
