import React from "react";
import * as Sentry from "@sentry/react";
import SignupBusiness from "../screens/Account/SignupBusiness";
import Layout from "../layouts/index";
const MobileSignupBusinessPage = () => (
  <Layout>
    <SignupBusiness />
  </Layout>
);
export default MobileSignupBusinessPage;
