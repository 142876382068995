import React from "react";
import css from "../../style/businessInfo.module.css";
// Importing the UI components
import S1Form from "../../components/BusinessInfo/S1Form";
import S2Features from "../../components/BusinessInfo/S2Features";
import S3HowItWorks from "../../components/BusinessInfo/S3HowItWorks";
import S4Industries from "../../components/BusinessInfo/S4Industries";

const BusinessDeliveryInfo = () => {
  return (
    <div>
      <S1Form />
      <S2Features />
      <S3HowItWorks />
      <S4Industries />
    </div>
  );
};

export default BusinessDeliveryInfo;
