import React from "react";
import Layout from "../layouts/index";
import Home from "../screens/Info/Home";

const home = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default home;
