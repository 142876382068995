import {
  GET_TRIP_FAIL,
  GET_TRIP_SUCCESS,
  GET_TRIP_REQUEST,
} from "../constants/myTripConstants";
import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

// my trips action
// this action is called in my trips page
// it shows all trips of a customer
export const getMyTrips = (userId, status) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRIP_REQUEST,
    });
    if (status) {
      console.log("status", status);
      const { data } = await axios.get(
        `${baseUrl}ProfileDetailAPI/myTrips?id=${userId}&tripStatus=${status}`
      );
      console.log("trip data from api", data);
      dispatch({
        type: GET_TRIP_SUCCESS,
        payload: data,
      });
    } else {
      const { data } = await axios.get(
        `${baseUrl}ProfileDetailAPI/myTrips?id=${userId}`
      );
      console.log("trip data from api", data);
      dispatch({
        type: GET_TRIP_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_TRIP_FAIL,
      payload: "my trips API Failed,Please try again!",
    });
  }
};
// my trips action
// this action is called in my trips page
// it shows all trips of a customer
export const getSingleTrip = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRIP_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}DeliverySlotsAPI`);
    console.log("trip data from api", data);
    dispatch({
      type: GET_TRIP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_TRIP_FAIL,
      payload: "my trips API Failed,Please try again!",
    });
  }
};

// my trips action
// this action is called in my trips page
// it shows all trips of a customer
export const cancelTrip = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRIP_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}DeliverySlotsAPI`);
    console.log("trip data from api", data);
    dispatch({
      type: GET_TRIP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    dispatch({
      type: GET_TRIP_FAIL,
      payload: "Trip couldn't be cancelled,Please try again!",
    });
  }
};
