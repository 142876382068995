import React from "react";
import Section1Intro from "../../components/Help/Section1Intro";
import Section2UseCases from "../../components/Help/Section2UseCases";
import Section3BecomeAMover from "../../components/Help/Section3BecomeAMoover";

const Help = () => {
  return (
    <div>
      <Section1Intro />
      <Section2UseCases />
      <Section3BecomeAMover />
    </div>
  );
};

export default Help;
