import React, { useState, useRef, useEffect } from "react";
// importing the css
import css from "../../style/booking.module.css";
import { FaChevronDown } from "react-icons/fa";

const MoveTypes = ({ values, moveTypeId, clickFunction }) => {
  const [toggleDD, setToggleDD] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const menuRef = useRef();
  useEffect(() => {
    const closeDDMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setToggleDD(false);
      }
    };
    document.addEventListener("click", closeDDMenuEvent);

    return () => {
      document.addEventListener("click", closeDDMenuEvent);
    };
  }, []);
  useEffect(() => {
    if (!moveTypeId || !Array.isArray(values)) {
      setSelectedItem("Select move type");
    } else {
      const item = values.find((val) => moveTypeId === val?.id);
      setSelectedItem(item?.place);
    }
  }, [moveTypeId]);
  return (
    <div className={css?.dropdownMainDiv}>
      <div
        className={css?.dropdownTextDiv}
        onClick={() => setToggleDD(!toggleDD)}
        ref={menuRef}
      >
        {selectedItem}
        <FaChevronDown />
      </div>
      <div
        className={css?.DDOptions}
        style={{ display: toggleDD ? "block" : "none" }}
      >
        {values?.map((moves) => (
          <div
            onClick={() => clickFunction(moves?.id)}
            className={css?.DDOption}
            style={{
              backgroundColor:
                moves?.id === moveTypeId ? "var(--secondary)" : "white",
            }}
          >
            {moves?.place}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoveTypes;
