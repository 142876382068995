import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import { FaAndroid, FaApple } from "react-icons/fa";

const SectionF = () => {
  const [ShowCustomerAppDOwnloadButtons, setShowCustomerAppDOwnloadButtons] =
    useState(false);
  const [ShowDriverAppDOwnloadButtons, setShowDriverAppDOwnloadButtons] =
    useState(false);

  const handleOpenApp = (type = "customerAndroid") => {
    try {
      const Links = {
        customerAndroid:
          "https://play.google.com/store/apps/details?id=com.moovezwebiew",
        customerIos:
          "https://apps.apple.com/ca/app/moovez-customer/id6444885290",
        driverAndroid:
          "https://play.google.com/store/apps/details?id=com.moovez.driver",
        driverIos: "https://apps.apple.com/us/app/moovez-moover/id1666637663",
      };
      setShowCustomerAppDOwnloadButtons(false);
      setShowDriverAppDOwnloadButtons(false);
      window.location.href = Links[type];
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  function getMobileOperatingSystem(setFunc, isDriv) {
    try {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        handleOpenApp(`${isDriv ? "driver" : "customer"}Android`);
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        handleOpenApp(`${isDriv ? "driver" : "customer"}Ios`);
      } else {
        setFunc(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }
  return (
    <div className={css?.S6MainDIv}>
      <div className={css?.S6MainTxt} style={{ width: "100%" }}>
        There’s more to love in the apps
      </div>
      <div
        className={css?.appCard}
        onClick={() =>
          getMobileOperatingSystem(setShowCustomerAppDOwnloadButtons)
        }
        onMouseLeave={() => setShowCustomerAppDOwnloadButtons(false)}
      >
        <img src="images/app2.jpg" alt="" className={css?.driverAppImg} />
        <p className={css?.S6CardHeading}>Download the customer app</p>
        {ShowCustomerAppDOwnloadButtons ? (
          <div className={css?.storeButtons}>
            <div
              className={css?.storeButtonIcon}
              onClick={() => handleOpenApp()}
            >
              <FaAndroid />
            </div>
            <div
              className={css?.storeButtonIcon}
              onClick={() => handleOpenApp("customerIos")}
            >
              <FaApple />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={css?.appCard}
        onClick={() =>
          getMobileOperatingSystem(setShowDriverAppDOwnloadButtons, true)
        }
        onMouseLeave={() => setShowDriverAppDOwnloadButtons(false)}
      >
        <img src="images/app.jpg" alt="" className={css?.driverAppImg} />
        <p className={css?.S6CardHeading}>Download the moover app</p>
        {ShowDriverAppDOwnloadButtons ? (
          <div className={css?.storeButtons}>
            <div
              className={css?.storeButtonIcon}
              onClick={() => handleOpenApp("driverAndroid")}
            >
              <FaAndroid />
            </div>
            <div
              className={css?.storeButtonIcon}
              onClick={() => handleOpenApp("driverIos")}
            >
              <FaApple />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SectionF;
