import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/feedBackScreen.module.css";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { getAllFeedBacks } from "../../store/actions/feebBackActions.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Box, CircularProgress } from "@material-ui/core";

const FeedBack_words = [
  "Excellent Service",
  "Friendly",
  "On Time",
  "Rude",
  "Bad attitude",
  "Slow",
  "Late",
  "Hard working",
  "Other",
];

const FeedBackScreen = () => {
  const [hover, setHover] = React.useState(false);
  const [ratingStarCheck, setRatingStarCheck] = useState(false);
  const [commentCheck, setCommentCheck] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [feedBackObj, setfeedBackObj] = useState({
    id: 0,
    driverID: null,
    userIdD: 0,
    bookingID: 0,
    rating: 0,
    feedback: "",
    comments: "",
  });
  const { loginInfo } = useSelector((state) => state.loginReducer);
  const [userInfo, setuserInfo] = useState();

  //  const { bookingInfo } = useSelector((state) => state.bookingReducer);
  const [bookingState, setBookingState] = useState();
  useEffect(() => {
    try {
      const temp = JSON.parse(localStorage.getItem("wemoovezMoovingObject"));
      console.log("use eff get itemfsdsfsdf", temp);
      if (temp) {
        setBookingState(temp);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (bookingState) {
        setfeedBackObj({
          ...feedBackObj,
          bookingID: bookingState?.id,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingState]);

  useEffect(() => {
    try {
      if (loginInfo) {
        console.log("User is logged in", loginInfo);
        setuserInfo(loginInfo);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [loginInfo]);

  useEffect(() => {
    try {
      if (userInfo?.id) {
        setfeedBackObj({
          ...feedBackObj,
          UserIdD: userInfo?.id,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [userInfo]);

  console.log("loginInfo", loginInfo);

  const feedBackSubmitted = () => {
    try {
      if (feedBackObj.rating >= 1) {
        if (feedBackObj.comments !== "" || feedBackObj.feedback !== "") {
          getAllFeedBacks(feedBackObj);
          setDataSubmitted(true);
        } else {
          setCommentCheck(true);
        }
      } else {
        setRatingStarCheck(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleModalClose = () => {
    try {
      setRatingStarCheck(false);
      setCommentCheck(false);
      setDataSubmitted(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 340,
    minheight: "100px",
    height: 160,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    // main div
    <div className={css?.MainContainer}>
      <Modal
        open={ratingStarCheck}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description">
            Oops ! Please Rate the Driver.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css?.roundBtn}
              onClick={async () => {
                handleModalClose();
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={commentCheck}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description">
            Oops ! Please write your FeedBack.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css?.roundBtn}
              onClick={async () => {
                handleModalClose();
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={dataSubmitted}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description">
            Your FeedBack successfully Submitted.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={css?.roundBtn}
              onClick={async () => {
                handleModalClose();
              }}
            >
              Ok
            </div>
          </div>
        </Box>
      </Modal>

      <div className={css?.MainDiv}>
        <div className={css?.MainDiv1}>
          <h1>Rating</h1>
          <div className={css?.MainDiv2}>
            <h1>How was your job with {userInfo?.firstName}? </h1>
            <span
              style={{ padding: "0vh 2vh 0vh 2vh", color: "rgb(153,153,153)" }}
            >
              Your Job was from {bookingState?.pickupLocation} to{" "}
              {bookingState?.dropoffLocation}.
            </span>
          </div>
        </div>
        <div className={css?.MainDiv3}>
          <div className={css?.MainDiv4}>
            <Typography style={{ fontSize: 30 }} component="legend">
              Excellent
            </Typography>
            <Rating
              name="simple-controlled"
              value={feedBackObj.rating}
              onChange={(e) =>
                setfeedBackObj({ ...feedBackObj, rating: e.target.value })
              }
              style={{ paddingTop: 10 }}
              size="large"
              defaultValue={1}
            />
          </div>
        </div>
        <div className={css?.MainDiv5}>
          <div className={css?.MainDiv6}>
            <div className={css?.feedBackHeadingDiv}>
              <Typography
                style={{ fontSize: 30 }}
                component="legend"
                variant="h5"
                gutterBottom
              >
                Give feedback
              </Typography>
            </div>

            <div className={css?.FeedBackTextDiv}>
              {FeedBack_words.map((item) => {
                return (
                  <span
                    className={css?.FeedBackText}
                    style={
                      feedBackObj.comments === item
                        ? {
                            backgroundColor: "rgb(39,24,122)",
                            color: "white",
                            cursor: "pointer",
                          }
                        : {}
                    }
                    onClick={() => {
                      if (item === "Other") {
                        setHover(true);
                        setfeedBackObj({ ...feedBackObj, comments: "" });
                      } else {
                        setHover(false);
                        setfeedBackObj({ ...feedBackObj, comments: item });
                      }
                    }}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        {hover && (
          <div className={css?.FeedBackTextBox_Div}>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Empty"
              value={feedBackObj.feedback}
              onChange={(e) =>
                setfeedBackObj({ ...feedBackObj, feedback: e.target.value })
              }
              style={{ width: 310, marginTop: 10, minHeight: 90 }}
            />
          </div>
        )}
        <div className={css?.MainDiv10}>
          <button className={css?.submitBtn} onClick={feedBackSubmitted}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedBackScreen;
