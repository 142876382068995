import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Store from "./store";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserTracing } from "@sentry/tracing";
import Chatbot from "./components/Chatbot/BotpressChatBot";
import ReactGA from "react-ga4";
// Initialize React Ga with your tracking ID
const google_analytics_measurement_id = process.env.REACT_APP_GA_ID;
const sentry_dsn = process.env.REACT_APP_SENTRY_DSN;
ReactGA.initialize(google_analytics_measurement_id);
ReactGA.send("pageview");

Sentry.init({
  dsn: sentry_dsn,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Chatbot />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
