import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import Login from "../screens/Account/Login";

const LoginPage = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  );
};

export default LoginPage;
