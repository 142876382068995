import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/BecomeAMoover.module.css";

const Card = ({ source, text }) => {
  return (
    <div className={css?.s7Card}>
      <img
        className={css?.s7CardImg}
        src={`/images/${source}.svg`}
        alt={source}
      />
      <div className={css?.s7CardTxt}>{text}</div>
    </div>
  );
};
const Section7 = () => {
  const cards = [
    { source: "store", text: "Store purchases" },
    { source: "donation", text: "Junk removal & donations" },
    { source: "small_moves", text: "Small to medium-sized moves" },
    { source: "purchase", text: "Kijiji/Facebook Purchases" },
  ];
  return (
    <div className={css?.s7M}>
      <div className={css?.s7Heading}>What you’ll be moving</div>
      <div className={css?.s7Cards}>
        {cards.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </div>
  );
};

export default Section7;
