import React from "react";
import Layout from "../layouts/index";
import ForgotPassword from "../screens/Account/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <Layout>
      <ForgotPassword />
    </Layout>
  );
};

export default ForgotPasswordPage;
