import React from "react";
import css from "../../style/deliveryInfo.module.css";

const S5HowYouCanHelp = () => {
  const items = [
    {
      image: "packed_ready.svg",
      heading: "Packed and ready",
      content:
        "We are not a packing service, we are a moving and delivery service. Our moovers will help pack up some last minute items but they’re expecting things to be ready to go when they arrive.All our moovers have dollies, blankets, straps & basic tools but if you have fragile items that require extra protection, we expect these items to be wrapped in advance. Our moovers don’t carry additional boxes and bubble wrap. The more prepared you are, the smoother your delivery will be.",
      bullets: [],
    },
    {
      image: "register.svg",
      heading: "Pre-arrival checklist",
      content: `Before our moovers arrive, make sure to consider these things:`,
      bullets: [
        "Measure the dimensions of your items and all doorways to guarantee everything will fit.",
        "Disassemble any large furniture (there will be additional charges for disassembly).",
        "Clear a path for our moovers so they don’t trip.",
        "Defrost and clean your refrigerator and freezer.",
        "Make sure items are packed and ready to go.",
      ],
    },
    {
      image: "labour.svg",
      heading: "Labour expectations",
      content:
        "Our moovers will load and unload your items. For extremely large and heavy items, please make sure you’ve booked enough moovers or have additional lifters on hand to help. If it took extra help to get the items in the vehicle, our moovers will need the same help at the drop off location. Please consider this ahead of time. We do not specialize in heavy machinery or industrial equipment. Common household items and general construction materials are what we typically deliver.",
      bullets: [],
    },
  ];
  return (
    <div className={css?.s5Md}>
      <h2 className={css?.s5H}>How you can help our moovers.</h2>
      <p className={css?.s5T}>
        We come to you and help with the heavy lifting but there are things you
        can do ahead of time to be ready.
      </p>
      <div className={css?.s5Cards}>
        {items.map((item) => (
          <div className={css?.s5Card}>
            <img src={`/images/${item.image}`} className={css?.s5CardImg} />
            <h3 className={css?.s5CardH}>{item.heading}</h3>
            <p className={css?.s5CardT}>{item.content}</p>
            <ul>
              {item.bullets.map((bullet) => (
                <li className={css?.s5CardBullet}>{bullet}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default S5HowYouCanHelp;
