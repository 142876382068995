import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts";
import BookingSummaryScreen from "../screens/Booking/BookingSummary";

const MobileBookingSummary = () => {
  return (
    <Layout>
      <BookingSummaryScreen />
    </Layout>
  );
};

export default MobileBookingSummary;
