import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/businessSignup.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modals/Modal";
import BusinessDropdown from "../../components/Dropdowns/BusinessDropdown";
import {
  // getBusinessAverageDeliveries,
  getBusinessCurrentIndustry,
  // getBusinessGeneralLeadTime,
  // getBusinessOrderPreference,
  // getBusinessPreferedContactMethod,
  // getBusinessVehicleOftenRequired,
  postBookingSignup,
} from "../../store/actions/businessActions";
import { handleValidatePhoneNumber } from "../../Utilities/general";

function BusinessSignup() {
  // state to display errors
  const [error, seterror] = useState("");
  //history for routing
  const history = useNavigate();
  // dispatch actions
  const dispatch = useDispatch();
  // custom alert state
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });
  // Business dropdown data
  const [BusinessCurrentIndustry, setBusinessCurrentIndustry] = useState([]);

  const [businessObject, setBusinessObject] = useState({
    name: "",
    companyName: "",
    contactNumber: "",
    email: "",
    industryId: "",
  });

  const HandleChange = (e) => {
    setBusinessObject({ ...businessObject, [e.target.name]: e.target.value });
  };

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  // useEffect to show error modal
  useEffect(() => {
    try {
      // dispatch(getBusinessAverageDeliveries(setBusinessAverageDeliveries));
      dispatch(getBusinessCurrentIndustry(setBusinessCurrentIndustry));
      // dispatch(getBusinessGeneralLeadTime(setBusinessGeneralLeadTime));
      // dispatch(getBusinessOrderPreference(setBusinessOrderPreference));
      // dispatch(
      //   getBusinessPreferedContactMethod(setBusinessPreferedContactMethod)
      // );
      // dispatch(
      //   getBusinessVehicleOftenRequired(setBusinessVehicleOftenRequired)
      // );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleCheckNullOrUndefined = (item) => {
    try {
      if (item === null || item === undefined) return false;
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  function ValidateEmail(mail) {
    try {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      setModalFields({
        ...modalFields,
        open: true,
        text: "You have entered an invalid email address!",
      });
      return false;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }

  const handleBusinessSignup = () => {
    try {
      if (ValidateEmail(businessObject.email))
        if (
          businessObject.name &&
          businessObject.companyName &&
          businessObject.contactNumber &&
          businessObject.email
        ) {
          if (handleCheckNullOrUndefined(businessObject.industryId)) {
            dispatch(
              postBookingSignup(
                {
                  ...businessObject,
                  contactNumber: parseInt(businessObject.contactNumber),
                  bulkOrderDelivery:
                    businessObject.bulkOrderDelivery === "true" ? true : false,
                },
                history
              )
            );
          } else alert("Please select all the options");
        } else alert("Please fill all fields");
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // function to add number
  const handleAddPhoneNumber = (e) => {
    try {
      let number = e?.target?.value;
      let numArr = [...number];
      if (numArr?.length < 13) {
        let newNum = "";
        for (let i = 0; i < numArr?.length; i++) {
          console.log(i);
          if (numArr[i] >= "0" && numArr[i] <= "9" && i != 3 && i != 7) {
            newNum = newNum + numArr[i];
          } else if (numArr[i] === "-" && i === 3) {
            newNum = newNum + numArr[i];
          } else if (numArr[i] === "-" && i === 7) {
            newNum = newNum + numArr[i];
          } else {
            setBusinessObject({
              ...businessObject,
              contactNumber: newNum,
            });
            return null;
          }
        }
        if (numArr?.length === 3) {
          number = number + "-";
          setBusinessObject({
            ...businessObject,
            contactNumber: number,
          });
        } else if (numArr?.length === 7) {
          number = number + "-";
          setBusinessObject({
            ...businessObject,
            contactNumber: number,
          });
        } else {
          setBusinessObject({
            ...businessObject,
            contactNumber: number,
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const inputFields = [
    {
      title: "Tell us about your business.",
      inputFields: [
        {
          name: "name",
          placeholder: "Contact Name",
          value: businessObject.name,
          onChange: HandleChange,
        },
        {
          name: "companyName",
          placeholder: "Business Name",
          value: businessObject.companyName,
          onChange: HandleChange,
        },
        {
          name: "contactNumber",
          placeholder: "Phone",
          value: businessObject.contactNumber,
          onChange: (e) =>
            handleValidatePhoneNumber(e, (num) =>
              setBusinessObject({
                ...businessObject,
                contactNumber: num,
              })
            ),
        },
        {
          name: "email",
          placeholder: "Email",
          value: businessObject.email,
          onChange: HandleChange,
        },
      ],
    },
  ];

  const dropdownList = [
    {
      title: "Industry",
      options: BusinessCurrentIndustry,
      changeFunction: (e) =>
        setBusinessObject({ ...businessObject, industryId: e }),
      value: businessObject.industryId,
      idKey: "industryId",
      valueKey: "industryName",
    },
  ];

  return (
    <div className={css?.signupMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <img src="/images/sign_avtar.svg" alt="" className={css?.posterImage} />
      <div className={css?.mainForm}>
        {inputFields.map((section) => (
          <>
            <div className={css?.posterMainHeading}>{section.title}</div>
            {section.inputFields.map((inputParams, key) => (
              <input
                placeholder={inputParams.placeholder}
                value={inputParams.value}
                name={inputParams.name}
                onChange={inputParams.onChange}
                className={css?.fullInput}
                key={key}
              />
            ))}
          </>
        ))}
        {dropdownList.map((dropdownParams, index) => (
          <BusinessDropdown {...dropdownParams} key={index} />
        ))}
        {/* <BusinessDropdown title /> */}
        <div className={css?.signupBtnMainDiv}>
          <button className={css?.submitBtn} onClick={handleBusinessSignup}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default BusinessSignup;
