import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Privacy.module.css";

const Privacy = () => {
  return (
    <div className={css?.privacy_policy}>
      <h3
        className={css?.colorDarkPurp}
        style={{
          fontWeight: "bolder",
          fontSize: "2.5rem",
        }}
      >
        moovez Platform Privacy Policy
      </h3>
      <p>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.75rem" }}>
          <br /> <br /> Last Updated: January 1, 2024
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.50rem" }}>
          <br /> <br /> This Privacy Policy explains how moovez collects, uses
          and discloses personal information
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          <br /> <br /> MUV Logistics Network Technologies Ltd. take privacy and
          data security seriously. We are committed to transparency in the
          collection, use and disclosure of Your personal information. This
          Privacy Policy describes how we use personal information, and the
          circumstances under which we may share this information with a third
          party. This Privacy Policy applies to the privacy practices of moovez
          on our website located at: www.moovez.ca, or the various associated
          websites, platforms or applications (collectively and each
          individually, the"moovez Platform") but does not include personal
          information relating to employees in such a capacity. <br /> <br />
          By visiting and/or using the moovez Platform, You consent to the
          collection, use, storage, disclosure and transfer of Your personal
          information as set out in this policy. In some cases, we may ask You
          to provide explicit consent if we intend to collect, use or disclose
          Your personal information otherwise than as indicated in this Privacy
          Policy, however sometimes Your consent may be implied through Your
          conduct with us. <br /> <br />
          If You do not agree with any part of this Privacy Policy You should
          not conduct any transactions on the moovez Platform, navigate away
          from the moovez Platform immediately, and discontinue the use of our
          application.
        </span>
        <br />
        <br />
        <br />
      </p>

      <h3
        className={css?.colorDarkPurp}
        style={{
          marginBottom: "4rem",
          fontWeight: "bolder",
          fontSize: "2rem",
        }}
      >
        Interpretation and Definitions
      </h3>
      <p>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "2rem", fontWeight: "bolder" }}
        >
          Interpretation
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          <br /> <br /> The words of which the initial letter is capitalized
          have meanings defined under the following conditions. The following
          definitions shall have the same meaning regardless of whether they
          appear in singular or in plural.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "2rem", fontWeight: "bolder" }}
        >
          Definitions
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          For the purposes of this Privacy Policy:
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Account&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          means a unique account created for You to access our Service or
          partsof our Service.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Company&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          (referred to as either "the Company", "We", "Us" or "Our" in this
          Agreement) refers to moovez
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Cookies&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          are small files that are placed on Your computer, mobile device or any
          other device by a website, containing the details of Your browsing
          history on that website among its many uses.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Country&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          refers to: Canada
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Device&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          means any device that can access the Service such as a computer, a
          cellphone or a digital tablet.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Personal Data&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          is any information that relates to an identified or identifiable
          individual.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Service&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          refers to the website
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Service Provider&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analyzing how the Service is used.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Third-party Social Media Service&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          refers to any website or any social network website through which a
          User can log in or create an account to use the Service.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Usage Data&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Website&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          refers to moovez, <br /> accessible from https://www.moovez.ca
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          You&nbsp;
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          means the individual accessing or using the Service, or the company,
          or other legal entity on behalf of which such individual is accessing
          or using the Service, as applicable.
        </span>
        <br /> <br /> <br /> <br />
        <br />
      </p>

      <h3
        className={css?.colorDarkPurp}
        style={{
          marginBottom: "4rem",
          fontWeight: "bolder",
          fontSize: "2rem",
        }}
      >
        Collection and Use of Personal Information
      </h3>
      <p>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.75rem", fontWeight: "bolder" }}
        >
          This section explains the purposes for which moovez collects personal
          information
        </span>
        <br />
        <br />
        <br />
        <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Account Creation: <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We collect Your name, phone number, email address, a hashed password
          of Your selection, the date and time of account creation and the IP
          address and device identifier of the device used for account creation.
          We use this information to administer Your account, to enable Your
          access to the moovez Platform, verify Your identity as an authorized
          user concerning any accounts, and to implement, carry out and maintain
          security measures aimed at protecting our customers from identity
          theft, fraud and unauthorized access to an account. We use this
          information to administer Your account, and to enable Your access to
          the moovez Platform.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Social Media: <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We may offer You the opportunity to otherwise engage with moovez or
          its content on or through Social Media. When You engage with us
          through Social Media websites, plug-ins and applications, You may
          allow us to have access to and disclose certain information associated
          with Your Social Media account (e.g., name, username, email address,
          profile picture) to deliver content or as part of the operation of the
          website, plug-in or application.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Placing an Order: <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We collect information relating to any orders You place on the moovez
          Platform, including the IP address of the device used to place the
          order to help protect against theft, fraud and unauthorized access to
          an account, and certain payment information using the secure services
          of our payment processors. Payment operations are outsourced to our
          payment processors and we do not store Your credit card information in
          our systems other than the first four and last four digits of the
          credit card number. If Your order has been placed for delivery, we
          collect the physical address to which You have requested delivery. We
          use this information to complete Your delivery, and to allow You to
          select previously entered addresses for future deliveries.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Order History: <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We may collect Your order history including the date, time, cost, type
          of labour, physical address and delivery instructions, method of
          purchase, and order details, including specific items and special
          order instructions. We use this information to ensure that Your order
          has been completed and to improve our service offerings.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Marketing and Other Communications: <br />
          <br />
        </span>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          This section explains when we may contact You for marketing purposes
          or to inform You about important changes or other developments to the
          moovez Platform, products, services or Your account
          <br />
          <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We may use Your personal information to contact You for Your views
          regarding moovez and to notify You occasionally about important
          changes or developments to the moovez Platform, products, services or
          Your account. You cannot opt out of receiving notifications of
          important changes or developments (e.g. to this Privacy Policy or the
          Terms of Service/Use) unless You discontinue use of the moovez
          Platform.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Referrals: <br />
          <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          The referral, "refer a friend" and other similar features on the
          moovez Platform permit You to send an email or a URL to other
          individuals that may be interested in moovez. This feature may require
          You to submit Your name and email address, as well as the name and
          email address of the recipient. We do not use the names or email
          addresses submitted in these circumstances for any other purpose
          without the consent of You or the email recipient to do so. Please
          ensure that You only submit email addresses of individuals with whom
          You have a personal or family relationship and who would want to
          receive the message.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Become a Driver: <br />
          <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          You may apply to become an independently contracted courier by
          providing us with personal information such as Your name, contact
          information, identification documents, proof of work eligibility,
          banking information as well as other contractor-related materials that
          we require from You. We use this information for the purpose of
          processing and responding to Your application, and to manage any
          ongoing contractor relationship.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Contact Us: <br />
          <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          When You contact us with a comment or question, we collect the
          information You provide such as Your name and email address, along
          with additional information that we may need to help us promptly
          respond to Your inquiry. We retain this information to assist You in
          the future and to improve our customer service and service offerings.
          We also use personal information to establish and manage our
          relationship with You and provide quality service. We may record voice
          calls for training and quality purposes
        </span>
        <br />
      </p>

      <p>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.75rem", fontWeight: "bolder" }}
        >
          <br />
          <br />
          Transfer of Your Personal Data
        </span>
        <br />
        <br />
        <br />
        <br />
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
          <br />
          <br />
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
          <br />
          <br />
          The Company will take all steps necessary to ensure that Your data is
          treated securely and in accordance with this Privacy Policy and no
          transfer of Your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the
          security of Your data and other personal information.
          <br />
          <br />
        </span>
        <br />
      </p>

      <p>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.75rem", fontWeight: "bolder" }}
        >
          Disclosure of Your Personal Data
        </span>
        <br />
        <br />
        <br />
        <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Business Transactions <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Law Enforcement <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Third Parties <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          The third parties with whom we share Your information may undertake
          various activities such as processing credit card payments and
          providing services or products for us. In addition, we may need to
          provide Your personal information to any vendors that a customer has
          placed an order with and couriers to allow the order to be processed
          and delivered. By submitting Your personal data, You agree to this
          transfer, storing or processing. We will take all steps reasonably
          necessary to ensure that Your personal information is treated securely
          and in accordance with this Privacy Policy.
        </span>
        <br /> <br />
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          Other legal requirements <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
          <br />
          <br />
          • Comply with a legal obligation
          <br />
          <br />
          • Protect and defend the rights or property of the Company
          <br />
          <br />
          • Prevent or investigate possible wrongdoing in connection with the
          Service
          <br />
          <br />
          • Protect the personal safety of Users of the Service or the public
          <br />
          <br />
          • Protect against legal liability
          <br />
          <br />
        </span>
        <br />
        <br />
        <br />
      </p>

      <h3
        className={css?.colorDarkPurp}
        style={{
          marginBottom: "4rem",
          fontWeight: "bolder",
          fontSize: "2rem",
        }}
      >
        Safeguarding and Retention of Personal Information
      </h3>
      <p>
        <span
          className={css?.colorDarkPurp}
          style={{ fontSize: "1.25rem", fontWeight: "bolder" }}
        >
          This section explains the safeguards moovez has put in place to
          protect Your personal information and the length of time moovez may
          retain Your personal information <br /> <br />
        </span>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We have implemented administrative, technical and physical measures to
          protect Your personal information from unauthorized access and against
          unlawful processing, accidental loss, destruction and damage. We
          restrict access to Your personal information to moovez personnel and
          authorized service providers who require access to fulfill their job
          requirements.
          <br />
          <br />
          We will keep Your information where we have an ongoing legitimate
          business need to do so (for example, while You hold an account with us
          and for a reasonable period of time thereafter, or to enable us to
          meet our legal, tax or accounting obligations). We may retain certain
          data as necessary to prevent fraud or future abuse, or for legitimate
          business purposes, such as analysis of aggregated, non-personal
          information, or account recovery, or if required or permitted by law.
          All retained personal information will remain subject to the terms of
          this Privacy Policy. If You request that Your name be removed from our
          databases, it may not be possible to completely delete all Your
          personal information due to technological and legal constraints.
          <br />
          <br />
          The transmission of information via the internet is not completely
          secure. Although we will take steps to protect Your personal
          information, we cannot guarantee the security of Your personal
          information or other data transmitted to moovez, any transmission is
          at Your own risk.
        </span>
        <br />
        <br />
        <br />
        <br />
      </p>

      <h3
        className={css?.colorDarkPurp}
        style={{
          marginBottom: "4rem",
          fontWeight: "bolder",
          fontSize: "2rem",
        }}
      >
        Changes to this Privacy Policy
      </h3>
      <p>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
          <br />
          <br />
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </span>
        <br />
        <br />
        <br />
        <br />
      </p>

      <h3
        className={css?.colorDarkPurp}
        style={{
          marginBottom: "4rem",
          fontWeight: "bolder",
          fontSize: "2rem",
        }}
      >
        Contact Us
      </h3>
      <p>
        <span className={css?.colorDarkPurp} style={{ fontSize: "1.25rem" }}>
          If You have any questions about this Privacy Policy, You can contact
          us:
          <br />
          <br />• By email: team@wemoovez.com
        </span>
      </p>
    </div>
  );
};

export default Privacy;
