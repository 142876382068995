import React from "react";
import css from "../../style/earning.module.css";

const Section4_Payout = () => {
  return (
    <div className={css?.s4Md}>
      <div className={css?.s4Heading}>How payouts work</div>
      <div className={css?.s4BoldText}>
        Sign in to your moover account and find your Wallet in the app menu.
      </div>
      <div className={css?.s4BoldText}>
        Add a payout method and follow the steps.
      </div>
      <div className={css?.s4Text}>
        Your earnings are transferred automatically each week to your linked
        bank account. Before you can begin accepting jobs a payout method is
        required.
      </div>
    </div>
  );
};

export default Section4_Payout;
