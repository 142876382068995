import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/orderSummary.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/loginActions";
import {
  getBookingSummary,
  startTimeResponse,
} from "../../store/actions/bookingSummary";
import { MdLocationPin } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { profileImageURL } from "../../store/constants/baseUrl";
import AddCardModal from "../../components/Modals/AddCardModal";
import Loader from "../../components/Loader/Loader";

const PriceLine = ({ Key, value, noCA, noDollar, isBold, noBorder }) => {
  if (value === null || value === undefined) return <></>;
  return (
    <div
      className={isBold ? css?.calculationBox2 : css?.calculationBox1}
      style={{
        borderBottom: noBorder ? "none" : "1px solid rgb(182, 182, 182)",
      }}
    >
      <div className={css?.label1}>{Key}:</div>
      <div className={css?.value1}>
        {noCA ? " " : "CA "}
        {noDollar ? "" : "$"}
        {value}
      </div>
    </div>
  );
};
const OrderSummary = () => {
  const history = useNavigate();
  const { userId, bookingId } = useParams();
  const dispatch = useDispatch();

  const [openCardModal, setOpenCardModal] = useState(false);
  const [bookingInfo, setBookingInfo] = useState();
  const [CardSaved, setCardSaved] = useState(false);
  const toggleCardModalState = () => setOpenCardModal(!openCardModal);
  useEffect(() => {
    try {
      let user = localStorage.getItem("wemoovezUserProfile");
      if (user) {
        user = JSON.parse(user);
        if (user.id.toString() === userId)
          dispatch(getBookingSummary(userId, bookingId, setBookingInfo));
        else {
          localStorage.setItem(
            "redirectToSummary",
            JSON.stringify({ userId, bookingId })
          );
          dispatch(logout(history, "/login"));
        }
      } else {
        localStorage.setItem(
          "redirectToSummary",
          JSON.stringify({ userId, bookingId })
        );
        history("/login");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      if (CardSaved) {
        setCardSaved(false);
        dispatch(getBookingSummary(userId, bookingId, setBookingInfo));
        setOpenCardModal(false);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [CardSaved]);

  const handleRespondToSummary = (response) => {
    try {
      dispatch(
        startTimeResponse(
          userId,
          bookingId,
          bookingInfo?.email,
          response,
          history
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <>
      {bookingInfo ? (
        <div className={css?.orderSummaryMainDiv}>
          <AddCardModal
            text={"Please Enter your Card's details"}
            openState={openCardModal}
            closeFunction={toggleCardModalState}
            userId={userId}
            setCardSaved={setCardSaved}
          />
          <div className={css?.cardsMainDiv}>
            <img
              className={css?.moovezImage}
              src="/images/Moovez-Dark.svg"
              alt=""
            />
            <div className={css?.cardMainDiv}>
              <div className={css?.heading1}>Thanks for choosing moovez</div>
              <div className={css?.text1}>
                The little things make a big difference and we hope that we've
                made your day a little better
              </div>
              <div className={css?.text1} style={{ marginTop: "30px" }}>
                Cost breakdown:
              </div>
              {bookingInfo?.bookingTotalModel?.totalCharge ? (
                <div className={css?.totalBox}>
                  <div className={css?.heading1}>Total</div>
                  <div className={css?.heading1}>
                    CA ${bookingInfo?.bookingTotalModel?.totalCharge}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {bookingInfo?.bookingTotalModel ? (
              <div className={css?.cardMainDiv}>
                <PriceLine
                  Key={"Base Fare"}
                  value={bookingInfo?.bookingTotalModel.baseFare}
                />
                <PriceLine
                  Key={"Distance"}
                  value={bookingInfo.bookingTotalModel.totalDistance}
                />
                <PriceLine
                  Key={"Subtotal"}
                  value={bookingInfo.bookingTotalModel.subtotal}
                  isBold={true}
                />
                <PriceLine
                  Key={"Service Fee"}
                  value={bookingInfo.bookingTotalModel.totalServiceFee}
                />
                <PriceLine
                  Key={"Labour Surcharge"}
                  value={bookingInfo.bookingTotalModel.labourSurcharge}
                />
                <PriceLine
                  Key={"GST"}
                  value={bookingInfo.bookingTotalModel.gstandPst}
                  noBorder={
                    bookingInfo.bookingTotalModel.discount ? false : true
                  }
                />
                <PriceLine
                  Key={"Discount"}
                  value={bookingInfo.bookingTotalModel.discount}
                  noCA={true}
                  noDollar={true}
                  noBorder={true}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={css?.cardMainDiv}>
              <div className={css?.heading2}>Amount Charged</div>
              <div className={css?.CardLine}>
                {bookingInfo?.cardLastFourDigits ? (
                  <div className={css?.CardInfoSec}>
                    <div className={css?.Cardtype}>
                      {bookingInfo.cardType ?? ""}
                    </div>
                    <div className={css?.CardNo}>
                      **** {bookingInfo.cardLastFourDigits}
                    </div>
                    <div
                      className={css?.UpdateCard}
                      onClick={() => toggleCardModalState()}
                    >
                      Update
                    </div>
                  </div>
                ) : (
                  <div
                    className={css?.UpdateCard}
                    onClick={() => toggleCardModalState()}
                  >
                    Add Card
                  </div>
                )}
                <div className={css?.TotalAmountDiv}>
                  CA ${bookingInfo?.bookingTotalModel?.totalCharge}
                </div>
              </div>
            </div>
            {bookingInfo?.name ? (
              <div className={css?.cardMainDiv}>
                <div className={css?.heading2}>You rode with</div>
                <div className={css?.driverInfoMain}>
                  <div className={css?.driverInfoLeft}>
                    <img
                      className={css?.driverInfoLeftLeft}
                      src={
                        bookingInfo?.profileDisplayImageUrl
                          ? bookingInfo?.profileDisplayImageUrl
                          : "/images/friend.png"
                      }
                      alt="driver"
                    />
                    <div className={css?.driverInfoLeftRight}>
                      <div className={css?.driverInfoLeftRightTop}>
                        {bookingInfo.name}
                      </div>
                      {bookingInfo?.rating ? (
                        <div className={css?.driverInfoLeftRightBottom}>
                          <AiFillStar className={css?.StarIcon} />
                          {bookingInfo.rating}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={css?.driverInfoRight}>
                    Rate or Tip {bookingInfo.name}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {bookingInfo?.pickupLocation ||
            bookingInfo?.dropoffLocation ||
            bookingInfo?.stops?.length ? (
              <div className={css?.cardMainDiv}>
                <div className={css?.heading2}>Trip Info</div>
                {bookingInfo?.pickupLocation ? (
                  <div className={css?.locationLine}>
                    <MdLocationPin className={css?.locationPin} />
                    <div className={css?.text1}>
                      {bookingInfo.pickupLocation}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {bookingInfo?.stops?.map((stop) => (
                  <div className={css?.locationLine}>
                    <MdLocationPin className={css?.locationPin} />
                    <div className={css?.text1}>{stop}</div>
                  </div>
                ))}
                {bookingInfo?.dropoffLocation ? (
                  <div className={css?.locationLine}>
                    <MdLocationPin className={css?.locationPin} />
                    <div className={css?.text1}>
                      {bookingInfo.dropoffLocation}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {bookingInfo?.referalLink ? (
              <div className={css?.cardMainDiv}>
                <div className={css?.heading2}>Referral Program</div>
                <div className={css?.text1}>
                  Copy this link and send it to yours friends to receive
                  discounts on your next trip!
                </div>
                <div className={css?.text1}>{bookingInfo.referalLink}</div>
              </div>
            ) : (
              <></>
            )}
            <div className={css?.buttonsDiv}>
              <div
                className={css?.acceptBtn}
                onClick={() => handleRespondToSummary(true)}
              >
                Accept
              </div>
              <div
                className={css?.rejectBtn}
                onClick={() => handleRespondToSummary(false)}
              >
                Reject
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OrderSummary;
