import css from "../../style/rating.module.css";
import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  postFeedback,
  getFeedbackOptions,
  getTipOptions,
} from "../../store/actions/ratingActions";
import { useDispatch } from "react-redux";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getBookingSummary } from "../../store/actions/bookingSummary";

const TipOption = ({ value, setState, id, currentId }) => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  useEffect(() => {
    try {
      const arr = value.split(" ");
      if (!isNaN(arr[0])) {
        setFirst("$" + arr[0]);
        arr?.shift();
        setLast(arr.flat());
      } else setLast(value);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  return (
    <div
      onClick={() => setState(id)}
      className={css?.tipOptions}
      style={
        id === currentId
          ? { backgroundColor: "var(--primary)", color: "#fff" }
          : {}
      }
    >
      {first ? (
        <div style={id === currentId ? { color: "#fff" } : {}}>{first}</div>
      ) : (
        ""
      )}{" "}
      {last}
    </div>
  );
};

const FeedbackOption = ({ value, setState, id, currentId }) => {
  return (
    <div
      onClick={() => setState(id)}
      className={css?.tipOptions}
      style={
        id === currentId
          ? { backgroundColor: "var(--primary)", color: "#fff" }
          : {}
      }
    >
      {value}
    </div>
  );
};

const RatingScreen = () => {
  const [star, setStar] = useState(1);
  const [tipId, setTipId] = useState(0);
  const [feedbackId, setFeedbackId] = useState(0);
  const [comment, setComment] = useState("");
  const [feedbackOptions, setFeedbackOptions] = useState();
  const [tipOptions, setTipOptions] = useState();
  const [showComment, setShowComment] = useState(false);
  const [booking, setBooking] = useState();
  const [error, setError] = useState("");
  const history = useNavigate();
  const { userId, bookingId } = useParams();
  const dispatch = useDispatch();
  const ratingText = ["Very Bad", "Bad", "Average", "Good", "Excellent"];

  const handlePostFeedback = () => {
    try {
      dispatch(
        postFeedback(
          {
            rating: star,
            feedbackId,
            comments: comment,
            userIdD: userId,
            bookingId,
            tipId,
          },
          setError,
          history
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      dispatch(getFeedbackOptions(setFeedbackOptions));
      dispatch(getTipOptions(setTipOptions));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      if (bookingId && userId)
        dispatch(getBookingSummary(userId, bookingId, setBooking));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingId, userId]);

  useEffect(() => {
    try {
      if (tipOptions?.length) setTipId(tipOptions[0]?.tipId);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [tipOptions]);

  useEffect(() => {
    try {
      if (feedbackOptions?.length)
        setFeedbackId(feedbackOptions[0]?.feedbackId);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [feedbackOptions]);

  return (
    <div className={css?.mainDIv}>
      <div className={css?.mainHeading}>Rating</div>
      <MdCancel className={css?.cancelIcon} onClick={() => history("/home")} />
      {booking ? (
        <div className={css?.shadowedBox}>
          <div className={css?.shadowTopText}>
            {booking?.name ? "How was your job with " + booking?.name : ""}
          </div>
          <div className={css?.greyText}>
            {booking?.pickupLocation
              ? `Your job was from 
              ${booking?.pickupLocation}${
                  booking?.startTime ? ` on ${booking?.startTime}.` : "."
                }`
              : ""}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={css?.boxMainDiv}>
        <div className={css?.topText}>{ratingText[star - 1]}</div>
        <div className={css?.options}>
          {[1, 2, 3, 4, 5].map((num) =>
            star >= num ? (
              <AiFillStar
                className={css?.starFilled}
                onClick={() => setStar(num)}
              />
            ) : (
              <AiOutlineStar
                className={css?.starEmpty}
                onClick={() => setStar(num)}
              />
            )
          )}
        </div>
      </div>
      {tipOptions?.length ? (
        <div className={css?.boxMainDiv}>
          <div className={css?.topText}>Tip</div>
          <div className={css?.options}>
            {tipOptions.map((tip, index) => (
              <TipOption
                value={tip.value}
                setState={setTipId}
                id={tip.tipId}
                currentId={tipId}
                key={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {feedbackOptions?.length ? (
        <div className={css?.boxMainDiv}>
          <div className={css?.topText}>Give feedback</div>
          <div className={css?.options}>
            {feedbackOptions.map((feedback, index) => (
              <FeedbackOption
                value={feedback.value}
                setState={setFeedbackId}
                id={feedback.feedbackId}
                currentId={feedbackId}
                key={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className={css?.addACmt}
        onClick={() => setShowComment(!showComment)}
      >
        Add a comment here
      </div>
      {showComment ? (
        <textarea
          className={css?.commentText}
          placeholder="Comments will appear here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      ) : (
        <div style={{ height: 10 }}></div>
      )}
      {error ? error : ""}
      <div className={css?.submit} onClick={() => handlePostFeedback()}>
        Submit
      </div>
    </div>
  );
};

export default RatingScreen;
