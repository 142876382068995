import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/registerPageStyle.module.css";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { changePassword } from "../../store/actions/forgotPasswordActions";

function ResetPassword() {
  // status is password changed or not
  const [status, setStatus] = useState(false);
  // state to display errors
  const [error, seterror] = useState("");
  // code from Params
  const { email } = useParams();
  //history for routing
  const history = useNavigate();
  // dispatch actions
  const dispatch = useDispatch();
  //oldPassword
  const [oldPassword, setoldPassword] = useState("");
  const [oldPasswordActive, setoldPasswordActive] = useState(false);
  //password
  const [password, setpassword] = useState("");
  const [passwordStrength, setpasswordStrength] = useState(0);
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");
  // check password strength
  const handleSetPassword = (e) => {
    try {
      let strengthLevel = 0;
      const passArray = [...e];
      setpassword(e);
      if (e !== 0) {
        const hasLowerCase = passArray?.filter(
          (letter) => letter >= "a" && letter <= "z"
        );
        const hasUpperCase = passArray?.filter(
          (letter) => letter >= "A" && letter <= "Z"
        );
        const hasNumber = passArray?.filter(
          (letter) => letter >= "0" && letter <= "9"
        );
        const hasSC1 = passArray?.filter(
          (letter) => letter >= "!" && letter <= "/"
        );
        const hasSC2 = passArray?.filter(
          (letter) => letter >= ":" && letter <= "@"
        );
        const hasSC3 = passArray?.filter(
          (letter) => letter >= "[" && letter <= "`"
        );
        const hasSC4 = passArray?.filter(
          (letter) => letter >= "{" && letter <= "~"
        );
        if (hasLowerCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasUpperCase?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (hasNumber?.length > 0) {
          strengthLevel = strengthLevel + 20;
        }
        if (
          hasSC1?.length > 0 ||
          hasSC2?.length > 0 ||
          hasSC3?.length > 0 ||
          hasSC4?.length > 0
        ) {
          strengthLevel = strengthLevel + 20;
        }
        if (e?.length > 5) {
          strengthLevel = strengthLevel + 20;
        }
      }
      setpasswordStrength(strengthLevel);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleResetPassword = () => {
    try {
      if (oldPassword && password && confirmPassword) {
        if (passwordStrength === 100) {
          if (password === confirmPassword) {
            const resetPasswordData = {
              currentPassword: oldPassword,
              newPassword: password,
              email: email,
            };
            console.log(
              "new pass data from reset pass component",
              resetPasswordData
            );
            dispatch(
              changePassword(resetPasswordData, history, setStatus, seterror)
            );
          } else {
            alert("Passwords do not match");
          }
        } else {
          alert(
            "Your password must contain at least 6 characters. It should include a mix of upper and lower case letters, numbers, and special characters."
          );
        }
      } else {
        alert("Please fill all the fields");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div className={css?.RegisterMainDiv}>
      <div className={css?.signup_page}>
        {status ? (
          <div style={{ color: "var(--primary)", textAlign: "center" }}>
            Password Changed Successfully!
          </div>
        ) : (
          <div className={css?.inner_content}>
            <div class={css?.RegisterMainHeading}>Change Password</div>
            <div style={{ color: "transparent" }}>---</div>
            <>
              <div class={css?.sign_up_form}>
                <div class={css?.fullInputleft}>
                  <span className={css?.textIconsFull}>
                    <i class="fas fa-lock"></i>
                  </span>
                  <input
                    placeholder="Old password"
                    value={oldPassword}
                    type="password"
                    className={css?.inputFieldFull}
                    onFocus={() => setoldPasswordActive(true)}
                    onChange={(e) => setoldPassword(e?.target?.value)}
                  />
                  {oldPasswordActive && !oldPassword && (
                    <span className={css?.crossIconFull}>
                      <i class="far fa-times-circle"></i>
                    </span>
                  )}
                </div>
                {/* password input  */}
                <div class={css?.fullInputleft}>
                  <span className={css?.textIconsFull}>
                    <i class="fas fa-unlock-alt"></i>
                  </span>
                  <input
                    placeholder="Password"
                    value={password}
                    type="password"
                    className={css?.inputFieldFull}
                    // value={password}
                    onChange={(e) => handleSetPassword(e?.target?.value)}
                  />
                </div>
                <div class={css?.fullInputleft}>
                  <span className={css?.textIconsFull}>
                    <i class="fas fa-unlock-alt"></i>
                  </span>
                  <input
                    placeholder="Confirm password"
                    value={confirmPassword}
                    type="password"
                    className={css?.inputFieldFull}
                    onChange={(e) => setconfirmPassword(e?.target?.value)}
                  />
                </div>
              </div>
              <div style={{ color: "red" }}>{error ? error : ""}</div>
              <div
                className={css?.BottomButtons}
                style={{ justifyContent: "flex-end" }}
              >
                <button
                  onClick={handleResetPassword}
                  className={css?.submitButton}
                >
                  Change Password
                </button>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
