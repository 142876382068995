import React from "react";
import Layout from "../layouts/index";
import Earnings from "../screens/Info/Earnings";

const EarningsPage = () => {
  return (
    <Layout>
      <Earnings />
    </Layout>
  );
};

export default EarningsPage;
