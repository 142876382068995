import React from "react";
import css from "../../style/moverInfo.module.css";

const Card = ({ title, index }) => {
  return (
    <div className={css?.S5_CardMD}>
      <div className={css?.S5_CardTitle}>{title}</div>
      <img
        className={css?.S5_Image}
        src={`/images/s${index + 1}.svg`}
        alt="Help"
      />
    </div>
  );
};
const S5AllNeeds = () => {
  const LIST = [
    { title: "Moving services" },
    { title: "Store delivery" },
    { title: "Used item delivery" },
    { title: "Donations" },
    { title: "Junk removal" },
    { title: "Storage moves" },
    { title: "Office moves" },
    { title: "Labour only" },
    { title: "Small business delivery" },
  ];
  return (
    <div className={css?.S5_Md}>
      <div className={css?.S5_Heading}>
        Use moovez for all your moving & delivery needs.
      </div>
      <div className={css?.S5_CardsDiv}>
        {LIST.map((info, index) => (
          <Card title={info.title} index={index} />
        ))}
      </div>
    </div>
  );
};

export default S5AllNeeds;
