import React from "react";
import css from "../../style/earning.module.css";

const ImageSection = ({ imageSrc }) => {
  return (
    <div className={css?.s3ImageD}>
      <img
        src={"/images/" + imageSrc + ".png"}
        className={css?.s3ImageTag}
        alt={imageSrc}
      />
    </div>
  );
};
const TextSection = ({ title, description }) => {
  return (
    <div className={css?.s3Info}>
      <div className={css?.s3Title}>{title}</div>
      <div className={css?.s3Desc}>{description}</div>
    </div>
  );
};
const Section3_InfoBubbles = () => {
  const steps = [
    {
      title: "Earnings",
      image: "earning",
      description:
        "What you see is what you get. When you accept a delivery job for $75 or a mooving job for $250-300 from your moover dashboard, that’s your calculated earnings. The customer pays a different total.",
    },
    {
      title: "Track payments",
      image: "track_payment",
      description:
        "See what you made for each order, including tips. Track everything from your moover earnings page.",
    },
    {
      title: "Ratings",
      image: "rating",
      description:
        "You’re required to maintain at least a 4.0 star rating out of 5.0. Your rating is an overall average so every job counts. Our platform rewards hard work and improves your chances of receiving more favorable jobs if you maintain a high rating.",
    },
    {
      title: "Low ratings",
      image: "low_rating",
      description:
        "While we take all ratings seriously, we also know that one isolated incident doesn’t necessarily reflect your overall performance. We will always examine low ratings on a case-by-case basis. If we notice a pattern of low ratings your account will be frozen and blocked from accepting jobs. Our goal is to maintain a high standard of customer service with every job and want to ensure that only the moovers who feel the same are able to accept jobs on our platform.",
    },
  ];
  return (
    <div className={css?.s3Md}>
      {steps.map((step, index) => (
        <div className={index % 2 === 0 ? css?.s3Card : css?.s3Card2}>
          <ImageSection imageSrc={step.image} />
          <TextSection title={step.title} description={step.description} />
        </div>
      ))}
    </div>
  );
};

export default Section3_InfoBubbles;
