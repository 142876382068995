import {
  GET_VEHICLE_TYPES_FAIL,
  GET_VEHICLE_TYPES_SUCCESS,
  GET_VEHICLE_TYPES_REQUEST,
} from "../constants/vehicleConstants";

// used for vehicle types
export const vehicleTypesReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VEHICLE_TYPES_REQUEST:
      return {
        ...state,
        vehicleTypesInfo: null,
        vehicleTypesLoading: true,
      };
    case GET_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        vehicleTypesLoading: false,
        vehicleTypesInfo: payload,
        vehicleTypesError: "",
      };
    case GET_VEHICLE_TYPES_FAIL:
      return {
        ...state,
        vehicleTypesLoading: false,
        vehicleTypesError: payload,
      };
    default:
      return state;
  }
};
