import React from "react";
// importing the css
import css from "../../style/booking.module.css";
import { BiPlusMedical } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import { CustomTooltip } from "../Tooltip/tooltip";

const textArr = [
  {
    isSubbullet: false,
    text: "Please select the number of movers required for your job:",
  },
  {
    isSubbullet: false,
    text: "The total price range provided below is a quote. The final price will be determined by the actual labour time plus 30 minutes of travel time for all local moves. Additional travel time charges will apply for moves outside the city.",
  },
  { isSubbullet: false, text: "Hourly rates are as follows:" },
  {
    isSubbullet: true,
    text: "1 mover: $50/hr (*only available for pickup trucks/cargo vans)",
  },
  {
    isSubbullet: true,
    text: "2 movers: $85/hr (pickup trucks/cargo vans), $120/hr (16-20ft truck), $125/hr (26ft truck)",
  },
  {
    isSubbullet: true,
    text: "3 movers: $150/hr (16-20ft truck), $155/hr (26ft truck)",
  },
  {
    isSubbullet: true,
    text: "4 movers: $180/hr (16-20ft truck), $185/hr (26ft truck)",
  },
  {
    isSubbullet: false,
    text: "For example, if you require 2 movers for 2 hours (labour time) in a cargo van + 30 minutes (travel time), the calculation would be: $85/hr x 2.5 hours = $212.50 + $10.63 GST = $223.13 (Final Total).",
  },
];

const MoverTooltip = () => {
  return (
    <div className={css?.tooltipMainDiv}>
      <div className={css?.tooltipBullet}>
        <div className={css?.tooltipDotBox}>
          <div className={css?.tooltipDot}>.</div>
        </div>
        <div className={css?.tooltipText}>
          Please select the number of movers needed for your job.
        </div>
      </div>
    </div>
  );
};

const MovingTooltip = () => {
  return (
    <div className={css?.tooltipMainDiv}>
      {textArr.map((bullet, index) => (
        <div
          className={css?.tooltipBullet}
          style={
            bullet.isSubbullet
              ? { marginLeft: "40px", width: "calc(100% - 40px)" }
              : {}
          }
        >
          <div className={css?.tooltipDotBox}>
            <div className={css?.tooltipDot}>.</div>
          </div>
          <div className={css?.tooltipText}>{bullet.text}</div>
        </div>
      ))}
    </div>
  );
};

const Counter = ({
  value,
  type,
  min,
  max,
  increment,
  decrement,
  increment2,
}) => {
  return (
    <div className={css?.counterMainDiv}>
      <div className={css?.Level2Heading}>
        <># of movers </>
        <CustomTooltip
          title={type === "Movers" ? <MoverTooltip /> : <MovingTooltip />}
        >
          <img
            src="images/tooltip.svg"
            style={{
              display: "inline",
              cursor: "pointer",
              width: 21,
              marginLeft: 3,
            }}
            alt=""
          />
        </CustomTooltip>
      </div>
      <div className={css?.counterStrip}>
        <input
          defaultValue={value}
          value={value}
          min={min}
          max={max}
          className={css?.counterInput}
        ></input>
        {value > min ? (
          <div
            onClick={() => value > min && decrement()}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        ) : (
          <div
            onClick={() => value > min && decrement()}
            style={{ backgroundColor: "#9e9ac7" }}
            className={css?.counterButtons}
          >
            <FaMinus />
          </div>
        )}
        {value < max ? (
          <div className={css?.counterButtons} onClick={() => increment()}>
            <BiPlusMedical />
          </div>
        ) : (
          <div
            className={css?.counterButtons}
            style={{ backgroundColor: "#9e9ac7" }}
            onClick={() => value < max && increment()}
          >
            <BiPlusMedical />
          </div>
        )}
      </div>
    </div>
  );
};

export default Counter;
