import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React from "react";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { userPaymentMethods } from "../../store/actions/paymentActions";
import baseUrl from "../../store/constants/baseUrl";
import css from "../../style/orderSummary.module.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "black" },
      ":-placeholder": { color: "black" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({ userId, setCardSaved, submitWrapper }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { error, token } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (!error) {
        const { id } = token;
        const { data } = await axios.post(
          baseUrl +
            `BookingsAPI/AddingStripeToUserProfile?userId=${userId}&token=${id}`
        );
        if (data.statusCode === 200) {
          setCardSaved(true);
          elements.getElement(CardElement).clear();
        }
      } else {
        console.log("payment error", error.message);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      elements.getElement(CardElement).clear();
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) =>
          submitWrapper ? submitWrapper(handleSubmit, e) : handleSubmit(e)
        }
        className={css?.CMCardForm}
      >
        <fieldset style={{ width: "100%" }}>
          <div className={css?.CMCardField}>
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>

        <input
          type="submit"
          value="Save Card"
          className={css?.saveCard}
        ></input>
      </form>
    </div>
  );
};

export default PaymentForm;
