import * as React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import { useNavigate } from "react-router";

export default function SectionB() {
  //history for routing
  const history = useNavigate();
  return (
    <div className={css?.S2MainDiv}>
      <div className={css?.S2Left}>
        <h4 className={css?.S2LeftH3}>ONLY AVAILABLE IN ALBERTA</h4>
        <h1 className={css?.S2LeftH1}>
          We help you move your <strong>things</strong>
        </h1>
        <p className={css?.S2NormalTxt}>
          Move-in day? Move-out day? Can’t fit that massive couch from Kijiji in
          your car? Last-mile delivery challenges with your business? Our
          on-demand moving & delivery service has you covered.
        </p>
        <button
          onClick={() => history("/howItWorks")}
          className={css?.roundBtn2}
        >
          How does it work?
        </button>
      </div>
      <div className={css?.S2Right}>
        <img src="images/welcome.svg" alt="" className="img-fluid" />
      </div>
    </div>
  );
}
